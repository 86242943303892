import { Component } from '@angular/core';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { FormsModule, NgForm } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { RouterLink } from '@angular/router';
import { LoginResponse } from '../../core/models/users';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-login',
	standalone: true,
	imports: [CommonModule, InputComponent, ButtonComponent, FormsModule, RouterLink],
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css', '../../../form-styles.css'],
})
export class LoginComponent {
	email: string = '';
	password: string = '';
	invalidFormMessage = '';
	loggingIn: boolean = false;
	isLockedOut: boolean = false;

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
		private router: Router,
	) {}

	onLogin(loginForm: NgForm): void {
		if (loginForm.valid) {
			this.loggingIn = true;
			this.userService.login(this.email, this.password).subscribe({
				next: (user: LoginResponse) => {
					if (user.isAuthorized) {
						this.router.navigateByUrl('/').catch((error) => {
							console.error('Navigation error:', error);
							this.loggingIn = false;
						});
					} else if (user.isLockedOut) {
						this.loggingIn = false;
						this.invalidFormMessage = '';
						this.isLockedOut = user.isLockedOut;
					} else {
						this.invalidFormMessage = 'Invalid username or password';
						this.loggingIn = false;
					}
				},
				error: () => {
					this.invalidFormMessage = 'Something went wrong';
					this.loggingIn = false;
					this.toastrService.showError({});
				},
			});
		} else {
			this.invalidFormMessage = 'Please fill out the form correctly';
		}
	}
}
