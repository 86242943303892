import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Contact } from '../models/users';

@Injectable({
	providedIn: 'root',
})
export class EmailService {
	private baseUrl: string = `${environment.apiUrl}auth/api/emails`;

	constructor(private httpClient: HttpClient) {}

	verifyEmail(token: string, email: string): Observable<void> {
		return this.httpClient.put<void>(`${this.baseUrl}/verify`, { token, email });
	}

	validateResetPassword(token: string, email: string): Observable<void> {
		const params = new HttpParams().set('token', token).set('email', email);
		return this.httpClient.get<void>(`${this.baseUrl}/validate-reset-password`, {
			params,
		});
	}

	resendVerificationEmail(email: string): Observable<void> {
		return this.httpClient.put<void>(`${this.baseUrl}/resend-verification`, { email: email });
	}

	forgotPasswordEmail(email: string): Observable<void> {
		return this.httpClient.put<void>(`${this.baseUrl}/forgot-password`, {
			email: email,
		});
	}

	sendContactForm(contactForm: Contact): Observable<Contact> {
		const payload = {
			name: `${contactForm.firstName} ${contactForm.lastName}`,
			senderMail: contactForm.email,
			contactForm: contactForm.country,
			body: contactForm.body,
			phoneNumber: contactForm.phoneNumber,
			subject: contactForm.subject,
		};
		return this.httpClient.post<Contact>(`${this.baseUrl}/contact-form`, payload);
	}
}
