import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { UserService } from '../../core/services/user.service';
import { NgIf } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { ProcessStatus } from '../../core/models/users';
import { EmailService } from '../../core/services/email.service';
import { ParamMapString } from '../../core/types/param-map-string';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-reset-password',
	standalone: true,
	imports: [
		InputComponent,
		ButtonComponent,
		NgIf,
		FormsModule,
		RouterModule,
		LoadingSpinnerComponent,
	],
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css', '../../../form-styles.css'],
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordResult: ProcessStatus = ProcessStatus.Default;
	resetPasswordResults = ProcessStatus;
	email: ParamMapString = '';
	token: ParamMapString = '';
	newPassword: string = '';
	repeatPassword: string = '';
	invalidFormMessage: string = '';
	resettingPassword: boolean = false;
	isValidToken: boolean = true;

	constructor(
		private userService: UserService,
		private emailService: EmailService,
		private toastrService: ToastrService,
		private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.token = this.route.snapshot.queryParamMap.get('token');
		this.email = this.route.snapshot.queryParamMap.get('email');
		if (this.token && this.email)
			this.emailService.validateResetPassword(this.token, this.email).subscribe({
				next: () => {
					this.isValidToken = true;
				},
				error: () => {
					this.isValidToken = false;
					this.toastrService.showError({});
				},
			});
	}

	onChangePassword(changePasswordForm: NgForm): void {
		if (changePasswordForm.valid) {
			this.resettingPassword = true;
			if (this.token && this.email) {
				this.userService.resetPassword(this.token, this.email, this.newPassword).subscribe({
					next: () => {
						this.resetPasswordResult = this.resetPasswordResults.Success;
						this.resettingPassword = false;
					},
					error: () => {
						this.resetPasswordResult = this.resetPasswordResults.Failure;
						this.resettingPassword = false;
						this.toastrService.showError({});
					},
				});
			}
		}
	}
}
