import { Component } from '@angular/core';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { RouterLink } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';
import { UserService } from '../../core/services/user.service';
import { ProcessStatus, RegisterUser } from '../../core/models/users';
import { ToastrService } from '../../core/services/toastr.service';
import { EmailService } from '../../core/services/email.service';

@Component({
	selector: 'app-register',
	standalone: true,
	imports: [InputComponent, ButtonComponent, RouterLink, FormsModule, NgIf],
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css', '../../../form-styles.css'],
})
export class RegisterComponent {
	registerResult: ProcessStatus = ProcessStatus.Default;
	registerResults = ProcessStatus;
	user: RegisterUser = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
	};
	invalidFormMessage = '';
	isRegisterSuccessfull = false;
	registering: boolean = false;
	hasResentEmail: boolean = false;

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
		private emailService: EmailService,
	) {}

	onRegister(registerForm: NgForm): void {
		if (registerForm.valid) {
			this.registering = true;
			this.userService.register(this.user).subscribe({
				next: () => {
					this.registerResult = this.registerResults.Success;
					this.registering = false;
				},
				error: () => {
					this.registerResult = this.registerResults.Failure;
					this.registering = false;
					this.toastrService.showError({});
				},
			});
		} else {
			this.invalidFormMessage = 'Please fill out the form correctly';
		}
	}

	resendEmail(): void {
		this.hasResentEmail = false;
		this.emailService.resendVerificationEmail(this.user.email).subscribe({
			next: () => {
				this.hasResentEmail = true;
			},
			error: () => {
				console.log('error');
			},
		});
	}

	backToRegister(): void {
		this.registerResult = this.registerResults.Default;
	}
}
