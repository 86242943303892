import {
	Component,
	EventEmitter,
	Output,
	Input,
	ElementRef,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { ButtonComponent } from '../button/button/button.component';
import { interval, Subscription } from 'rxjs';
import { ConfirmCancelButtonGroupComponent } from '../buttons-confirm-cancel/confirm-cancel-button-group.component';
import { NgFor } from '@angular/common';
import { ModalResult } from '../../core/services/modal.service';

@Component({
	selector: 'app-modal',
	standalone: true,
	imports: [ButtonComponent, ConfirmCancelButtonGroupComponent, NgFor],
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() size?: string = 'md';
	@Input() title?: string = '';
	@Input() content?: string = '';
	@Input() autoClose: boolean = false;
	@Input() autoCloseTime: number = 5;
	@Input() disableBackdrop: boolean = false;
	@Input() buttonLabel: string = '';
	@Input() buttons: { label: string; action: () => void }[] = [];

	@Output() closeEmitter = new EventEmitter<void>();

	remainingTime: number = 0;
	autoCloseSubscription: Subscription | undefined;

	ModalResult = ModalResult;

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		if (this.autoClose) {
			this.remainingTime = this.autoCloseTime;
			this.autoCloseSubscription = interval(1000).subscribe(() => {
				this.remainingTime--;
				if (this.remainingTime <= 0) {
					this.closeEmitter.emit();
					this.elementRef.nativeElement.remove();
					this.clearAutoClose();
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.clearAutoClose();
	}

	onButtonClick(action: () => void): void {
		action();
		this.closeEmitter.emit();
		this.elementRef.nativeElement.remove();
		this.clearAutoClose();
	}

	clearAutoClose(): void {
		if (this.autoCloseSubscription) {
			this.autoCloseSubscription.unsubscribe();
			this.autoCloseSubscription = undefined;
		}
	}
}
