import { Component, Input } from '@angular/core';
import { User } from '../../../core/models/users';
import { SharingService } from '../../../core/services/sharing.service';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { ToastrService } from '../../../core/services/toastr.service';
import { ContextView } from '../../../core/models/contexts';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';

@Component({
	selector: 'app-shared-users',
	standalone: true,
	imports: [ModalComponent, LoadingSpinnerComponent],
	templateUrl: './shared-users.component.html',
	styleUrl: './shared-users.component.css',
})
export class SharedUsersComponent {
	@Input() context!: ContextView;
	users: User[] = [];
	isSharingVisible: boolean = false;
	isLoading: boolean = false;

	constructor(
		private sharingService: SharingService,
		private toastrService: ToastrService,
	) {}

	toggleSharing(): void {
		this.isSharingVisible = !this.isSharingVisible;
		if (this.isSharingVisible) {
			this.getUsersWithAccessToContext(this.context.id);
		}
	}

	getUsersWithAccessToContext(contextId: number): void {
		this.isLoading = true;
		this.sharingService.getUsersWithAccessToContext(contextId).subscribe({
			next: (response) => {
				this.users = response;
				this.isLoading = false;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	onModalClose(): void {
		this.isSharingVisible = false;
	}
}
