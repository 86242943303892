export class Symbol{
    static Arrow = "Arrow";
    static Default = "Default";

    static ToCssId(symbol) {
        switch (symbol){
            case Symbol.Arrow:
				return "#dd-arrow";
			case Symbol.Default:
				return null;
        }
    }
}