import { Node } from "./node.js"
import { Anchor } from "../anchor.js";
import { namespace } from "../../../shared/constants/svg.js";
import { Location } from "../../../shared/constants/location.js";

export class BoxNode extends Node{
	#border = "#888888";
	#boxDepth = 12;
	color = "rgb(255, 255, 255)";

	constructor(position, applicationState, shape, referenceId, id, type){
		super(applicationState, shape, position, referenceId, id, type);
		this.#createSvg(position);
		this.#createAnchors();
		this.registerListeners();
	}

	getNodeCenterPosition(){
		const pos = this.getSvgPosition();
		return {
			x: pos.x + this.width / 2, 
			y: pos.y + this.height / 2 
		};
	}

	#createSvg(position){
		const {x, y} = position;

		this.svgContainer = document.createElementNS(namespace, "svg");
		this.svgContainer.setAttribute("fill", "white");
		this.svgContainer.setAttribute("x", x);
		this.svgContainer.setAttribute("y", y);
		this.svgContainer.style.overflow = "visible";

		this.svg = document.createElementNS(namespace, "polygon");
        this.svg.setAttribute("points", this.#getBoxNodePoints());
		this.svg.setAttribute("fill", this.color);
		this.svg.setAttribute("stroke", this.#border);
		this.svg.setAttribute("stroke-width", this.strokeWidth);
        
		this.svgContainer.appendChild(this.svg);
		this.container.appendChild(this.svgContainer);
		this.#createStatusIndicator();
		this.createTextElement();
	}

	#createStatusIndicator(){
		const statusSvg = document.createElementNS(namespace, "rect");
		statusSvg.setAttribute("x", this.strokeWidth / 2);
		statusSvg.setAttribute("y", this.strokeWidth / 2);

		statusSvg.setAttribute("width", this.statusIndicatorWidth);
		statusSvg.setAttribute("height", this.height - this.strokeWidth);
		statusSvg.style.pointerEvents = "none";
		this.svgContainer.appendChild(statusSvg);
	}

	#getBoxNodePoints() {
		return `0 0, ${this.width} 0, ${this.width} ${this.height}, 0 ${this.height}, 0 0, ${this.#boxDepth} ${this.#boxDepth * -1}, ${this.width + this.#boxDepth} ${this.#boxDepth * -1}, ${this.width} 0, ${this.width + this.#boxDepth} ${this.#boxDepth * -1}, ${this.width + this.#boxDepth} ${this.height - this.#boxDepth}, ${this.width} ${this.height}, 0 ${this.height}`;
	}
	
	#createAnchors(){
		const halfWidth = this.width * 0.5;
		const halfHeight = this.height * 0.5;
		const nodeSize = {
			"width": this.width, 
			"height": this.height
		}
		this.anchors = [
			new Anchor({ x: halfWidth, y: 0 }, Location.Top, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: this.width, y: halfHeight }, Location.Right, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: halfWidth, y: this.height }, Location.Bottom, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: 0, y: halfHeight }, Location.Left, this.svgContainer, nodeSize, this.applicationState, this.id),
		];
	}
}
