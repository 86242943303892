@if (metaData) {
	<a [href]="url" target="_blank">
		<div class="meta-content">
			@if (metaData.iconUrl) {
				<img class="meta-icon" [src]="metaData.iconUrl" alt="Link Preview Image" />
			}
			<div class="flex-col">
				<span class="font-smaller">
					<b>{{ metaData.title }}</b>
				</span>
				<p class="meta-description font-smaller">
					{{ metaData.description }}
				</p>
			</div>
		</div>
	</a>
}
