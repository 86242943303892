@if (!canCreate) {
	<app-modal
		[title]="'You have shared this drawing with:'"
		[content]="'ContextAccess cannot be created because you already have 5 contexts created.'"
		[showCloseButton]="false"
	>
		<br />
		<p class="font-smaller">Please wait, you will be redirected to the homepage again</p>
	</app-modal>
}
