import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ContentViewStateService {
	private folderExpandedLookup: { [folderId: number]: boolean } = {};
	private managedObjectAttributeExpandedLookup: { [managedObjectAttributeId: number]: boolean } =
		{};

	setFolderExpanded(folderId: number, isExpanded: boolean): void {
		this.folderExpandedLookup[folderId] = isExpanded;
	}

	isFolderExpanded(folderId: number): boolean {
		return this.folderExpandedLookup[folderId] || false;
	}

	setInitialManagedObjectAttributeExpanded(
		managedObjectAttributeId: number,
		isExpanded: boolean,
	): void {
		if (!(managedObjectAttributeId in this.managedObjectAttributeExpandedLookup)) {
			this.managedObjectAttributeExpandedLookup[managedObjectAttributeId] = isExpanded;
		}
	}

	setManagedObjectAttributeExpanded(managedObjectAttributeId: number, isExpanded: boolean): void {
		if (!(managedObjectAttributeId in this.managedObjectAttributeExpandedLookup)) {
			this.managedObjectAttributeExpandedLookup[managedObjectAttributeId] = false;
		}
		this.managedObjectAttributeExpandedLookup[managedObjectAttributeId] = isExpanded;
	}

	isManagedObjectAttributeExpanded(managedObjectAttributeId: number): boolean {
		return managedObjectAttributeId in this.managedObjectAttributeExpandedLookup
			? this.managedObjectAttributeExpandedLookup[managedObjectAttributeId]
			: true;
	}
}
