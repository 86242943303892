import { CommandName } from "../shared/constants/command-name.js";
import { containerId } from "../shared/constants/id.js";
import { NodeRepository } from "../infrastructure/node-repository.js";
import { CircleNode } from "../core/models/node/circle-node.js";
import { DiamondNode } from "../core/models/node/diamond-node.js";
import { OctagonNode } from "../core/models/node/octagon-node.js";
import { Shape } from "../shared/constants/shapes.js";
import { SquareNode } from "../core/models/node/square-node.js";
import { TriangleNode } from "../core/models/node/triangle-node.js";
import { RelationRepository } from "../infrastructure/relation-repository.js";
import { Relation } from "../core/models/relation.js";
import { RelationState } from "../shared/constants/relation-state.js";
import { BoxNode } from "../core/models/node/box-node.js";

export class CreateFromNodesCommand
{
	static commandName = CommandName.CreateFromNodesCommand;

	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.CreateFromNodesCommand, { detail: detail }));
	}
}

export class CreateFromNodesHandler
{
	constructor(rootContainer)
    {
		rootContainer.addEventListener(CreateFromNodesCommand.commandName, (e) => {
			this.#generateContent(e.detail.nodes, e.detail.applicationState)
        });
    }
	
	#generateContent(nodes, applicationState) {
        NodeRepository.clear();
		RelationRepository.clear();

		this.#createAndSaveNodes(nodes, applicationState);
		this.#createAndSaveRelations(nodes);
	}

	#createAndSaveNodes(nodes, applicationState) {
		nodes.map(node => {
			const createNode = this.#getNode(node.shape, { x: node.positionX, y: node.positionY }, node.referenceId, node.id, applicationState, node.type);
			createNode.setName(node.name);
			NodeRepository.add(createNode);
		});
	}

	#createAndSaveRelations(nodes) {
		nodes.reduce((accumulatedList, node) => accumulatedList.concat(node.relations), []).forEach(relation => {
			const startAnchor = NodeRepository.getAnchorByLocationAndNodeId(relation.anchorLocation1, relation.node1Id);
			const endAnchor = NodeRepository.getAnchorByLocationAndNodeId(relation.anchorLocation2, relation.node2Id);

			const anchoredRelation = new Relation(relation.id, startAnchor, endAnchor, relation.symbol1, relation.symbol2, RelationState.Idle)

			startAnchor.connectedRelations.push(anchoredRelation);
			endAnchor.connectedRelations.push(anchoredRelation);

			RelationRepository.add(anchoredRelation);		
		});
	}

    #getNode(shape, position, referenceId, id, actionMenuSelection, nodeType) {
		switch (shape){
			case Shape.Box:
				var node = new BoxNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
			case Shape.Square:
				var node = new SquareNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
			case Shape.Circle:
				var node = new CircleNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
			case Shape.Diamond:
				var node = new DiamondNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
			case Shape.Triangle:
				var node = new TriangleNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
			case Shape.Octagon:
				var node = new OctagonNode(position, actionMenuSelection, shape, referenceId, id, nodeType);
				break;
		}
		return node;
	}
}