import { Component } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { User } from '../../../core/models/users';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-overview',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.css', '../../../../form-styles.css'],
})
export class OverviewComponent {
	user: User;

	constructor(private userService: UserService) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser === undefined) throw new Error(`Couldn't get current user`);
		this.user = currentUser.user;
	}
}
