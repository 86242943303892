import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

export interface ToastrMessage {
	type: 'error' | 'success' | 'info' | 'warning';
	title: string;
	message: string;
	duration?: number;
	progress?: number;
	progressSubscription?: Subscription;
}

interface ShowErrorOptions {
	title?: string;
	message?: string;
	duration?: number;
}

@Injectable({
	providedIn: 'root',
})
export class ToastrService {
	private toastrSubject = new Subject<ToastrMessage>();

	get toastrState$(): Observable<ToastrMessage> {
		return this.toastrSubject.asObservable();
	}

	showError({
		title = 'Error',
		message = 'We encountered an error. The development team has been notified.',
		duration = 10000,
	}: ShowErrorOptions): void {
		this.toastrSubject.next({
			type: 'error',
			title,
			message,
			duration,
		});
	}
}
