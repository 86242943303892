import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml',
	standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string, isExpanded: boolean): SafeHtml {
		let sanitizedValue = value.replace(/<p><\/p>/g, '<p>&nbsp;</p>');

		if (!isExpanded) {
			sanitizedValue = sanitizedValue.replace(
				/<img[^>]+src="([^">]+)"[^>]*>/g,
				'<img src="$1" class="notes-img-preview" />',
			);
		}
		return this.sanitizer.bypassSecurityTrustHtml(sanitizedValue);
	}
}
