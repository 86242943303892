import { Component } from '@angular/core';
import { SideBarStateService } from '../../core/services/side-bar-state.service';
import { Observable, switchMap } from 'rxjs';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TopBarComponent } from '../../shared/top-bar/top-bar.component';
import { DeluxeDrawComponent } from '../../features/deluxe-draw/deluxe-draw.component';
import { ContentViewComponent } from '../../features/content-view/content-view.component';
import { SideBarComponent } from '../../features/side-bar/side-bar.component';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { Side, SideDragged } from '../../core/models/sidebar';
import { ContextView } from '../../core/models/contexts';
import { ContextService } from '../../core/services/context.service';
import { DeluxeDrawService } from '../../core/services/deluxe-draw.service';
import { ToastrService } from '../../core/services/toastr.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-home',
	standalone: true,
	imports: [
		RouterOutlet,
		TopBarComponent,
		DeluxeDrawComponent,
		ContentViewComponent,
		SideBarComponent,
		NgIf,
		NgStyle,
		AsyncPipe,
	],
	templateUrl: './home.component.html',
	styleUrl: './home.component.css',
})
export class HomeComponent {
	sidebarWidth: number = 175;
	contentViewWidth: number = 300;

	showSideBar$: Observable<boolean>;
	showContentView$: Observable<boolean>;

	contexts: ContextView[] = [];
	contextId: number = 0;
	isLoadingContext: boolean = false;
	isContextSelected: boolean = false;
	currentContext!: ContextView;

	constructor(
		private sideBarStateService: SideBarStateService,
		private contextService: ContextService,
		private deluxeDrawService: DeluxeDrawService,
		private toastrService: ToastrService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.showSideBar$ = sideBarStateService.showSideBar$.asObservable();
		this.showContentView$ = sideBarStateService.showContentView$.asObservable();

		this.isLoadingContext = true;
		this.contextService
			.getAll()
			.pipe(
				takeUntilDestroyed(),
				switchMap((contexts) => {
					this.contexts = contexts;
					this.isLoadingContext = false;
					return this.route.params;
				}),
			)
			.subscribe({
				next: (params) => {
					this.contextId = +params['contextId'];
					if (!this.contextId) {
						this.tryParseContextIdAndRedirect();
						return;
					}

					this.currentContext = this.findCurrentContext(this.contexts, this.contextId)!;
					this.setExpandedState(this.contexts, this.contextId);
					this.contextService.setContextId(this.contextId);
				},
				error: () => {
					this.isLoadingContext = false;
					this.toastrService.showError({});
				},
			});

		this.contextService.contextsModified$
			.pipe(
				takeUntilDestroyed(),
				switchMap(() => this.contextService.getAll()),
			)
			.subscribe({
				next: (updatedContexts) => {
					const previousContext = [...this.contexts];
					this.contexts = updatedContexts;
					this.restoreExpandedState(this.contexts, previousContext);
					this.currentContext = this.findCurrentContext(this.contexts, this.contextId)!;
					this.isLoadingContext = false;
				},
				error: () => {
					this.isLoadingContext = false;
					this.toastrService.showError({});
				},
			});
		this.deluxeDrawService.nodeClicked$.pipe(takeUntilDestroyed()).subscribe({
			next: (id) => {
				if (id) this.isContextSelected = true;
				else {
					this.isContextSelected = false;
				}
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	onResize(sideDragged: SideDragged): void {
		if (sideDragged.side === Side.Left) {
			this.sidebarWidth = sideDragged.newWidth;

			if (
				this.sidebarWidth <= this.sideBarStateService.minWidthSidebar &&
				this.sideBarStateService.showSideBar
			) {
				this.sideBarStateService.toggleSideBar(false);
			}
			if (
				this.sidebarWidth > this.sideBarStateService.minWidthSidebar &&
				!this.sideBarStateService.showSideBar
			) {
				this.sideBarStateService.toggleSideBar(true);
			}
		}

		if (sideDragged.side === Side.Right) {
			this.contentViewWidth = window.innerWidth - sideDragged.newWidth;

			if (
				this.contentViewWidth <= this.sideBarStateService.minWidthContentView &&
				this.sideBarStateService.showContentView
			) {
				this.sideBarStateService.toggleContentView(false);
			}
			if (
				this.contentViewWidth > this.sideBarStateService.minWidthContentView &&
				!this.sideBarStateService.showContentView
			) {
				this.sideBarStateService.toggleContentView(true);
			}
		}
	}

	onContextSelectedChange(isSelected: boolean): void {
		this.isContextSelected = isSelected;
	}

	private findCurrentContext(contexts: ContextView[], contextId: number): ContextView | null {
		for (const context of contexts) {
			if (context.id === contextId) return context;

			const foundContext = this.findCurrentContext(context.children, contextId);
			if (foundContext) return foundContext;
		}
		return null;
	}

	private setExpandedState(contexts: ContextView[], contextId: number): boolean {
		for (const context of contexts) {
			if (context.id === contextId) {
				context.expanded = true;
				return true;
			}

			if (this.setExpandedState(context.children, contextId)) {
				context.expanded = true;
				return true;
			}
		}
		return false;
	}

	private restoreExpandedState(
		updatedContexts: ContextView[],
		previousContexts: ContextView[],
	): boolean {
		for (const updatedContext of updatedContexts) {
			const previousContext = previousContexts.find((context) => context.id === updatedContext.id);
			if (previousContext) updatedContext.expanded = previousContext.expanded;

			if (updatedContext.children && previousContext?.children)
				this.restoreExpandedState(updatedContext.children, previousContext.children);
		}
		return false;
	}

	private tryParseContextIdAndRedirect(): void {
		const contextId = localStorage.getItem('contextId') ?? this.contexts[0]?.id;

		if (contextId) void this.router.navigate(['/', contextId]);

		this.isLoadingContext = false;
		this.isContextSelected = false;
	}
}
