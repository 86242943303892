import { namespace } from "../../shared/constants/svg.js";
import { Location } from "../../shared/constants/location.js";
import { CustomEventName } from "../../shared/constants/custom-events.js";
import { containerId } from "../../shared/constants/id.js";
import { gridSize } from "../../shared/constants/grid.js";
import { ApplicationState } from "../../shared/constants/application-state.js";

export class Anchor {
	radius = 5;
	location;
	svg;
	svgContainer;
	nodeSize;
	applicationState;
	connectedRelations;
	nodeId;
	#hitboxRadius = gridSize * 1.1;
	#color = "#888888";
	#activeColor = "rgb(40, 80, 140)";
	#rootContainer;
	#hitbox;

	constructor(position, location, svgContainer, nodeSize, applicationState, nodeId) {
		this.nodeId = nodeId;
		this.location = this.#validateLocationOrThrow(location);
		this.svgContainer = svgContainer;
		this.#createSvg(position);
		this.nodeSize = nodeSize;
		this.applicationState = applicationState;
		this.connectedRelations = [];
		this.#registerListeners();
		this.#rootContainer = document.getElementById(containerId);
	}

	show(){
		this.svg.style.display = "block";
	}
	
	hide(){
		this.svg.style.display = "none";
	}

	setActive(){
		this.svg.setAttribute("fill", this.#activeColor)
	}

	setInactive(){
		this.svg.setAttribute("fill", this.#color)
	}

	getAbsolutePosition() {
		return {
			x: Number.parseInt(this.svgContainer.getAttribute("x")) + Number.parseInt(this.svg.getAttribute("cx")),
			y: Number.parseInt(this.svgContainer.getAttribute("y")) + Number.parseInt(this.svg.getAttribute("cy")),
		}
	}

	removeConnectedRelation(relation) {
		this.connectedRelations = this.connectedRelations.filter(r => r.guid !== relation.guid);
	}

	#validateLocationOrThrow(location){
		const allowedLocations = Object.values(Location);
		if (!location || !allowedLocations.some((s) => s === location))
			throw new Error(`${location} is not in allowed locations: ${allowedLocations.join(",")}`);
		return location;
	}

	#createSvg(position){
		const {x, y} = position;

		this.svg = document.createElementNS(namespace, "circle");
		this.svg.setAttribute("cx", x);
		this.svg.setAttribute("cy", y);
		this.svg.setAttribute("r", this.radius);
		this.svg.setAttribute("fill", this.#color);
		this.svg.setAttribute("class", "dd-anchor");
		this.svg.style.display = "none";
		this.svgContainer.appendChild(this.svg);

		this.#hitbox = document.createElementNS(namespace, "circle");
		this.#hitbox.setAttribute("cx", x);
		this.#hitbox.setAttribute("cy", y);
		this.#hitbox.setAttribute("r", this.#hitboxRadius);
		this.#hitbox.setAttribute("fill", "transparent");
		this.svgContainer.appendChild(this.#hitbox);

		this.#hitbox.addEventListener("mouseover", () => {
			this.svg.setAttribute("fill", this.#activeColor);
			this.svgContainer.firstChild.setAttribute("class", "dd-node-active");
		});

		this.#hitbox.addEventListener("mouseout", () => {
			this.svg.setAttribute("fill", this.#color);
			this.svgContainer.firstChild.removeAttribute("class", "dd-node-active");
		});
	};

	#registerListeners() {
		this.#hitbox.addEventListener("click", (e) => {
			e.stopPropagation();
			if (this.applicationState === ApplicationState.Edit)
				this.#rootContainer.dispatchEvent(new CustomEvent(CustomEventName.AnchorClicked, { detail: this }));
		});
	}
};