import { Shape } from "./shapes.js"
import { Symbol } from "../static/symbols.js"
import { ActionMenuSelection } from "./action-menu-selection.js"
import { CustomEventName } from "./custom-events.js"
import { NodeType } from "./node-type.js"

export const EventSelection = {
    Box: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Box, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.Context }}),
    Square: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Square, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.ManagedObject }}),
    Circle: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Circle, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.ManagedObject }}),
    Triangle: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Triangle, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.ManagedObject }}),
    Diamond: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Diamond, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.ManagedObject }}),
    Octagon: new CustomEvent(CustomEventName.CreateNode, {detail: { shape: Shape.Octagon, actionMenuSelection: ActionMenuSelection.CreateShape, type: NodeType.ManagedObject }}),
    Connect: new CustomEvent(CustomEventName.SetRelations, { detail: { symbols: { symbol1: Symbol.Default, symbol2: Symbol.Arrow }, actionMenuSelection: ActionMenuSelection.CreateRelation}}),
    ConnectBoth: new CustomEvent(CustomEventName.SetRelations, { detail: { symbols: {symbol1: Symbol.Arrow, symbol2: Symbol.Arrow }, actionMenuSelection: ActionMenuSelection.CreateRelation}}),
    Navigate: new CustomEvent(CustomEventName.ActionMenuSelection, { detail: { actionMenuSelection: ActionMenuSelection.Navigate } }),
    Delete: new CustomEvent(CustomEventName.ActionMenuSelection, { detail: { actionMenuSelection: ActionMenuSelection.Delete } }),
    Edit: new CustomEvent(CustomEventName.ActionMenuSelection, { detail: { actionMenuSelection: ActionMenuSelection.Edit } }),
    MarkerArea: new CustomEvent(CustomEventName.ActionMenuSelection, { detail: { actionMenuSelection: ActionMenuSelection.MarkerArea } }),
    ResetZoom: new CustomEvent(CustomEventName.ResetZoom, { detail: { actionMenuSelection: ActionMenuSelection.ResetZoom } }),
    Focusbox: new CustomEvent(CustomEventName.ActionMenuSelection, { detail: { actionMenuSelection: ActionMenuSelection.Focusbox }})
}