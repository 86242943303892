<main class="form-main-wrapper">
	<section class="form-container flex-col">
		<section class="form-heading-container flex-col">
			<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
		</section>
		@switch (resetPasswordResult) {
			@case (resetPasswordResults.Success) {
				<h1 class="form-headings">Success!</h1>
				<p class="login-link login-option margin-top">
					You can now <a class="login-option-link" routerLink="/login">login</a> to Boswell
				</p>
			}
			@case (resetPasswordResults.Failure) {
				<h1 class="form-headings">Could not reset your password</h1>
				<p class="login-option margin-top">
					Back to <a class="login-option-link" href="/login">login</a> and try again
				</p>
			}
			@default {
				@if (isValidToken) {
					<section class="form-heading-container flex-col">
						<h1 class="form-headings">Please select a new password</h1>
						<h2 class="form-headings font-smaller">Minimum of 8 characters</h2>
					</section>
					<form
						class="login-form-container flex-col"
						#changePasswordForm="ngForm"
						(ngSubmit)="onChangePassword(changePasswordForm)"
					>
						<app-input
							labelText="New Password"
							type="password"
							name="newPassword"
							[(ngModel)]="newPassword"
							minlength="8"
							[maxLength]="64"
							required
						></app-input>
						@if (
							changePasswordForm.submitted && changePasswordForm.controls['newPassword'].invalid
						) {
							<div class="error-msg">Password must contain at least 8 characters</div>
						}
						<app-input
							labelText="Repeat Password"
							type="password"
							name="repeatPassword"
							[(ngModel)]="repeatPassword"
							minlength="8"
							[maxLength]="64"
							required
						></app-input>
						@if (
							changePasswordForm.submitted && changePasswordForm.controls['repeatPassword'].invalid
						) {
							<div class="error-msg">Please confirm your password</div>
						}
						<app-button
							class="margin-top shadow"
							label="Change password"
							type="submit"
							[showLoadingSpinner]="resettingPassword"
						></app-button>
						<p class="login-option margin-top">
							Back to <a class="login-option-link" href="/login">login</a>
						</p>
					</form>
				} @else {
					<section class="form-heading-container flex-col">
						<h2 class="form-headings font-smaller">This url is no longer valid.</h2>
					</section>
					<p class="login-option margin-top">
						Back to <a class="login-option-link" href="/login">login</a> and try again
					</p>
				}
			}
		}
	</section>
</main>
