import { pointWithin } from "../../deluxe-math.js";

export function getPoints(startAnchor, endAnchor, lineMargin){
	const start = startAnchor.getAbsolutePosition();
	const end = endAnchor.getAbsolutePosition();
	const pStart = {x: start.x, y: start.y};
	const pEnd = {x: end.x, y: end.y};
	const directionX = start.x < end.x ? 1 : -1;

	if (pointWithin(
		start.x,
		end.x - endAnchor.nodeSize.width / 2 - lineMargin,
		start.x,
		end.x + endAnchor.nodeSize.width / 2 + lineMargin)){

		if (start.y > end.y){
			const p1 = {
				x: start.x,
				y: start.y + lineMargin
			};
			const p2 = {
				x: start.x + (startAnchor.nodeSize.width / 2 + lineMargin) * directionX,
				y: p1.y
			};
			const p3 = {
				x: p2.x,
				y: start.y - startAnchor.nodeSize.height - (start.y - startAnchor.nodeSize.height - end.y) / 2
			};
			const p4 = {
				x: end.x,
				y: p3.y
			};
			return [pStart, p1, p2, p3, p4, pEnd]
		}
		else{
			const p1 = {
				x: start.x,
				y: start.y + (end.y - endAnchor.nodeSize.height - start.y) / 2
			};
			const p2 = {
				x: end.x - (endAnchor.nodeSize.width / 2 + lineMargin) * directionX,
				y: p1.y
			};
			const p3 = {
				x: p2.x,
				y: end.y + lineMargin
			};
			const p4 = {
				x: end.x,
				y: p3.y
			};
			return [pStart, p1, p2, p3, p4, pEnd]
		}
	}
	if (start.y < end.y){
		const p1 = {
			x: start.x,
			y: end.y + lineMargin
		};
		const p2 = {
			x: end.x,
			y: end.y + lineMargin
		};
		return [pStart, p1, p2, pEnd];
	}
	const p1 = {
		x: start.x,
		y: start.y + lineMargin
	};
	const p2 = {
		x: end.x,
		y: start.y + lineMargin
	};
	return [pStart, p1, p2, pEnd];
}
