import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Address, LoginResponse, RegisterUser, User } from '../models/users';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private baseUrl: string = `${environment.apiUrl}auth/api/users`;
	private authSubject = new BehaviorSubject<LoginResponse | undefined>(undefined);
	private isGuestUserSubject = new BehaviorSubject<boolean>(false);

	constructor(private httpClient: HttpClient) {
		this.loadAuthFromStorage();
	}

	get auth$(): Observable<LoginResponse | undefined> {
		return this.authSubject.asObservable();
	}

	get isGuestUser$(): Observable<boolean> {
		return this.isGuestUserSubject.asObservable();
	}

	login(email: string, password: string): Observable<LoginResponse> {
		return this.httpClient.post<LoginResponse>(`${this.baseUrl}/login`, { email, password }).pipe(
			tap((user) => {
				this.setCurrentUser(user, email);
				this.setGuestUser(user.user.guest);
			}),
		);
	}

	register(user: RegisterUser): Observable<void> {
		return this.httpClient.post<void>(`${this.baseUrl}/register`, user);
	}

	logOut(): void {
		localStorage.removeItem('auth');
		localStorage.removeItem('contextId');
		this.authSubject.next(undefined);
	}

	setGuestUser(isGuest: boolean): void {
		this.isGuestUserSubject.next(isGuest);
		localStorage.setItem('guest-user', JSON.stringify(isGuest));
	}

	generateGuestUser(): Observable<LoginResponse> {
		return this.httpClient.post<LoginResponse>(`${this.baseUrl}/generate-guest-user`, {}).pipe(
			tap((response) => {
				this.setCurrentUser(response, response.user.email);
				this.setGuestUser(response.user.guest);
			}),
		);
	}

	convertGuestToUser(user: RegisterUser): Observable<void> {
		return this.httpClient
			.post<void>(`${this.baseUrl}/convert-guest-to-user`, user)
			.pipe(tap(() => this.setGuestUser(false)));
	}

	getGuestUser(): boolean | undefined {
		const guestUser = localStorage.getItem('guest-user');
		if (guestUser === null) {
			return undefined;
		}
		try {
			return JSON.parse(guestUser);
		} catch (error) {
			return undefined;
		}
	}

	setCurrentUser(loginResponse: LoginResponse, email: string): void {
		if (loginResponse.isAuthorized) {
			localStorage.setItem('auth', JSON.stringify(loginResponse));
			localStorage.setItem('email', email);

			this.authSubject.next(loginResponse);
		}
	}

	getCurrentUser(): LoginResponse | undefined {
		const auth = localStorage.getItem('auth');
		if (!auth) return;
		return JSON.parse(auth);
	}

	updateUser(user: User): Observable<User> {
		return this.httpClient.put<User>(`${this.baseUrl}/update-user`, user).pipe(
			tap((updatedUser) => {
				const currentUser = this.getCurrentUser();
				if (currentUser) {
					currentUser.user.firstName = updatedUser.firstName;
					currentUser.user.lastName = updatedUser.lastName;
					const adress: Address = {
						street: updatedUser.addresses[0].street ?? '',
						postalCode: updatedUser.addresses[0].postalCode ?? '',
						city: updatedUser.addresses[0].city ?? '',
						country: updatedUser.addresses[0].country ?? '',
						company: updatedUser.addresses[0].company ?? '',
						phoneNumber: updatedUser.addresses[0].phoneNumber ?? '',
					};
					if (currentUser.user.addresses.length > 0) currentUser.user.addresses[0] = adress;
					else currentUser.user.addresses.push(adress);
					localStorage.setItem('auth', JSON.stringify(currentUser));
				}
			}),
		);
	}

	updatePassword(
		oldPassword: string,
		newPassword: string,
		confirmNewPassword: string,
	): Observable<void> {
		return this.httpClient.put<void>(`${this.baseUrl}/update-password`, {
			oldPassword: oldPassword,
			newPassword: newPassword,
			confirmNewPassword: confirmNewPassword,
		});
	}

	getFullName(): string {
		const authData = JSON.parse(localStorage.getItem('auth')!);
		if (authData && authData.user) {
			const { firstName, lastName } = authData.user;
			return `${firstName} ${lastName}`.trim();
		}

		return '';
	}

	getEmail(): string {
		return localStorage.getItem('email')!.replace(/"/g, '');
	}

	resetPassword(token: string, email: string, newPassword: string): Observable<void> {
		return this.httpClient.put<void>(`${this.baseUrl}/reset-password`, {
			token: token,
			email: email,
			newPassword: newPassword,
		});
	}

	renewToken(): Observable<LoginResponse | undefined> {
		const userId = this.getCurrentUser()?.user.id;
		return this.httpClient.get<LoginResponse>(`${this.baseUrl}/renew-token/${userId}`).pipe(
			tap((authResponse) => {
				if (authResponse.isAuthorized) this.setCurrentUser(authResponse, this.getEmail());
				else this.logOut();
			}),
			catchError((_) => {
				this.logOut();
				return of(undefined);
			}),
		);
	}

	removeAccount(): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/delete-user`);
	}

	removeGuestAccount(): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/delete-guest-user`);
	}

	getValidToken(): Observable<string | undefined> {
		const auth = this.getCurrentUser();
		if (auth && this.isTokenValid(auth.token)) return of(auth.token);
		else {
			return this.renewToken().pipe(
				switchMap((authResponse) => {
					if (authResponse && authResponse.isAuthorized) return of(authResponse.token);
					else return of(undefined);
				}),
			);
		}
	}

	isTokenValid(token: string): boolean {
		try {
			const payload = JSON.parse(atob(token.split('.')[1]));
			const expiry = payload.exp;
			return expiry && Date.now() < expiry * 1000;
		} catch (e) {
			return false;
		}
	}

	private loadAuthFromStorage(): void {
		const auth = this.getCurrentUser();
		if (auth?.token && this.isTokenValid(auth.token)) this.authSubject.next(auth);
		else this.authSubject.next(undefined);
	}
}
