import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ManagedObjectAttribute } from '../../core/models/managed-object';
import { AttributeExpandedComponent } from './attribute-expanded/attribute-expanded.component';
import { AttributeService } from '../../core/services/attribute.service';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ConfirmCancelButtonGroupComponent } from '../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '../../core/pipes/safe-html.pipe';
import { AttributeType } from '../../core/models/attribute/attribute-type';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { ContentViewStateService } from '../../core/services/content-view-state.service';

@Component({
	selector: 'app-attribute',
	standalone: true,
	imports: [
		NgFor,
		NgIf,
		DatePipe,
		AttributeExpandedComponent,
		InputComponent,
		ConfirmCancelButtonGroupComponent,
		FormsModule,
		NgClass,
		SafeHtmlPipe,
		LoadingSpinnerComponent,
	],
	templateUrl: './attribute.component.html',
	styleUrls: ['./attribute.component.css', '../../../attribute-value-styles.css'],
})
export class AttributeComponent implements OnInit {
	name: string = '';
	AttributeType = AttributeType;
	timeout: ReturnType<typeof setTimeout> | undefined;
	showTooltip: boolean = false;

	@Input() managedObjectAttribute!: ManagedObjectAttribute;

	constructor(
		private attributeService: AttributeService,
		private contentViewStateService: ContentViewStateService,
	) {}

	ngOnInit(): void {
		// TODO: Refactor --> https://dev.azure.com/Advacy/Boswell2/_sprints/taskboard/The%20Avengers/Boswell2/Sprint%2010?workitem=2604
		this.contentViewStateService.setInitialManagedObjectAttributeExpanded(
			this.managedObjectAttribute.id,
			this.managedObjectAttribute.isExpanded,
		);
		this.managedObjectAttribute.isExpanded =
			this.contentViewStateService.isManagedObjectAttributeExpanded(this.managedObjectAttribute.id);
		if (this.managedObjectAttribute.id === 0) this.managedObjectAttribute.isExpanded = true;
	}

	onToggleExpanded(): void {
		if (this.managedObjectAttribute.isEditing || this.managedObjectAttribute.id === 0) return;

		this.managedObjectAttribute.isExpanded = !this.managedObjectAttribute.isExpanded;

		this.contentViewStateService.setManagedObjectAttributeExpanded(
			this.managedObjectAttribute.id,
			this.managedObjectAttribute.isExpanded,
		);
	}

	saveChanges(): void {
		this.attributeService.createManagedObjectAttribute(this.managedObjectAttribute);
	}

	cancel(): void {
		this.managedObjectAttribute.id === 0;
		this.attributeService.closeManagedObjectAttribute(this.managedObjectAttribute);
	}

	handleMouseEnter(): void {
		this.timeout = setTimeout(() => {
			this.showTooltip = true;
		}, 1000);
	}

	handleMouseLeave(): void {
		clearTimeout(this.timeout);
		this.showTooltip = false;
	}

	updateManagedObjectAttribute(): void {
		this.attributeService.updateManagedObjectAttribute(this.managedObjectAttribute);
	}

	onRename(): void {
		if (this.managedObjectAttribute.isExpanded) this.managedObjectAttribute.isEditing = true;
	}

	onSaveRename(): void {
		if (this.name) {
			this.managedObjectAttribute.name = this.name;
			this.updateManagedObjectAttribute();
		}
		this.managedObjectAttribute.isEditing = false;
	}

	onCancelRename(): void {
		this.managedObjectAttribute.isEditing = false;
	}
}
