<aside class="sidebar-container flex-col user-select width-full">
	<h2 class="heading">Drawings</h2>
	<section class="width-full">
		<ul class="flex-col remove-list-style">
			@if (loadingContexts) {
				<app-loading-spinner></app-loading-spinner>
			}
			@for (context of contexts; track context) {
				<app-context-item
					[context]="context"
					[currentContext]="currentContext"
					(toggleExpansion)="onToggleExpansion(context)"
					(deleteDrawing)="onDeleteDrawing(context)"
					(renameDrawing)="onRenameDrawing(context)"
					(deleteReadOnlyDrawing)="onDeleteContextAccess(context)"
				></app-context-item>
			}
			@if (creatingNewDrawing) {
				<app-loading-spinner></app-loading-spinner>
			}
		</ul>
	</section>
	<section class="btn-container">
		@if (!addDrawingFormVisible) {
			<app-button
				extraClass="create-btn"
				label="+ New Drawing"
				(buttonClick)="showAddDrawingForm()"
			>
			</app-button>
		} @else {
			<section class="gap-small flex-col">
				<app-input
					placeholder="Name"
					[(ngModel)]="newDrawingName"
					(enterPressed)="addNewDrawing()"
				></app-input>
				<app-button
					extraClass="create-btn"
					[label]="newDrawingName.trim().length > 0 ? 'Add' : 'Cancel'"
					(buttonClick)="newDrawingName.trim().length > 0 ? addNewDrawing() : cancelNewDrawing()"
					[showLoadingSpinner]="creatingNewDrawing"
				></app-button>
			</section>
		}
		@if (isShareLinkModalVisible) {
			<app-modal [title]="'Share Link'" (closeEmitter)="onModalClose()">
				<div class="input-container">
					<input class="response-input" [value]="sharedContextResponse" readonly />
					<div class="tooltip-container">
						<button (click)="copyToClipboard()" class="copy-icon-btn">
							<i class="bi bi-copy"></i>
						</button>
						<span class="tooltip-message">{{ copySuccess ? 'URL Copied' : 'Copy URL' }}</span>
					</div>
				</div>
			</app-modal>
		}
	</section>
</aside>
