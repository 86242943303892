import { Location } from "../../shared/constants/location.js";
import { parentNodesVerticalOverlap } from "../../deluxe-math.js";


export function getPoints(startAnchor, endAnchor, lineMargin){
	const start = startAnchor.getAbsolutePosition();
	const end = endAnchor.getAbsolutePosition();
	const pStart = {
		x: start.x,
		y: start.y
	};
	const pEnd = {
		x: end.x,
		y: end.y
	};

	if ((startAnchor.location === Location.Right && start.x > end.x) || (startAnchor.location === Location.Left && start.x < end.x) ){
		const directionX = startAnchor.location === Location.Right ? 1 : -1;
		const directionY = start.y < end.y ? 1 : -1
		if(parentNodesVerticalOverlap({"y": start.y, "parentHeight": startAnchor.nodeSize.height}, {"y": end.y, "parentHeight": endAnchor.nodeSize.height}))
		{
			const p1 = {
				x: start.x + lineMargin * directionX,
				y: start.y
			};
			const p2 = {
				x: p1.x,
				y: start.y - (startAnchor.nodeSize.height / 2 + lineMargin) * directionY
			};
			const p3 = {
				x: end.x - lineMargin * directionX,
				y: p2.y
			};
			const p4 = {
				x: p3.x,
				y: end.y
			};
			return [pStart, p1, p2, p3, p4, pEnd];

		}
		else {
			const p1 = {
				x: start.x + lineMargin * directionX,
				y: start.y
			};
			const p2 = {
				x: p1.x,
				y: start.y + (end.y - start.y) / 2
			};
			const p3 = {
				x: end.x - lineMargin * directionX,
				y: p2.y
			};
			const p4 = {
				x: p3.x,
				y: end.y
			};
			return [pStart, p1, p2, p3, p4, pEnd];
		}
	}

	const p1 = {
		x: start.x + (end.x - start.x) / 2,
		y: start.y
	};
	const p2 = {
		x: end.x - (end.x - start.x) / 2,
		y: end.y
	};
	return [pStart, p1, p2, pEnd];
}