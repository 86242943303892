import {
	Injectable,
	ApplicationRef,
	ComponentRef,
	createComponent,
	EnvironmentInjector,
} from '@angular/core';
import { ModalComponent, ModalMode } from '../../shared/modal/modal.component';
import { Observable } from 'rxjs';

export enum ModalResult {
	Save,
	Discard,
	Cancel,
	Ok,
	AutoClose,
}

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modalRef: ComponentRef<ModalComponent> | null = null;

	constructor(
		private environmentInjector: EnvironmentInjector,
		private appRef: ApplicationRef,
	) {}

	open(options?: {
		size?: string;
		title?: string;
		content?: string;
		mode?: ModalMode;
		autoClose?: boolean;
		autoCloseTime?: number;
		disableBackdrop?: boolean;
		buttonLabel?: string;
	}): Observable<ModalResult> {
		return new Observable<ModalResult>((observer) => {
			this.modalRef = createComponent(ModalComponent, {
				environmentInjector: this.environmentInjector,
			});

			if (options?.size) this.modalRef.instance.size = options.size;
			if (options?.title) this.modalRef.instance.title = options.title;
			if (options?.content) this.modalRef.instance.content = options.content;
			if (options?.mode) this.modalRef.instance.mode = options.mode;
			if (options?.autoClose !== undefined) this.modalRef.instance.autoClose = options.autoClose;
			if (options?.autoCloseTime !== undefined)
				this.modalRef.instance.autoCloseTime = options.autoCloseTime;
			if (options?.disableBackdrop)
				this.modalRef.instance.disableBackdrop = options.disableBackdrop;
			if (options?.buttonLabel) this.modalRef.instance.buttonLabel = options.buttonLabel;

			this.modalRef.instance.save.subscribe(() => {
				observer.next(ModalResult.Save);
				observer.complete();
				this.close();
			});

			this.modalRef.instance.discard.subscribe(() => {
				observer.next(ModalResult.Discard);
				observer.complete();
				this.close();
			});

			this.modalRef.instance.cancel.subscribe((result: ModalResult) => {
				observer.next(result);
				observer.complete();
				this.close();
			});

			this.appRef.attachView(this.modalRef.hostView);

			const domElem = this.modalRef.location.nativeElement;
			document.body.appendChild(domElem);
		});
	}

	close(): void {
		if (this.modalRef) {
			this.appRef.detachView(this.modalRef.hostView);
			this.modalRef.destroy();
			this.modalRef = null;
		}
	}
}
