<main class="form-main-wrapper">
	<section class="form-container flex-col">
		<section class="form-heading-container flex-col">
			<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
			<h1 class="form-headings">Forgot your password?</h1>
			<h2 class="form-headings font-smaller">Please enter your email to receive a reset link</h2>
		</section>
		<form
			class="login-form-container flex-col"
			#forgotPasswordForm="ngForm"
			(ngSubmit)="onForgotPassword(forgotPasswordForm)"
		>
			<app-input
				labelText="Your email"
				type="email"
				name="email"
				class="margin-bottom"
				[(ngModel)]="userEmail"
				[maxLength]="255"
				placeholder="example@example.com"
				email
				required
			></app-input>
			@if (emailSent) {
				<div>
					{{ emailSent }}
					Didn't receive an email?
				</div>
			}
			@if (forgotPasswordForm.submitted && forgotPasswordForm.controls['email'].invalid) {
				<div class="error-msg">Please enter a valid email</div>
			}
			<app-button
				class="shadow"
				[label]="emailSent ? 'Resend email' : 'Send email'"
				type="submit"
				[showLoadingSpinner]="forgotPassword"
			></app-button>
			<p class="login-option margin-top">
				Back to <a class="login-option-link" href="/login">login</a>
			</p>
		</form>
	</section>
</main>
