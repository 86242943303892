import "./lib/core/panzoom/panzoom.js";
import { CustomEventName } from "./lib/shared/constants/custom-events.js";
import { containerId, svgContainerId } from "./lib/shared/constants/id.js";
import { canvasBoundsPadding, canvasSize, maxZoom, minZoom, namespace } from "./lib/shared/constants/svg.js";
import { CreateNodeHandler, CreateNodeCommand } from "./lib/commands/create-node-command.js";
import { UpdateActionMenuSelectionBehavior } from "./lib/behaviors/update-action-menu-selection-behavior.js";
import { DeleteNodeCommand, DeleteNodeHandler } from "./lib/commands/delete-node-command.js";
import { CreateRelationCommand, CreateRelationHandler } from "./lib/commands/create-relation-command.js";
import { MouseMoveEventBehavior } from "./lib/behaviors/mouse-move-event-behavior.js";
import { Symbol } from "./lib/shared/static/symbols.js";
import { DeleteRelationCommand, DeleteRelationHandler } from "./lib/commands/delete-relation-command.js";
import { CursorBehavior } from "./lib/behaviors/cursor-behavior.js";
import { RelationSymbol } from "./lib/shared/static/relation-symbol.js";
import { CreateMarkerAreaBehavior } from "./lib/behaviors/create-marker-area-behavior.js";
import { CreateFromNodesCommand, CreateFromNodesHandler } from "./lib/commands/create-from-nodes-command.js";
import { ResetZoomCommand, ResetZoomHandler } from "./lib/commands/reset-zoom-command.js";
import { FocusboxBehavior } from "./lib/behaviors/focusbox-behavior.js";
import { ApplicationState } from "./lib/shared/constants/application-state.js";
import { ActionMenuSelection } from "./lib/shared/constants/action-menu-selection.js";
import { EventSelection } from "./lib/shared/constants/event-selection.js";
import { NodeClickedCommand } from "./lib/commands/node-clicked-command.js";
import { NodeRepository } from "./lib/infrastructure/node-repository.js";
import { RelationRepository } from "./lib/infrastructure/relation-repository.js";
import { TryDeleteNodeCommand } from "./lib/commands/try-delete-command.js";

export class DeluxeDraw {
	#rootContainer;
	#svgContainer;
	#context;
	#applicationState = ApplicationState.Edit;
	#relationSymbols = { symbol1: Symbol.Default, symbol2: Symbol.Default };

	init() {
		this.#rootContainer = document.getElementById(containerId);
		this.#svgContainer = this.#renderSvgContainer();
		this.#initPanzoom();	
		this.#registerListeners();
		this.#registerHandlers();
		this.#registerBehaviors();
	}
	
	openContext(context, action) {
		this.#context = context;
		this.#applicationState = this.#getApplicationStateByActionMenuSelection(EventSelection[action].detail.actionMenuSelection)
		this.#createStaticContent();
		this.#createContent();
    	this.#setPanZoomTransformValues();
	}

	onToolboxAction(action) {
		document
		.getElementById(containerId)
		.dispatchEvent(EventSelection[action]);
	}

	updateNodeAndReferenceId(referenceId, nodeId, nodeGuid) {
		NodeRepository.updateNodeByGuid(referenceId, nodeId, nodeGuid);
	}

	removeNode(node) {
		DeleteNodeCommand.send({ node: node });
    }

	updateNodeName(referenceId, newName, nodeType) {
		NodeRepository.updateNodeName(referenceId, newName, nodeType);
	}

	deleteContextNode(referenceId, nodeType){
		NodeRepository.deleteContextNode(referenceId, nodeType);
	}

	updateRelation(relationId, relationGuid) {
		RelationRepository.updateIdByGuid(relationId, relationGuid);
	}

	#registerListeners() {
		this.#rootContainer.addEventListener(CustomEventName.CreateNode, (e) => {
			CreateNodeCommand.send({
				shape: e.detail.shape,
				contextName: this.#context.name,
				type: e.detail.type,
			});
		});
		this.#rootContainer.addEventListener(CustomEventName.SetRelations, (e) => {
			this.#relationSymbols = e.detail.symbols;
		});
		this.#rootContainer.addEventListener(CustomEventName.TryDeleteNode, (e) => {
			TryDeleteNodeCommand.send({ node: e.detail });
		});
		this.#rootContainer.addEventListener(CustomEventName.AnchorClicked, (e) => {
			CreateRelationCommand.send({
				anchor: e.detail,
				relationSymbols: this.#relationSymbols,
			});
		});
		this.#rootContainer.addEventListener(
			CustomEventName.DeleteRelation,
			(e) => {
				DeleteRelationCommand.send({ relation: e.detail });
			}
		);
		this.#rootContainer.addEventListener(CustomEventName.ResetZoom, () => {
			ResetZoomCommand.send({ contextId: this.#context.id });
		});
		this.#rootContainer.addEventListener(CustomEventName.NodeClicked, (e) => {
			NodeClickedCommand.send({ node: e.detail });
		});
	}

	#registerHandlers() {
		new CreateNodeHandler(this.#rootContainer);
		new CreateFromNodesHandler(this.#rootContainer);
		new DeleteNodeHandler(this.#rootContainer);
		new CreateRelationHandler(this.#rootContainer, this.#svgContainer);
		new DeleteRelationHandler(this.#rootContainer);
		new ResetZoomHandler(this.#rootContainer);
	}

	#registerBehaviors() {
		const mouseMoveEventBehavior = new MouseMoveEventBehavior(
			this.#rootContainer,
			this.#svgContainer,
			this.#applicationState
		);
		const cursorBehavior = new CursorBehavior();
		const createMarkerAreaBehavior = new CreateMarkerAreaBehavior(
			this.#svgContainer
		);
		const updateActionMenuSelectionBehavior =
		new UpdateActionMenuSelectionBehavior();
		const focusboxBehavior = new FocusboxBehavior(this.#rootContainer);

    [
      CustomEventName.CreateNode,
      CustomEventName.SetRelations,
      CustomEventName.ActionMenuSelection,
      CustomEventName.ResetZoom,
    ].forEach((eventName) => {
      this.#rootContainer.addEventListener(eventName, (e) => {
        this.#applicationState = this.#getApplicationStateByActionMenuSelection(
          e.detail.actionMenuSelection
        );
        updateActionMenuSelectionBehavior.handle(this.#applicationState);
        mouseMoveEventBehavior.handle(this.#applicationState);
        cursorBehavior.handle(this.#applicationState, this.#svgContainer);
        createMarkerAreaBehavior.handle(this.#applicationState);
        focusboxBehavior.handle(e.detail.actionMenuSelection);
      });
    });
  }

	#renderSvgContainer() {
		this.#rootContainer.innerHTML = "";

		const svgContainer = document.createElementNS(namespace, "svg");
		svgContainer.setAttribute("width", canvasSize);
		svgContainer.setAttribute("height", canvasSize);
		svgContainer.setAttribute("id", svgContainerId);
		svgContainer.style.backgroundImage = "url('assets/svg/grid.svg')";

		this.#rootContainer.appendChild(svgContainer);
		return svgContainer;
	}

	#createStaticContent() {
		RelationSymbol.create(this.#svgContainer);
	}

	#createContent() {
		CreateFromNodesCommand.send({
			nodes: this.#context.nodes,
			applicationState: this.#applicationState,
		});
	}

	#getApplicationStateByActionMenuSelection(actionMenuSelection) {
    	switch (actionMenuSelection) {
		case ActionMenuSelection.CreateShape:
		case ActionMenuSelection.CreateRelation:
		case ActionMenuSelection.Edit:
			return ApplicationState.Edit;
		case ActionMenuSelection.Navigate:
			return ApplicationState.Navigate;
		case ActionMenuSelection.Delete:
			return ApplicationState.Delete;
		case ActionMenuSelection.MarkerArea:
			return ActionMenuSelection.MarkerArea;
		case ActionMenuSelection.ResetZoom:
		case ActionMenuSelection.Focusbox:
			return this.#applicationState;
    	}
	}

	#initPanzoom() {
		window.panZoom = panzoom(this.#svgContainer, {
      		minZoom: minZoom,
    		maxZoom: maxZoom,
    		boundsPadding: canvasBoundsPadding,
    	});

		this.#setDefaultZoomValues();
	}

  	#setPanZoomTransformValues() {
    	window.panZoom.on("panend", () => {
      		const transform = window.panZoom.getTransform();
      		sessionStorage.setItem(`panZoomValue-${this.#context.id}`, JSON.stringify({ x: transform.x, y: transform.y, zoom: transform.scale }));
		});

    	const storedTransform = JSON.parse(sessionStorage.getItem(`panZoomValue-${this.#context.id}`));

		if (storedTransform) {
			window.panZoom.zoomAbs(0, 0, storedTransform.zoom);
			window.panZoom.moveTo(storedTransform.x, storedTransform.y);
		}
			
		else this.#setDefaultZoomValues();
  	}

	#setDefaultZoomValues(){
		window.panZoom.zoomAbs(0, 0, 1);
		window.panZoom.zoomTo(0, 0, 1);
		window.panZoom.moveTo(-0.5 * canvasSize, -0.5 * canvasSize);
	}
}
