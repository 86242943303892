<li class="folder-container light-blue-hover border-radius">
	<section
		class="flex-row gap-small folder-closed-section"
		(mouseenter)="showDotMenu = true"
		(mouseleave)="showDotMenu = false"
	>
		@if (!folder.isEditing) {
			<button class="default-btn keep-scale folder-btn" (click)="onToggleExpansion()">
				<img
					class="arrow-icon"
					[src]="
						folder.isExpanded ? 'assets/icon/caret-down-fill.svg' : 'assets/icon/caret-right.svg'
					"
					alt="arrow icon"
				/>
				<p>{{ folder.name }}</p>
			</button>
			<section class="dot-menu-wrapper">
				@if (folder.isLoading) {
					<app-loading-spinner></app-loading-spinner>
				} @else if (showDotMenu) {
					<section class="dot-menu-container">
						<app-dot-menu>
							<app-dot-menu-option label="Rename" (click)="showRenameFolderForm()" ></app-dot-menu-option>
							<app-dot-menu-option label="Delete" (click)="onDeleteFolder()" ></app-dot-menu-option>
						</app-dot-menu>
					</section>
				}
			</section>
		} @else {
			<app-input
				[(ngModel)]="newFolderName"
				[model]="folder.name"
				(enterPressed)="onRenameFolder()"
			></app-input>
			<app-confirm-cancel-button-group
				(confirm)="onRenameFolder()"
				(cancel)="onCancelRenaming()"
				[disabled]="!newFolderName || !folder.isEditing"
				[showLoadingSpinner]="folder.isLoading"
			></app-confirm-cancel-button-group>
		}
	</section>
	@if (folder.isExpanded) {
		<section class="flex-col folder-open-section gap-small">
			<section class="flex-row attribute-menu-container">
				<app-attribute-menu
					(collapseAllAttributes)="onCollapseAllAttributes()"
					(expandAllAttributes)="onExpandAllAttributes()"
					[attributes]="attributes"
					[folderId]="folder.id"
					[managedObjectAttributes]="folder.managedObjectAttributes"
				></app-attribute-menu>
			</section>
			@for (
				managedObjectAttribute of folder.managedObjectAttributes;
				track managedObjectAttribute
			) {
				<app-attribute [managedObjectAttribute]="managedObjectAttribute"></app-attribute>
			}
		</section>
	}
</li>
