import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-dot-menu-option',
	standalone: true,
	imports: [],
	templateUrl: './dot-menu-option.component.html',
	styleUrl: './dot-menu-option.component.css',
})
export class DotMenuOptionComponent {
	@Input() label: string = '';
	@Output() action = new EventEmitter<void>();

	onAction(): void {
		this.action.emit();
	}
}
