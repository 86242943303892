import { CommandName } from "../shared/constants/command-name.js";
import { containerId } from "../shared/constants/id.js";
import { NodeRepository } from "../infrastructure/node-repository.js";
import { RelationRepository } from "../infrastructure/relation-repository.js";

export class DeleteNodeCommand
{
	static commandName = CommandName.DeleteNodeCommand;

	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.DeleteNodeCommand, { detail: detail, bubbles: true }))
	}
}

export class DeleteNodeHandler
{
	#rootContainer;

    constructor(rootContainer)
    {
		this.#rootContainer = rootContainer;
        this.#rootContainer.addEventListener(DeleteNodeCommand.commandName, (e) => {
			this.#removeConnectedRelations(e.detail.node);
			this.#removeNode(e.detail.node);
			e.detail.node.clear()
        });
    }

	#removeConnectedRelations(node) {
		RelationRepository.deleteManyByGuid(node.getConnectedRelations().map(relation => relation.guid));
	}

	#removeNode(node) {
		NodeRepository.deleteByGuid(node.guid);
	}
}