import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { authGuard } from './core/guards/auth.guard';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UserSettingsComponent } from './pages/settings/user-settings/user-settings.component';
import { PasswordComponent } from './pages/settings/password/password.component';
import { PaymentComponent } from './pages/settings/payment/payment.component';
import { OverviewComponent } from './pages/settings/overview/overview.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { FAQComponent } from './pages/settings/faq-and-contact/faq.component';
import { ContactComponent } from './pages/settings/faq-and-contact/contact/contact.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RegisterGuestComponent } from './pages/register-guest/register-guest.component';
import { ShareDrawingComponent } from './pages/share-drawing/share-drawing.component';

export const routes: Routes = [
	{ path: '', title: 'Home', component: HomeComponent, canActivate: [authGuard] },
	{ path: 'share-drawing', title: 'Share drawing', component: ShareDrawingComponent },
	{ path: 'login', title: 'Login', component: LoginComponent },
	{ path: 'login/forgot-password', title: 'Forgot password', component: ForgotPasswordComponent },
	{ path: 'register', title: 'Register', component: RegisterComponent },
	{ path: 'register-guest', title: 'Register', component: RegisterGuestComponent },
	{ path: 'contact-form', title: 'Contact form', component: ContactUsComponent },
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [authGuard],
		children: [
			{ path: 'overview', title: 'Overview', component: OverviewComponent },
			{ path: 'user', title: 'User Settings', component: UserSettingsComponent },
			{ path: 'password', title: 'Password', component: PasswordComponent },
			{ path: 'payment', title: 'Payment', component: PaymentComponent },
			{ path: 'faq', title: 'FAQ', component: FAQComponent },
			{ path: 'faq/contact', title: 'Contact', component: ContactComponent },
			{ path: '', redirectTo: 'overview', pathMatch: 'full' },
		],
	},
	{
		path: 'reset-password',
		title: 'Reset password',
		component: ResetPasswordComponent,
	},
	{ path: 'verify-email', title: 'Verifying email', component: VerifyEmailComponent },
	{ path: ':contextId', title: 'Context', component: HomeComponent, canActivate: [authGuard] },
];
