<section class="flex-col width-full content-view-container">
	@if (managedObject) {
		<section class="flex-row width-full gap-small">
			@if (!managedObject.isEditing) {
				<button
					class="default-btn keep-scale content-name border-radius"
					(click)="onShowEditForm()"
				>
					@if (!loading) {
						<p>{{ managedObject.name }}</p>
					}
					<img class="pencil-icon" src="assets/icon/pencil.svg" alt="Pencil icon" />
				</button>
			} @else {
				<app-input
					[(ngModel)]="newManagedObjectName"
					[model]="managedObject.name"
					(enterPressed)="onRenameManagedObject(managedObject)"
				></app-input>
				<app-confirm-cancel-button-group
					(confirm)="onRenameManagedObject(managedObject)"
					(cancel)="onCancelRenamingManagedObject()"
					[disabled]="!newManagedObjectName || !managedObject.isEditing"
					[showLoadingSpinner]="managedObject.isLoading"
				></app-confirm-cancel-button-group>
			}
		</section>
		<section>
			<p>{{ managedObject.description }}</p>
		</section>
		<section>
			<ul class="flex-col gap-small">
				@if (loading) {
					<app-loading-spinner></app-loading-spinner>
				} @else {
					@if (managedObject) {
						@for (folder of managedObject.folders; track folder) {
							<app-folder
								[folder]="folder"
								[isLoading]="loadingNewFolder"
								[attributes]="attributes"
								(renameFolder)="onRenameFolder()"
								(deleteFolder)="onDeleteFolder($event)"
							></app-folder>
						}
					}
				}
			</ul>
		</section>
		<section class="btn-container">
			@if (!loading) {
				@if (!addFolderFormVisible) {
					<app-button
						extraClass="create-btn"
						label="+ New Folder"
						(buttonClick)="showAddFolderForm()"
					>
					</app-button>
				} @else {
					<section class="flex-col gap-small">
						<app-input
							placeholder="Name"
							[(ngModel)]="newFolderName"
							(enterPressed)="createNewFolder()"
						></app-input>
						<app-button
							extraClass="create-btn"
							[label]="newFolderName.trim().length > 0 ? 'Add' : 'Cancel'"
							(buttonClick)="
								newFolderName.trim().length > 0 ? createNewFolder() : cancelNewFolder()
							"
							[showLoadingSpinner]="loadingNewFolder"
						>
						</app-button>
					</section>
				}
			}
		</section>
	} @else {
		<app-loading-spinner></app-loading-spinner>
	}
</section>
