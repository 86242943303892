export const containerId = "dd-container";
export const toolboxId = "dd-toolbox";
export const focusboxId = "dd-focusbox";
export const svgContainerId = "dd-svgContainer";
export const zoomboxId = "dd-zoombox";
export const cloneContainerId = "dd-cloneContainer";
export const cloneSvgContainerId = "dd-svgClone";
export const zoomboxResizeElementId = "zoomboxResizeElement";
export const zoomboxChangeZoomContainerId = "zoomboxChangeZoomContainer";
export const zoomButtonPlus = "zoomButtonPlus";
export const zoomButtonMinus = "zoomButtonMinus";
export const nodeToolbarId = "dd-nodeToolbar";
export const editNodeSelectShapeElement = "editNodeSelectShapeElement";
export const editNodeInputElement = "editNodeInputElement";