import {
	Component,
	EventEmitter,
	Output,
	Input,
	ElementRef,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { ButtonComponent } from '../button/button/button.component';
import { interval, Subscription } from 'rxjs';
import { ModalResult } from '../../core/services/modal.service';
import { ConfirmCancelButtonGroupComponent } from '../buttons-confirm-cancel/confirm-cancel-button-group.component';

export enum ModalMode {
	Edit,
	Info,
	Delete,
}

@Component({
	selector: 'app-modal',
	standalone: true,
	imports: [ButtonComponent, ConfirmCancelButtonGroupComponent],
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() size?: string = 'md';
	@Input() title?: string = '';
	@Input() content?: string = '';
	@Input() mode: ModalMode = ModalMode.Edit;
	@Input() autoClose: boolean = false;
	@Input() autoCloseTime: number = 5;
	@Input() disableBackdrop: boolean = false;
	@Input() buttonLabel: string = '';

	@Output() save = new EventEmitter<ModalResult>();
	@Output() discard = new EventEmitter<ModalResult>();
	@Output() cancel = new EventEmitter<ModalResult>();

	remainingTime: number = 0;
	autoCloseSubscription: Subscription | undefined;

	ModalResult = ModalResult;
	ModalMode = ModalMode;

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		if (this.autoClose) {
			this.remainingTime = this.autoCloseTime;
			this.autoCloseSubscription = interval(1000).subscribe(() => {
				this.remainingTime--;
				if (this.remainingTime <= 0) {
					this.close(ModalResult.AutoClose);
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.clearAutoClose();
	}

	close(result: ModalResult = ModalResult.Cancel): void {
		this.cancel.emit(result);
		this.elementRef.nativeElement.remove();
		this.clearAutoClose();
	}

	saveChanges(): void {
		this.save.emit(ModalResult.Save);
		this.elementRef.nativeElement.remove();
		this.clearAutoClose();
	}

	discardChanges(): void {
		this.discard.emit(ModalResult.Discard);
		this.elementRef.nativeElement.remove();
		this.clearAutoClose();
	}

	onModalContentClick(event: Event): void {
		event.preventDefault();
	}

	clearAutoClose(): void {
		if (this.autoCloseSubscription) {
			this.autoCloseSubscription.unsubscribe();
			this.autoCloseSubscription = undefined;
		}
	}
}
