import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Relation } from '../models/deluxedraw';
import { environment } from '../../../environments/environment';
import { CreateRelation } from '../models/deluxedraw/create-relation';

@Injectable({
	providedIn: 'root',
})
export class RelationService {
	private baseUrl: string = `${environment.apiUrl}relation/api`;
	constructor(private httpClient: HttpClient) {}

	createRelation(createRelation: CreateRelation): Observable<Relation> {
		return this.httpClient.post<Relation>(`${this.baseUrl}/relations`, createRelation);
	}

	removeRelation(id: number): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/relations/${id}`);
	}
}
