import { CommandName } from "../shared/constants/command-name.js";
import { containerId } from "../shared/constants/id.js";
import { RelationRepository } from "../infrastructure/relation-repository.js";

export class DeleteRelationCommand
{
	static commandName = CommandName.DeleteRelationCommand;

	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.DeleteRelationCommand, { detail: detail, bubbles: true }))
	}
}

export class DeleteRelationHandler
{
	#rootContainer;

    constructor(rootContainer)
    {
		this.#rootContainer = rootContainer;
        this.#rootContainer.addEventListener(DeleteRelationCommand.commandName, (e) => {
			this.#deleteRelation(e.detail.relation);
        });
    }

	#deleteRelation(relation) {
        RelationRepository.deleteByGuid(relation.guid);
	}
}