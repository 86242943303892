import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UserService } from './core/services/user.service';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { ToastrComponent } from './shared/toastr/toastr.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [HomeComponent, LoginComponent, RouterOutlet, TopBarComponent, ToastrComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.css',
})
export class AppComponent {
	constructor(private userService: UserService) {}

	@HostListener('window:focus', [])
	onWindowFocus(): void {
		if (this.userService.getCurrentUser()) this.userService.renewToken().subscribe();
	}
}
