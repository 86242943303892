<main class="form-main-wrapper gap-large">
	<section class="form-heading-container flex-col">
		<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
		<h1 class="form-headings">Login to Boswell</h1>
	</section>
	<section class="login-page-wrapper flex-row">
		<section class="form-container flex-col">
			@if (!cookiePreferencesAccepted) {
				<app-cookies></app-cookies>
			}
			<form
				(ngSubmit)="onLogin(loginForm)"
				#loginForm="ngForm"
				class="login-form-container flex-col fixed-height"
			>
				<app-input
					labelText="Email adress"
					type="email"
					[(ngModel)]="email"
					name="email"
					placeholder="name@example.com"
					[maxLength]="255"
					[toLowerCase]="true"
					required
					email
				></app-input>
				@if (loginForm.submitted && loginForm.controls['email'].invalid) {
					<div class="error-msg">Please enter a valid email address.</div>
				}

				<app-input
					labelText="Password"
					type="password"
					[(ngModel)]="password"
					name="password"
					placeholder="Password"
					[maxLength]="64"
					required
				></app-input>
				@if (loginForm.submitted && loginForm.controls['password'].invalid) {
					<div class="error-msg">Password is required.</div>
				}
				@if (invalidFormMessage) {
					<div class="error-msg">
						{{ invalidFormMessage }}
					</div>
				}
				@if (isLockedOut) {
					<div class="error-msg">
						<p>
							You have been locked out, try again later or reset your password to unlock your
							account
						</p>
					</div>
				}
				<p class="align-self-end forgot-password font-smaller" routerLink="/login/forgot-password">
					Forgot password?
				</p>
				<app-button
					class="margin-top"
					label="Login"
					type="submit"
					[showLoadingSpinner]="loggingIn"
				></app-button>
			</form>
			<section class="align-self-end">
				<p class="register">
					Not registered?
					<a class="create-account login-link" routerLink="/register">Create account here</a>
				</p>
				<p class="register">
					Problem with login?
					<a class="create-account login-link" routerLink="/contact-form">Contact us here</a>
				</p>
			</section>
		</section>
		<section class="separator flex-col">or</section>
		<section class="form-container guest-btn-container flex-col">
			<app-button
				label="Continue as Guest"
				(buttonClick)="onLoginAsGuest()"
				[showLoadingSpinner]="loadingGuest"
			></app-button>
			<section>
				<p>
					Prefer to explore first? Use this option to familiarize yourself with the program and its
					features before creating an account and becoming part of our community.
				</p>
			</section>
		</section>
	</section>
</main>
