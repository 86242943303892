<section class="flex-row container">
	@if (validManagedObjectAttributes.length) {
		<button
			class="default-btn keep-scale attribute-btn flex-row"
			(click)="onExpandAllAttributes()"
			(mouseenter)="handleMouseEnter('expand')"
			(mouseleave)="handleMouseLeave('expand')"
		>
			<i class="bi bi-arrows-expand"></i>
			<p class="tooltip capitalize" *ngIf="showExpandTooltip">Expand all</p>
		</button>
		<button
			class="default-btn keep-scale attribute-btn flex-row"
			(click)="onCollapseAllAttributes()"
			(mouseenter)="handleMouseEnter('collapse')"
			(mouseleave)="handleMouseLeave('collapse')"
		>
			<i class="bi bi-arrows-collapse"></i>
			<p class="tooltip capitalize font-smaller" *ngIf="showCollapseTooltip">Collapse all</p>
		</button>
	}
	<button
		class="default-btn keep-scale attribute-btn flex-row"
		(click)="onToggleShowCreateAttributeMenu($event)"
	>
		<i class="bi bi-file-earmark-plus-fill"></i> New Item
	</button>
	@if (showCreateAttributeMenu) {
		<section
			class="flex-row container create-attribute-menu-container shadow border-radius position-relative"
			#menu
		>
			@for (attribute of attributes; track attribute; let i = $index) {
				<button
					class="default-btn keep-scale create-attribute flex-row ellipsis"
					(click)="onCreateAttribute(attribute, i)"
					(mouseenter)="handleMouseEnterAttribute(i)"
					(mouseleave)="handleMouseLeaveAttribute(i)"
				>
					<i class="{{ attribute.icon }}"></i>
					<p class="ellipsis">{{ attribute.name }}</p>
					<p
						class="tooltip attribute-tooltip font-smaller capitalize"
						*ngIf="showAttributeTooltip[i]"
					>
						{{ attribute.name }}
					</p>
				</button>
			}
		</section>
	}
</section>
