import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { DropDownComponent } from './drop-down/drop-down.component';
import { NgIf } from '@angular/common';
import { UserService } from '../../../core/services/user.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-user-profile',
	standalone: true,
	imports: [DropDownComponent, NgIf],
	templateUrl: './user-profile.component.html',
	styleUrl: './user-profile.component.css',
})
export class UserProfileComponent implements OnInit {
	nameInitial: string = '';
	showDropDown: boolean = false;

	constructor(
		private elementRef: ElementRef,
		private userService: UserService,
		private router: Router,
	) {}

	ngOnInit(): void {
		const fullName = this.userService.getFullName();
		if (fullName) this.nameInitial = this.getInitials(fullName);

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.showDropDown = false;
			}
		});
	}

	getInitials(fullName: string): string {
		const names = fullName.replace(/['"]+/g, '').trim().split(' ');
		const initials = names.map((name) => name.charAt(0).toUpperCase()).join('');
		return initials;
	}

	toggleDropDown(): void {
		this.showDropDown = !this.showDropDown;
	}

	@HostListener('document:click', ['$event.target'])
	onClickOutside(target: Event): void {
		if (this.showDropDown) {
			const clickedInside = this.elementRef.nativeElement.contains(target);
			if (!clickedInside) this.showDropDown = false;
		}
	}
}
