<section class="flex-col page-container">
	<section>
		<h1 class="form-headings">Change password</h1>
	</section>
	<section class="width-full">
		<form
			#changePasswordForm="ngForm"
			class="login-form-container flex-col"
			(ngSubmit)="onChangePassword(changePasswordForm)"
		>
			<app-input
				labelText="Current password"
				type="password"
				name="currentPassword"
				[(ngModel)]="currentPassword"
			></app-input>
			<div
				*ngIf="
					changePasswordForm.submitted && changePasswordForm.controls['currentPassword'].invalid
				"
				class="error-msg"
			>
				Password must contain at least 8 characters
			</div>
			<app-input
				labelText="New password"
				type="password"
				name="newPassword"
				[(ngModel)]="newPassword"
			></app-input>
			<div
				*ngIf="changePasswordForm.submitted && changePasswordForm.controls['newPassword'].invalid"
				class="error-msg"
			>
				Please confirm your password
			</div>
			<app-input
				labelText="Confirm new password"
				type="password"
				name="confirmNewPassword"
				[(ngModel)]="confirmNewPassword"
				(ngModelChange)="onConfirmPasswordInput()"
			></app-input>
			<div
				*ngIf="!passwordsMatch && changePasswordForm.controls['confirmNewPassword']?.dirty"
				class="error-msg"
			>
				Passwords doesn't match
			</div>
			<div *ngIf="invalidFormMessage" class="error-msg">
				{{ invalidFormMessage }}
			</div>
			<div *ngIf="passwordChangedSuccessfully">
				{{ passwordChangedSuccessfully }}
			</div>
			<div *ngIf="passwordChangedFailed" class="error-msg">
				{{ passwordChangedFailed }}
			</div>
			<section class="flex-row gap-small">
				<app-button
					class="option-btn margin-top"
					label="Cancel"
					(buttonClick)="onCancel()"
				></app-button>
				<app-button
					class="option-btn margin-top"
					type="submit"
					label="Save"
					[showLoadingSpinner]="savingNewPassword"
				></app-button>
			</section>
		</form>
	</section>
</section>
