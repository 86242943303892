import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolboxAction, ToolboxState } from '../../../../core/models/deluxedraw';

@Component({
	selector: 'app-toolbox-button',
	standalone: true,
	imports: [NgIf, NgClass],
	templateUrl: './toolbox-button.component.html',
	styleUrl: './toolbox-button.component.css',
})
export class ToolboxButtonComponent {
	showTooltip: boolean = false;
	timeout: ReturnType<typeof setTimeout> | undefined;

	@Input() iconClass: string = '';
	@Input() tooltip: string = '';
	@Input() Action: ToolboxAction = ToolboxAction.Edit;
	@Input() ToolbarState: ToolboxState = ToolboxState.Edit;

	@Output() clickEvent = new EventEmitter<{
		action: ToolboxAction;
		state: ToolboxState;
	}>();

	handleMouseEnter(): void {
		this.timeout = setTimeout(() => {
			this.showTooltip = true;
		}, 1000);
	}

	handleMouseLeave(): void {
		clearTimeout(this.timeout);
		this.showTooltip = false;
	}

	onClick(): void {
		this.clickEvent.emit({
			action: this.Action,
			state: this.ToolbarState
		});
	}
}
