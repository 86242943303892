import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContextView } from '../models/contexts/context-view';
import { Context } from '../models/deluxedraw';
import { environment } from '../../../environments/environment';
import { ContextNode } from '../models/context-node/context-node';
import { CreateNode } from '../models/deluxedraw/create-node';

@Injectable({
	providedIn: 'root',
})
export class ContextService {
	private baseUrl: string = `${environment.apiUrl}context/api`;
	private contexts = new BehaviorSubject<ContextView[]>([]);
	constructor(private httpClient: HttpClient) {}

	get currentContexts$(): Observable<ContextView[]> {
		return this.contexts.asObservable();
	}

	getAll(): Observable<ContextView[]> {
		return this.httpClient.get<ContextView[]>(`${this.baseUrl}/contexts`);
	}

	get(id: number): Observable<Context> {
		return this.httpClient.get<Context>(`${this.baseUrl}/contexts/${id}`);
	}

	create(name: string, description: string): Observable<ContextView> {
		return this.httpClient.post<ContextView>(`${this.baseUrl}/contexts`, { name, description });
	}

	delete(id: number): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/contexts/${id}`);
	}

	update(id: number, name: string, description: string): Observable<ContextView> {
		return this.httpClient.put<ContextView>(`${this.baseUrl}/contexts`, { id, name, description });
	}

	createAsNode(createNode: CreateNode): Observable<ContextNode> {
		return this.httpClient.post<ContextNode>(`${this.baseUrl}/contexts/node`, createNode);
	}

	deleteAsNode(nodeId: number, contextId: number): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/contexts/${contextId}/nodes/${nodeId}`);
	}

	updateContextList(newContexts: ContextView[]): void {
		this.contexts.next(newContexts);
	}

	setContextId(contextId: number): void {
		localStorage.setItem('contextId', contextId.toString());
	}
}
