import { Component } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'app-cookies',
	standalone: true,
	imports: [ModalComponent, FormsModule],
	templateUrl: './cookies.component.html',
	styleUrls: ['./cookies.component.css', '../../../../form-styles.css'],
})
export class CookiesComponent {
	isExpanded: boolean = false;

	toggleCookieInfo(): void {
		this.isExpanded = !this.isExpanded;
	}

	acceptAll(): void {
		localStorage.setItem('cookiePreferences', JSON.stringify({ accepted: true }));
	}
}
