import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { EmailService } from '../../core/services/email.service';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { ProcessStatus } from '../../core/models/users';
import { ParamMapString } from '../../core/types/param-map-string';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-verify-email',
	standalone: true,
	imports: [RouterModule, ButtonComponent],
	templateUrl: './verify-email.component.html',
	styleUrls: ['./verify-email.component.css', '../../../form-styles.css'],
})
export class VerifyEmailComponent implements OnInit {
	emailVerificationResult: ProcessStatus = ProcessStatus.Processing;
	emailVerificationResults = ProcessStatus;
	email: ParamMapString = '';
	resendingEmail: boolean = false;
	resendSuccess: boolean = false;
	hasTriedResending: boolean = false;

	constructor(
		private emailService: EmailService,
		private toastrService: ToastrService,
		private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		const token = this.route.snapshot.queryParamMap.get('token');
		this.email = this.route.snapshot.queryParamMap.get('email');

		if (token && this.email) {
			this.emailService.verifyEmail(token, this.email).subscribe({
				next: () => {
					this.emailVerificationResult = ProcessStatus.Success;
				},
				error: () => {
					this.emailVerificationResult = ProcessStatus.Failure;
				},
			});
		}
	}

	onResend(): void {
		if (this.email) {
			this.hasTriedResending = true;
			this.resendingEmail = true;
			this.emailService.resendVerificationEmail(this.email).subscribe({
				next: () => {
					this.resendingEmail = false;
					this.resendSuccess = true;
				},
				error: () => {
					this.resendingEmail = false;
					this.resendSuccess = false;
					this.toastrService.showError({});
				},
			});
		}
	}
}
