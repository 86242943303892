<div class="resizeEl resizeLeft user-select" draggable="true" (drag)="onDrag($event, Side.Left)">
	<div class="toggleSideBar toggle" (click)="onSideBarToggleClick()"></div>
</div>
@if (isContextSelected) {
	<app-toolbox
		(selectedToolboxAction)="onSelectedToolboxAction($event)"
		[contextId]="contextId"
	></app-toolbox>
}
<div [ngClass]="isLoadingContext ? 'loading-spinner flex-col' : 'hide'">
	<app-loading-spinner [isLarge]="true"></app-loading-spinner>
</div>
<div id="dd-container" [ngClass]="isLoadingContext ? 'hide' : 'height-full'"></div>
@if (isContextSelected) {
	<div class="resizeEl resizeRight" draggable="true" (drag)="onDrag($event, Side.Right)">
		<div class="toggleContentView toggle" (click)="onContentViewToggleClick()"></div>
	</div>
}
