<section class="page-container flex-row">
	<article class="box flex-col" [routerLink]="'/settings/overview'">
		<h3>{{ user.firstName + ' ' + user.lastName }}</h3>
		<img src="../../../../assets/icon/eye.svg" alt="overview" />
		<p>{{ user.email }}</p>
	</article>
	<article class="box flex-col" [routerLink]="'/settings/user'">
		<h3>User Settings</h3>
		<img src="../../../../assets/icon/person-gear.svg" alt="user settings" />
		<p>Change address, name or phone number</p>
	</article>
	<article class="box flex-col" [routerLink]="'/settings/password'">
		<h3>Password</h3>
		<img src="../../../../assets/icon/key.svg" alt="password" />
		<p>Make your password stronger or change it if someone else knows your password</p>
	</article>
	<article class="box flex-col" [routerLink]="'/settings/payment'">
		<h3>Payment</h3>
		<img src="../../../../assets/icon/credit-card.svg" alt="payment" />
		<p>Become pro to access full version of Boswell</p>
	</article>
</section>
