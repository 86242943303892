<div class="toastr-container flex-col top-right">
	@for (toast of toastrMessages; track toast) {
		<div class="toastr shadow position-relative">
			<div class="toastr-header flex-row gap-small">
				<span class="icon">
					<i class="bi bi-x-circle-fill"></i>
				</span>
				<strong class="title ellipsis">{{ toast.title }}</strong>
				<button (click)="closeToastr(toast)" class="close-button default-btn keep-scale">
					&times;
				</button>
			</div>
			<div class="toastr-body">
				<p>{{ toast.message }}</p>
			</div>
			<div class="progress-bar-container">
				<div class="progress-bar" [style.width.%]="toast.progress"></div>
			</div>
		</div>
	}
</div>
