import { CommandName } from "../shared/constants/command-name.js";
import { containerId } from "../shared/constants/id.js";
import { NodeRepository } from "../infrastructure/node-repository.js";
import { CircleNode } from "../core/models/node/circle-node.js";
import { DiamondNode } from "../core/models/node/diamond-node.js";
import { OctagonNode } from "../core/models/node/octagon-node.js";
import { Shape } from "../shared/constants/shapes.js";
import { SquareNode } from "../core/models/node/square-node.js";
import { TriangleNode } from "../core/models/node/triangle-node.js";
import { ApplicationState } from "../shared/constants/application-state.js";
import { BoswellEventName } from "../shared/constants/boswell-events.js";
import { BoxNode } from "../core/models/node/box-node.js";
import { NodeType } from "../shared/constants/node-type.js";

export class CreateNodeCommand
{
	static commandName = CommandName.CreateNodeCommand;

	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.CreateNodeCommand, { detail: detail }))
	}
}

export class CreateNodeHandler
{
	#rootContainer;

    constructor(rootContainer)
    {
		this.#rootContainer = rootContainer;
        this.#rootContainer.addEventListener(CreateNodeCommand.commandName, (e) => {
            this.#createNode(e.detail.shape, e.detail.contextName, e.detail.type);
        });
    }

    #createNode(shape, contextName, nodeType) {
		const position = this.#getScreenCenterPosition();
		const node = this.#getNode(shape, position);
		this.#setUniqueName(contextName, node);
		document.dispatchEvent(new CustomEvent(BoswellEventName.NodeCreated, {
            detail: {
				guid: node.guid,
                name: node.name,
				positionX: Math.round(node.position.x),
				positionY: Math.round(node.position.y),
                shape: node.shape,
				nodeType: nodeType
            },
            bubbles: true
        }));
        NodeRepository.add(node);
	}

	#setUniqueName(contextName, node) {
		const nodes = NodeRepository.getAll();

    	const nextHighestNumber = Math.max(0, ...nodes
			.filter(n => n.name.startsWith(`${contextName}-`))
			.map(n => parseInt(n.name.split("-").pop()) || 0)) + 1;
		
		node.setName(`${contextName}-${nextHighestNumber}`);
	}

	#getScreenCenterPosition() {
		const transform = window.panZoom.getTransform();
		const x = transform.x * -1 / transform.scale + this.#rootContainer.offsetWidth / 2 / transform.scale;
		const y = transform.y * -1 / transform.scale + this.#rootContainer.offsetHeight / 2 / transform.scale;
		return { x: x, y: y };
	}


    #getNode(shape, position) {
		switch (shape) {
			case Shape.Box:
				var node = new BoxNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.Context);
				break;
			case Shape.Square:
				var node = new SquareNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.ManagedObject);
				break;
			case Shape.Circle:
				var node = new CircleNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.ManagedObject);
				break;
			case Shape.Diamond:
				var node = new DiamondNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.ManagedObject);
				break;
			case Shape.Triangle:
				var node = new TriangleNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.ManagedObject);
				break;
			case Shape.Octagon:
				var node = new OctagonNode(position, ApplicationState.Edit, shape, 0, 0, NodeType.ManagedObject);
				break;
		}
		return node;
	}
}