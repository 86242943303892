import { Component } from '@angular/core';
import { LoginResponse, User } from '../../../core/models/users';
import { UserService } from '../../../core/services/user.service';
import { FormsModule, NgForm } from '@angular/forms';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { ToastrService } from '../../../core/services/toastr.service';
import { ModalService } from '../../../core/services/modal.service';
import { Router, RouterLink } from '@angular/router';
import { catchError, of, switchMap, tap } from 'rxjs';

@Component({
	selector: 'app-user-settings',
	standalone: true,
	imports: [InputComponent, ButtonComponent, FormsModule, RouterLink],
	templateUrl: './user-settings.component.html',
	styleUrls: [
		'./user-settings.component.css',
		'../settings-form-styles.component.css',
		'../../../../form-styles.css',
	],
})
export class UserSettingsComponent {
	user: User;
	isEditing: boolean = false;
	isLockedOut: boolean = false;
	showPasswordInput: boolean = false;
	UserBeforeChanges: User | undefined;
	invalidFormMessage = '';
	password: string = '';

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
		private modalService: ModalService,
		private router: Router,
	) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser === undefined) throw new Error(`Couldn't get current user`);
		this.user = currentUser.user;

		if (this.user.addresses.length === 0) {
			this.user.addresses.push({
				city: '',
				street: '',
				country: '',
				postalCode: '',
				phoneNumber: '',
				company: '',
			});
		}
	}

	onRemoveAccount(): void {
		this.showPasswordInput = !this.showPasswordInput;
		this.password = '';
	}

	onEdit(): void {
		this.invalidFormMessage = '';
		this.isEditing = true;
		this.UserBeforeChanges = JSON.parse(JSON.stringify(this.user));
	}

	onCancelEdit(form: NgForm): void {
		form.reset();
		this.user = JSON.parse(JSON.stringify(this.UserBeforeChanges));
		this.isEditing = false;
	}

	onSave(settingsForm: NgForm): void {
		if (settingsForm.valid) {
			this.userService.updateUser(this.user).subscribe({
				next: (updatedUser: User) => {
					this.user = updatedUser;
					this.isEditing = false;
				},
				error: () => {
					this.invalidFormMessage = 'Could not update your information. Try refreshing the page.';
					this.toastrService.showError({});
				},
			});
		}
	}

	verifyPassword(): void {
		this.userService
			.login(this.user.email, this.password)
			.pipe(
				switchMap((user: LoginResponse) => {
					if (user.isAuthorized) {
						this.openRemoveAccountModal();
						return of(undefined);
					} else if (user.isLockedOut) {
						this.isLockedOut = user.isLockedOut;
						return this.modalService.open({
							title: 'Account Locked',
							content:
								'Your account is temporarily locked due to multiple incorrect password attempts. Please reset your password or contact us for assistance.',
							buttons: [
								{
									label: 'Ok',
									action: (): void => {},
								},
							],
						});
					} else {
						return this.modalService.open({
							title: 'Account could not be deleted',
							content: 'The password is incorrect, please try again.',
							buttons: [
								{
									label: 'Ok',
									action: (): void => {},
								},
							],
						});
					}
				}),
				switchMap(() => {
					if (this.isLockedOut) {
						return of(undefined).pipe(
							tap(() => {
								localStorage.clear();
								void this.router.navigate(['/']);
							}),
						);
					}
					return of(undefined);
				}),
				catchError(() => {
					this.toastrService.showError({});
					return of(undefined);
				}),
			)
			.subscribe();
	}

	openRemoveAccountModal(): void {
		this.modalService
			.open({
				title: 'Delete Account',
				content: 'Are you sure you want to delete your account?',
				buttons: [
					{
						label: 'Delete',
						action: (): void => {
							this.removeAccount();
						},
					},
					{
						label: 'Cancel',
						action: (): void => {},
					},
				],
			})
			.subscribe();
	}

	removeAccount(): void {
		this.userService
			.removeAccount()
			.pipe(
				switchMap(() =>
					this.modalService.open({
						title: 'Account Deleted',
						content: 'Your account has been successfully deleted.',
						buttons: [
							{
								label: 'Ok',
								action: (): void => {},
							},
						],
					}),
				),
				tap(() => {
					localStorage.clear();
					void this.router.navigate(['/']);
				}),
				catchError(() => {
					this.toastrService.showError({});
					return of(undefined);
				}),
			)
			.subscribe();
	}
}
