<main class="form-main-wrapper">
	<section class="form-container flex-col">
		<section class="form-heading-container flex-col">
			<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
			<h1 class="form-headings">Login to Boswell</h1>
		</section>
		<form (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm" class="login-form-container flex-col">
			<app-input
				labelText="Email adress"
				type="email"
				[(ngModel)]="email"
				name="email"
				placeholder="name@example.com"
				[maxLength]="255"
				required
				email
			></app-input>
			<div *ngIf="loginForm.submitted && loginForm.controls['email']?.invalid" class="error-msg">
				Please enter a valid email address.
			</div>
			<app-input
				labelText="Password"
				type="password"
				[(ngModel)]="password"
				name="password"
				placeholder="Password"
				[maxLength]="64"
				required
			></app-input>
			<div *ngIf="loginForm.submitted && loginForm.controls['password']?.invalid" class="error-msg">
				Password is required.
			</div>
			<div *ngIf="invalidFormMessage" class="error-msg">
				{{ invalidFormMessage }}
			</div>
			<div *ngIf="isLockedOut" class="error-msg">
				<p>You have been locked out, try again later</p>
			</div>
			<p class="align-self-end forgot-password font-smaller" routerLink="/login/forgot-password">
				Forgot password?
			</p>
			<app-button
				class="margin-top shadow"
				label="Login"
				type="submit"
				[showLoadingSpinner]="loggingIn"
			></app-button>
		</form>
		<section>
			<p class="register">
				Not registered?
				<a class="create-account login-link" routerLink="/register">Create account here</a>
			</p>
			<p class="register">
				Problem with login?
				<a class="create-account login-link" routerLink="/contact-form">Contact us here</a>
			</p>
		</section>
	</section>
</main>
