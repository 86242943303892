import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SideBarStateService {
	showSideBar$ = new BehaviorSubject<boolean>(true);
	showContentView$ = new BehaviorSubject<boolean>(true);
	showSideBar: boolean = true;
	showContentView: boolean = true;

	minWidthSidebar: number = 175;
	minWidthContentView: number = 380;

	toggleSideBar(visible?: boolean): void {
		this.showSideBar = visible !== undefined ? visible : !this.showSideBar;
		this.showSideBar$.next(this.showSideBar);
	}

	toggleContentView(visible?: boolean): void {
		this.showContentView = visible !== undefined ? visible : !this.showContentView;
		this.showContentView$.next(this.showContentView);
	}
}
