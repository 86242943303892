import { Location } from "./shared/constants/location.js";

export function distance(x1, y1, x2, y2) {
	const dx = x2-x1;
	const dy = y2-y1;
	return Math.sqrt(dx * dx + dy * dy);
}

export function parentNodesHorizontalOverlap(startAnchor, endAnchor){
	return startAnchor.x + startAnchor.parentWidth / 2 > endAnchor.x - endAnchor.parentWidth / 2
	&& startAnchor.x - startAnchor.parentWidth / 2 < endAnchor.x + endAnchor.parentWidth / 2;
}

export function parentNodesVerticalOverlap(startAnchor, endAnchor){
	return startAnchor.y + startAnchor.parentHeight / 2 > endAnchor.y - endAnchor.parentHeight / 2
	&& startAnchor.y - startAnchor.parentHeight / 2 < endAnchor.y + endAnchor.parentHeight / 2;
}

export function pointWithin(p1, p2, p3, p4){
	return p1 > p2 && p3 < p4;
}

function sameSideHorizontal(pointMatrix){
	switch (`${pointMatrix.startAnchor.location}|${pointMatrix.endAnchor.location}`){
		case `${Location.Bottom}|${Location.Left}`:
			return (pointMatrix.start.y <= pointMatrix.end.y && pointMatrix.start.x + pointMatrix.lineMargin <= pointMatrix.end.x)
			|| (pointMatrix.start.y >= pointMatrix.end.y && pointMatrix.start.x + pointMatrix.startAnchor.nodeSize.width / 2 <= pointMatrix.end.x)
		case `${Location.Bottom}|${Location.Right}`:
			return (pointMatrix.start.y <= pointMatrix.end.y && pointMatrix.start.x - pointMatrix.lineMargin >= pointMatrix.end.x)
			|| ((pointMatrix.start.y >= pointMatrix.end.y && pointMatrix.start.x - pointMatrix.startAnchor.nodeSize.width / 2 >= pointMatrix.end.x))
		case `${Location.Top}|${Location.Right}`:
			return (pointMatrix.start.y >= pointMatrix.end.y && pointMatrix.end.x + pointMatrix.lineMargin <= pointMatrix.start.x)
			|| (pointMatrix.start.y <= pointMatrix.end.y && pointMatrix.start.x - pointMatrix.startAnchor.nodeSize.width / 2 >= pointMatrix.end.x);
		case `${Location.Top}|${Location.Left}`:
			return (pointMatrix.start.y <= pointMatrix.end.y && pointMatrix.start.x + pointMatrix.startAnchor.nodeSize.width / 2 <= pointMatrix.end.x)
			|| (pointMatrix.start.y >= pointMatrix.end.y && pointMatrix.start.x + pointMatrix.lineMargin <= pointMatrix.end.x);
	}
}
	
function sameSideVertical(pointMatrix){
	switch (`${pointMatrix.startAnchor.location}|${pointMatrix.endAnchor.location}`){
		case `${Location.Bottom}|${Location.Left}`:
			return (pointMatrix.start.x <= pointMatrix.end.x && pointMatrix.start.y + pointMatrix.lineMargin <= pointMatrix.end.y)
			|| (pointMatrix.start.x >= pointMatrix.end.x && pointMatrix.end.y - pointMatrix.startAnchor.nodeSize.height / 2 >= pointMatrix.start.y)
		case `${Location.Bottom}|${Location.Right}`:
			return (pointMatrix.start.x <= pointMatrix.end.x && pointMatrix.end.y - pointMatrix.startAnchor.nodeSize.height / 2 >= pointMatrix.start.y)
			|| ((pointMatrix.start.x >= pointMatrix.end.x && pointMatrix.start.y + pointMatrix.lineMargin <= pointMatrix.end.y))
		case `${Location.Top}|${Location.Right}`:
			return (pointMatrix.start.x >= pointMatrix.end.x && pointMatrix.start.y - pointMatrix.lineMargin >= pointMatrix.end.y)
			|| (pointMatrix.start.x <= pointMatrix.end.x && pointMatrix.end.y + pointMatrix.endAnchor.nodeSize.height / 2 <= pointMatrix.start.y);
		case `${Location.Top}|${Location.Left}`:
			return (pointMatrix.start.x <= pointMatrix.end.x && pointMatrix.start.y - pointMatrix.lineMargin >= pointMatrix.end.y)
			|| (pointMatrix.start.x >= pointMatrix.end.x && pointMatrix.end.y + pointMatrix.endAnchor.nodeSize.height / 2 <= pointMatrix.start.y);
	}
}
export function sameHorizontalAndVertical(pointMatrix){
	return sameSideHorizontal(pointMatrix) && sameSideVertical(pointMatrix);
}

export function sameSideHorizontalOnly(pointMatrix){
	return sameSideHorizontal(pointMatrix) && !sameSideVertical(pointMatrix);
}

export function sameSideVerticalOnly(pointMatrix){
	return !sameSideHorizontal(pointMatrix) && sameSideVertical(pointMatrix);
}

export function oppositeHorizontalAndVertical(pointMatrix){
	return !sameSideHorizontal(pointMatrix) && !sameSideVertical(pointMatrix);
}

export function getTransformedMousePoint(container, event){
	const matrix = container.getScreenCTM();
	return {
		x: (event.clientX - matrix.e) / matrix.a,
		y: (event.clientY - matrix.f) / matrix.d
	};
}

export function calculateSnap(pos, snap) {
	let dx = pos.x % snap;
	let dy = pos.y % snap;

	if (dx >= snap / 2)
		dx = -(snap - dx);

	if (dy >= snap / 2)
		dy = -(snap - dy);

	return {
		x: dx,
		y: dy
	};
}