import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
	selector: 'app-markdown-editor',
	standalone: true,
	imports: [CommonModule, FormsModule, QuillEditorComponent],
	templateUrl: './markdown-editor.component.html',
	styleUrl: './markdown-editor.component.css',
	encapsulation: ViewEncapsulation.None,
})
export class MarkdownEditorComponent {
	@Input() editorContent: string = '';

	@Output() contentEdited = new EventEmitter<string>();

	modules = {
		toolbar: [
			[{ size: ['small', false, 'large', 'huge'] }],
			['bold', 'italic', 'underline'],
			[{ color: [] }, { background: [] }],
			[{ align: [] }],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['image'],
			['code-block'],
			['clean'],
		],
	};

	emitMarkdownContent(): void {
		this.contentEdited.emit(this.editorContent);
	}
}
