import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ToolboxButtonComponent } from './toolbox-button/toolbox-button.component';
import { ToolboxState, ToolboxAction } from '../../../core/models/deluxedraw';

@Component({
	selector: 'app-toolbox',
	standalone: true,
	imports: [ToolboxButtonComponent],
	templateUrl: './toolbox.component.html',
	styleUrl: './toolbox.component.css',
})
export class ToolboxComponent implements OnChanges {
	@Input() contextId: number | null = null;

	@Output() selectedToolboxAction = new EventEmitter<{
		action: ToolboxAction;
	}>();

	ToolboxAction = ToolboxAction;
	ToolboxState = ToolboxState;
	toolboxState: ToolboxState = ToolboxState.Edit;
	selectedRelation: ToolboxAction = ToolboxAction.Connect;

	readonly #relationActions: ToolboxAction[] = [ToolboxAction.Connect, ToolboxAction.ConnectBoth];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contextId'] && this.contextId) {
			this.onToolboxButtonClick({ action: ToolboxAction.Edit, state: ToolboxState.Edit });
		}
	}

	onToolboxButtonClick(
		event: {
			action: ToolboxAction;
			state: ToolboxState;
		}
	): void {
		this.selectedRelation = this.#relationActions.includes(event.action)
			? event.action
			: this.selectedRelation

		this.toolboxState = event.state;

		this.selectedToolboxAction.emit({
			action: event.action,
		});
	}
}
