<section [ngClass]="[extraClass, 'btn-container']" (click)="onClick()">
	<i
		[ngClass]="[iconClass, 'font-bigger', 'toolbox-icon']"
		(mouseenter)="handleMouseEnter()"
		(mouseleave)="handleMouseLeave()"
	></i>
	@if (showTooltip) {
		<p class="tooltip font-smaller capitalize">{{ tooltip }}</p>
	}
</section>
