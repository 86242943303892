<aside class="sidebar-container flex-col user-select width-full">
	<h2 class="heading">Drawings</h2>
	<section class="width-full">
		<ul class="flex-col">
			@if (loadingContexts) {
				<app-loading-spinner></app-loading-spinner>
			}
			@for (context of contexts; track context) {
				<app-context-item
					[context]="context"
					[currentContext]="currentContext"
					(toggleExpansion)="onToggleExpansion(context)"
					(deleteDrawing)="onDeleteDrawing(context)"
					(renameDrawing)="onRenameDrawing(context)"
				></app-context-item>
			}
			@if (creatingNewDrawing) {
				<app-loading-spinner></app-loading-spinner>
			}
		</ul>
	</section>
</aside>
<section class="btn-container">
	@if (!addDrawingFormVisible) {
		<app-button extraClass="create-btn" label="+ New Drawing" (buttonClick)="showAddDrawingForm()">
		</app-button>
	} @else {
		<section class="gap-small flex-col">
			<app-input
				placeholder="Name"
				[(ngModel)]="newDrawingName"
				(enterPressed)="addNewDrawing()"
			></app-input>
			<app-button
				extraClass="create-btn"
				[label]="newDrawingName.trim().length > 0 ? 'Add' : 'Cancel'"
				(buttonClick)="newDrawingName.trim().length > 0 ? addNewDrawing() : cancelNewDrawing()"
				[showLoadingSpinner]="creatingNewDrawing"
			></app-button>
		</section>
	}
</section>
