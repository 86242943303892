@if (context.sharedContextId > 0) {
	<button class="default-btn shared-button" (click)="toggleSharing()">
		<i class="bi bi-share"></i>
	</button>

	@if (isSharingVisible) {
		<app-modal [title]="'You have shared this drawing with:'" (closeEmitter)="onModalClose()">
			@if (!isLoading) {
				<ul class="user-list-container flex-col">
					@if (users.length > 0) {
						@for (user of users; track user) {
							<li class="user-list-item flex-row">
								<b class="font-smaller">{{ user.firstName }} {{ user.lastName }}</b>
								<div class="font-smaller">{{ user.email }}</div>
								<button class="default-btn stop-share-btn">
									<i class="bi bi-x-circle"></i>
								</button>
							</li>
						}
					} @else {
						<li class="flex-row">
							<div class="font-smaller">No one has accepted your invitation yet.</div>
						</li>
					}
				</ul>
			} @else {
				<app-loading-spinner></app-loading-spinner>
			}
		</app-modal>
	}
}
