import { getTransformedMousePoint } from "../../../deluxe-math.js";
import { namespace } from "../../../shared/constants/svg.js";
import { CustomEventName } from "../../../shared/constants/custom-events.js";
import { containerId, svgContainerId } from "../../../shared/constants/id.js";
import { calculateSnap } from "../../../deluxe-math.js";
import { gridSize } from "../../../shared/constants/grid.js";
import { ApplicationState } from "../../../shared/constants/application-state.js";
import { BoswellEventName } from "../../../shared/constants/boswell-events.js";

export class Node {
	container;
	anchors;
	svg;
	isDragging;
	mouseStart;
	svgContainer;
	mouseMoveListener;
	width = 120;
	height = 120;
	strokeWidth = 1;
	statusIndicatorWidth = 15;
	applicationState;
	id;
	referenceId;
	guid
	name;
	shape;
	position;
	type
	#rootContainer;
	#dragThreshold = 8;
	#mouseStartPosition;
	padding = 5;

	constructor(applicationState, shape, position, referenceId, id, type){
		this.#rootContainer = document.getElementById(containerId);
		this.container = document.getElementById(svgContainerId);
		this.isDragging = false;
		this.guid = crypto.randomUUID();
		this.applicationState = applicationState;
		this.shape = shape;
		this.position = position;
		this.referenceId = referenceId;
		this.id = id;
		this.type = type;
	}

	setName(name) {
		this.name = name;
		this.#updateTextElement();
	}

	updateApplicationState(applicationState) {
		this.applicationState = applicationState;
		this.anchors.forEach(anchor => anchor.applicationState = applicationState); 
	}

	setReferenceId(referenceId) {
		this.referenceId = referenceId;
	}

	setNodeId(id) {
		this.id = id;
		this.anchors.forEach(anchor => {
			anchor.nodeId = id;
		});	
	}

	getConnectedRelations() {
		const anchorsWithRelations = this.anchors.filter(anchor => anchor.connectedRelations.length > 0);
		let connectedRelations = [];
		anchorsWithRelations.forEach(anchor => {
			connectedRelations = connectedRelations.concat(anchor.connectedRelations.filter(relation => !connectedRelations.includes(relation)));
		})
		return connectedRelations;
	}

	registerListeners() {
		if (!this.svg)
			throw new Error(`Can't register event listeners before creating SVG`);

		this.svg.addEventListener("pointerdown", (event) => {
			if (!this.isDragging) {
				this.#startDrag(event);
			}
		});
		
		this.mouseMoveListener = (e) => {
			const mouseMove = getTransformedMousePoint(this.container, e);
			if (this.applicationState === ApplicationState.Edit) {
				window.panZoom.pause();
				if (this.ShouldStartDrag(mouseMove)) {
					this.isDragging = true;
					this.drag(e);
				}
			}
			else{
				if (this.ShouldStartDrag(mouseMove))
					this.isDragging = true;
			}
		}
		
		this.svg.addEventListener("pointerup", async () => {
			this.#rootContainer.removeEventListener("pointermove", this.mouseMoveListener);
			switch (this.applicationState) {
				case ApplicationState.Navigate:
					if (this.isDragging) {
						this.#endDrag();
						break;
					} else {
						this.#rootContainer.dispatchEvent(new CustomEvent(CustomEventName.NodeClicked, {detail: this}));
					}
					break;
				case ApplicationState.Delete:
					if (this.isDragging) {
						this.#endDrag();
						break;
					}
					this.#rootContainer.dispatchEvent(new CustomEvent(CustomEventName.TryDeleteNode, { detail: this }));
					break;
				case ApplicationState.Edit:
					if (this.isDragging) {
						this.#endDrag();
					} else {
						this.#rootContainer.dispatchEvent(new CustomEvent(CustomEventName.NodeClicked, {detail: this}));
					}
					break;
			}
		});

		this.svg.addEventListener("pointerenter", () => {
			this.highlight();
			this.getConnectedRelations().forEach(relation => relation.highlight())
		});
		
		this.svg.addEventListener("pointerleave", () => {
			this.removeHighlight();
			this.getConnectedRelations().forEach(relation => relation.removeHighlight());
		});
	}

	createTextElement(){
		const text = document.createElementNS(namespace, "text");
		text.style.fontSize = "14px";
		text.style.pointerEvents = "none";
		text.setAttribute("fill", "#000");
		text.setAttribute("x", this.width / 2);
		text.setAttribute("y", this.height / 2);
		text.setAttribute("dx", this.padding);
		text.setAttribute("text-anchor", "middle");
		text.setAttribute("dominant-baseline", "middle");
		text.setAttribute("class", "dd-node-text");

		this.svgContainer.appendChild(text);

		setTimeout(() => {
			this.setEllipsisOnNodeText(text);
		}, 0)
	}

	setEllipsisOnNodeText(textElem) {
		let textWidth = textElem.getComputedTextLength();
		const maxWidth = this.width - this.padding * 2;

		if (textWidth > maxWidth) {
			let truncatedText = textElem.textContent;
			while (textWidth > maxWidth && truncatedText.length > 0) {
				truncatedText = truncatedText.slice(0, -1);
				textElem.textContent = truncatedText + '...';
				textWidth = textElem.getComputedTextLength();
			}
		}
		textElem.setAttribute("x", maxWidth / 2);
	}

	getSvgPosition(){
		const x = Number.parseInt(this.svgContainer.getAttribute("x"));
		const y = Number.parseInt(this.svgContainer.getAttribute("y"));
		return {
			x, y
		};
	}
	
	setActive(){
		this.anchors.forEach(anchor => {
			anchor.show();
		});
	}

	setInactive(){
		this.anchors.forEach(anchor => {
			anchor.hide();
		});
	}

	removeFromSvg(){
		this.container.removeChild(this.svgContainer);
	}

	highlight() {
		this.svg.setAttribute("class", `dd-node-active ${this.applicationState === "edit" ? "dd-node-edit" : ""}`);		
	}

	removeHighlight() {
		this.svg.removeAttribute("class", `dd-node-active ${this.applicationState === "edit" ? "dd-node-edit" : ""}`);
	}

	focus() {
		window.panZoom.zoomAbs(0, 0, 1);
		const { x, y } = this.getSvgPosition();
		window.panZoom.smoothMoveTo(x * -1 + this.#rootContainer.offsetWidth / 2, y * -1 + this.#rootContainer.offsetHeight / 2);
		this.highlight();
	}

	focusOut() {
		this.removeHighlight();
	}

	setMouseStartPosition(event){
		this.mouseStart = getTransformedMousePoint(this.container, event);
		this.#mouseStartPosition = getTransformedMousePoint(this.container, event);
		const pos = this.getSvgPosition();
		this.mouseStart.x -= pos.x;
		this.mouseStart.y -= pos.y;
	}
	
	drag(event){
		var coord = getTransformedMousePoint(this.container, event);
		this.svgContainer.setAttribute("x", coord.x - this.mouseStart.x);
		this.svgContainer.setAttribute("y", coord.y - this.mouseStart.y);
		this.#updateConnectedRelations();
	}

	clear() {
		this.getConnectedRelations().forEach(relation => relation.clear());
		this.container.removeChild(this.svgContainer);
	}

	ShouldStartDrag(mouseMove) {
		return (
			Math.abs(mouseMove.x - this.#mouseStartPosition.x) > this.#dragThreshold || 
			Math.abs(mouseMove.y - this.#mouseStartPosition.y) > this.#dragThreshold
		);
	}

	#updateConnectedRelations(){
		this.getConnectedRelations().forEach(relation => {
			relation.update();
		});
	}

	#updateTextElement(){
		const textElement = this.svgContainer.getElementsByClassName("dd-node-text")[0];
		textElement.textContent = this.name;
		this.setEllipsisOnNodeText(textElement);
	}

	#startDrag(event){
		this.#rootContainer.addEventListener("pointermove", this.mouseMoveListener);
		this.setMouseStartPosition(event);
	}

	#endDrag(){
		window.panZoom.resume();
		this.isDragging = false;
		const pos = this.getSvgPosition();
		const snapAdjustment = calculateSnap(pos, gridSize);
		this.svgContainer.setAttribute("x", pos.x - snapAdjustment.x);
		this.svgContainer.setAttribute("y", pos.y - snapAdjustment.y);
		this.#updateConnectedRelations();

		const detail = {
			positionX: pos.x - snapAdjustment.x,
			positionY: pos.y - snapAdjustment.y,
			id: this.id,
			shape: this.shape
		};

		this.#rootContainer.dispatchEvent(new CustomEvent(BoswellEventName.NodeDragged, {
			detail: detail,
			bubbles: true
		}));
	}
}
