import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { switchMap, take } from 'rxjs';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
	const userService = inject(UserService);

	const nonAuthUrls = [
		'/login',
		'/register',
		'/renew-token',
		'/forgot-password',
		'/reset-password',
		'/validate-reset-password',
		'/resend-verification',
		'/contact-form',
	];
	const requiresAuth = !nonAuthUrls.some((url) => req.url.includes(url));

	if (!requiresAuth) {
		return next(req);
	}

	return userService.getValidToken().pipe(
		take(1),
		switchMap((token) => {
			if (token) {
				req = req.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
			}
			return next(req);
		}),
	);
};
