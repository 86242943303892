<aside class="drop-down-container user-select width-full height-full">
	<section class="flex-row user-name-container width-full gap-small">
		<div class="initials-container">
			<p class="font-bigger">{{ userNameInitial }}</p>
		</div>
		<section>
			<p class="font-bigger">{{ userName }}</p>
			<p class="font-smaller">{{ userEmail }}</p>
		</section>
	</section>
	<ul>
		<li>
			<a routerLink="/settings" class="default-btn">Settings</a>
		</li>
	</ul>
	<a class="log-out-link font-smaller" (click)="logOut()" routerLink="/login">Log Out</a>
</aside>
