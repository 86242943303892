@if (metaData) {
	<a [href]="url" target="_blank">
		<div class="meta-content">
			<img
				class="meta-icon"
				[src]="metaData.iconUrl"
				alt="Link Preview Image"
				*ngIf="metaData.iconUrl"
			/>
			<div class="flex-col">
				<span class="font-smaller">
					<b>{{ metaData.title }}</b>
				</span>
				<p class="meta-description font-smaller">
					{{ metaData.description }}
				</p>
			</div>
		</div>
	</a>
}
