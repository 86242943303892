<main class="form-main-wrapper">
	<section class="form-container flex-col">
		<section class="form-heading-container flex-col">
			<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
			<h1 class="form-headings">Register</h1>
		</section>
		@switch (registerResult) {
			@case (registerResults.Success) {
				<h1 class="form-headings">Successfully registered!</h1>
				<section>
					<p>
						An email has been sent to <span class="highlight-text">{{ user.email }}</span>
					</p>
				</section>
				<p>Didn't receive the email?</p>
				<app-button label="Resend email" class="shadow" (buttonClick)="resendEmail()"></app-button>
				@if (hasResentEmail) {
					<p>
						New email verification sent to
						<span class="highlight-text">{{ user.email }}</span
						>. Please check your inbox.
					</p>
				}

				<p class="login-option">Back to <a class="login-link" href="/login">login</a></p>
			}
			@case (registerResults.Failure) {
				<h1 class="form-headings">Could not register account</h1>
				<app-button
					(buttonClick)="backToRegister()"
					label="Back to register"
					class="shadow"
				></app-button>
				<p class="login-option">Back to <a class="login-link" href="/login">login</a></p>
			}
			@default {
				<form
					class="login-form-container flex-col"
					#registerForm="ngForm"
					(ngSubmit)="handleFormSubmit(registerForm)"
				>
					<app-input
						labelText="First name*"
						name="firstName"
						type="text"
						placeholder="First Name"
						[(ngModel)]="user.firstName"
						required
					></app-input>
					@if (registerForm.submitted && registerForm.controls['firstName'].invalid) {
						<div class="error-msg">Please enter your first name</div>
					}

					<app-input
						labelText="Last name*"
						name="lastName"
						type="text"
						placeholder="Last Name"
						[(ngModel)]="user.lastName"
						required
					></app-input>
					@if (registerForm.submitted && registerForm.controls['lastName'].invalid) {
						<div class="error-msg">Please enter your last name</div>
					}

					<app-input
						labelText="Email adress*"
						name="email"
						type="email"
						placeholder="name@example.com"
						[(ngModel)]="user.email"
						[maxLength]="255"
						email
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
						[toLowerCase]="true"
						required
					></app-input>
					@if (registerForm.submitted && registerForm.controls['email'].invalid) {
						<div class="error-msg">Please enter a valid email address</div>
					}

					<app-input
						labelText="Password*"
						name="password"
						type="password"
						placeholder="Password"
						minlength="8"
						[(ngModel)]="user.password"
						[maxLength]="64"
						required
					></app-input>
					@if (registerForm.submitted && registerForm.controls['password'].invalid) {
						<div class="error-msg">Password must contain at least 8 characters</div>
					}

					<app-input
						labelText="Confirm password*"
						name="confirmPassword"
						type="password"
						placeholder="Confirm password"
						minlength="8"
						[(ngModel)]="user.confirmPassword"
						[maxLength]="64"
						required
					></app-input>
					@if (registerForm.submitted && registerForm.controls['confirmPassword'].invalid) {
						<div class="error-msg">Please confirm your password</div>
					}
					@if (
						registerForm && registerForm.controls['password'] && registerForm.controls['confirmPassword'] &&
              			registerForm.controls['password'].value !== registerForm.controls['confirmPassword'].value
					) {
						<div class="error-msg">Passwords doesn't match</div>
					}
					@if (invalidFormMessage) {
						<div class="error-msg">
							{{ invalidFormMessage }}
						</div>
					}

					<div class="checkbox-container">
						<input
							type="checkbox"
							id="terms"
							[(ngModel)]="user.acceptedTerms"
							[name]="'terms'"
							required
						/>
						<label for="terms">
							To use our service, you must accept our GDPR policy. Read more about how we handle
							your information.
						</label>
					</div>
					@if (isExpanded) {
						<p>
							We store user information to enable the use of the application, keep the user logged
							in, and link to the latest drawing the user was working on. All information is
							processed in accordance with our privacy policy to provide a secure user experience.
						</p>
					}

					@if (registerForm.submitted && !user.acceptedTerms) {
						<div class="error-msg">You must accept the GDPR policy to continue.</div>
					}
					<a class="cookie-button login-link" (click)="togglePrivacyNotice()">
						{{ isExpanded ? 'Less text' : 'Read more about our GDPR policy' }}
					</a>
					<app-button
						class="margin-top"
						label="Register"
						type="submit"
						[showLoadingSpinner]="registering"
						[disabled]="!user.acceptedTerms"
					></app-button>
					<p class="login-option margin-top">
						Already a user? <a class="login-link" href="/login">Login instead</a>
					</p>
				</form>
			}
		}
	</section>
</main>
