import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	ManagedObjectAttribute,
	ManagedObjectAttributeValue,
} from '../../../../core/models/managed-object';
import { InputComponent } from '../../../../shared/input-field/input/input.component';
import { FormsModule } from '@angular/forms';
import { ConfirmCancelButtonGroupComponent } from '../../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { AttributeService } from '../../../../core/services/attribute.service';
import { ToastrService } from '../../../../core/services/toastr.service';

@Component({
	selector: 'app-file',
	standalone: true,
	imports: [
		InputComponent,
		FormsModule,
		ConfirmCancelButtonGroupComponent,
		InputComponent,
		LoadingSpinnerComponent,
	],
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.css', '../../../../../attribute-value-styles.css'],
})
export class FileComponent implements OnInit {
	isEditing: boolean = false;
	isFileSelected: boolean = false;
	selectedFileName: string = '';

	@Input() managedObjectAttributeValue!: ManagedObjectAttributeValue;
	@Input() managedObjectAttribute!: ManagedObjectAttribute;

	@Output() saveFileChanges = new EventEmitter();

	constructor(
		private attributeService: AttributeService,
		private toastrService: ToastrService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.selectedFileName = this.managedObjectAttributeValue?.value;
	}

	uploadNewFile(event: Event): void {
		const inputElement = event.target as HTMLInputElement;

		if (inputElement.files && inputElement.files[0]) {
			this.isFileSelected = true;
			const file: File = inputElement.files[0];
			this.attributeService
				.uploadFile(file, file.name, this.managedObjectAttribute.folderId.toString())
				.subscribe({
					next: (response) => {
						this.managedObjectAttributeValue.value = response;
						this.managedObjectAttribute.hasAttributeValue = true;
						this.isFileSelected = false;
						this.cdr.markForCheck();
					},
					error: () => {
						this.toastrService.showError({});
					},
				});
		} else {
			this.isFileSelected = false;
		}
	}

	downloadFile(): void {
		const fileName = this.managedObjectAttributeValue.value;

		this.attributeService
			.downloadFile(fileName, this.managedObjectAttribute.folderId.toString())
			.subscribe({
				next: (blob) => {
					this.triggerDownload(blob, fileName);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	private triggerDownload(blob: Blob, fileName: string): void {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}

	onEdit(): void {
		this.isEditing = true;
	}

	onSaveChanges(): void {
		this.saveFileChanges.emit();
		this.isEditing = false;
	}

	onCancelChanges(): void {
		this.isEditing = false;
	}
}
