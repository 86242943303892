<section class="flex-col page-container">
	<section class="heading-section">
		<h1 class="form-headings">Change password</h1>
	</section>
	@if (user.guest) {
		<section>
			<p>Create an account to access this view</p>
		</section>
		<app-button label="Create account" routerLink="/register-guest"></app-button>
	} @else {
		<section class="width-full">
			<form
				#changePasswordForm="ngForm"
				class="login-form-container flex-col"
				(ngSubmit)="onChangePassword(changePasswordForm)"
			>
				<app-input
					labelText="Current password"
					type="password"
					name="currentPassword"
					[(ngModel)]="currentPassword"
				></app-input>
				@if (
					changePasswordForm.submitted && changePasswordForm.controls['currentPassword'].invalid
				) {
					<div class="error-msg">Password must contain at least 8 characters</div>
				}
				<app-input
					labelText="New password"
					type="password"
					name="newPassword"
					[(ngModel)]="newPassword"
				></app-input>
				@if (changePasswordForm.submitted && changePasswordForm.controls['newPassword'].invalid) {
					<div class="error-msg">Please confirm your password</div>
				}

				<app-input
					labelText="Confirm new password"
					type="password"
					name="confirmNewPassword"
					[(ngModel)]="confirmNewPassword"
					(ngModelChange)="onConfirmPasswordInput()"
				></app-input>
				@if (!passwordsMatch && changePasswordForm.controls['confirmNewPassword'].dirty) {
					<div class="error-msg">Passwords doesn't match</div>
				}
				@if (invalidFormMessage) {
					<div class="error-msg">
						{{ invalidFormMessage }}
					</div>
				}
				@if (passwordChangedSuccessfully) {
					<div>
						{{ passwordChangedSuccessfully }}
					</div>
				}
				@if (passwordChangedFailed) {
					<div class="error-msg">
						{{ passwordChangedFailed }}
					</div>
				}
				<section class="flex-row gap-small">
					<app-button
						class="option-btn margin-top"
						label="Cancel"
						(buttonClick)="onCancel()"
					></app-button>
					<app-button
						class="option-btn margin-top"
						type="submit"
						label="Save"
						[showLoadingSpinner]="savingNewPassword"
					></app-button>
				</section>
			</form>
		</section>
	}
</section>
