<aside class="drop-down-container user-select height-full">
	<section class="flex-row user-name-container gap-small">
		@if (isGuestUser) {
			<section>
				<p class="font-weight-medium">Guest</p>
			</section>
		} @else {
			<div class="profile-container">
				<p class="font-weight-medium">{{ userNameInitial }}</p>
			</div>
			<section>
				<p class="font-bigger">{{ userName }}</p>
				<p class="font-smaller">{{ userEmail }}</p>
			</section>
		}
	</section>
	<ul class="remove-list-style">
		<li>
			<a routerLink="/" class="default-btn">Home</a>
		</li>
		@if (isGuestUser) {
			<li>
				<a routerLink="/login" class="default-btn">Login</a>
			</li>
		}
		<li>
			<a [routerLink]="isGuestUser ? '/register-guest' : '/settings'" class="default-btn">{{
				isGuestUser ? 'Register account' : 'Settings'
			}}</a>
		</li>
		<li>
			<a routerLink="/settings/faq" class="default-btn">Contact us</a>
		</li>
	</ul>
	<a
		class="log-out-link font-smaller"
		(click)="isGuestUser ? openRemoveAccountModal() : logOut()"
		>{{ isGuestUser ? 'Remove my data' : 'Log Out' }}</a
	>
</aside>
