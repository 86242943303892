<section class="attribute-value-section flex-col">
	<h6 class="attribute-value-heading flex-item">
		{{ managedObjectAttributeValue.attributeValue.name }}:
	</h6>
	@if (managedObjectAttributeValue.id === 0) {
		<div class="input-container flex-col">
			<app-input
				[small]="true"
				placeholder="Your link here"
				[(model)]="managedObjectAttributeValue.value"
				name="value"
				type="url"
				(modelChange)="onManagedObjectAttributeValueChanged($event)"
				(blurred)="addHttpsIfMissing()"
				[maxLength]="2048"
			></app-input>
			<app-meta-data
				[metaData]="metaData"
				[url]="managedObjectAttributeValue.value"
			></app-meta-data>
		</div>
	} @else if (isEditing) {
		<app-input
			[(ngModel)]="managedObjectAttributeValue.value"
			[model]="managedObjectAttributeValue.value"
			(enterPressed)="onSaveChanges()"
			[maxLength]="2048"
		></app-input>
		<section class="edit-btn-container flex-row">
			<app-confirm-cancel-button-group
				(cancel)="onCancelChanges()"
				(confirm)="onSaveChanges()"
			></app-confirm-cancel-button-group>
		</section>
	} @else {
		<section class="edit-pen-container">
			<a class="item" [href]="managedObjectAttributeValue.value" target="_blank">
				{{ managedObjectAttributeValue.value }} &nbsp;
			</a>
			<img
				src="../../../../../assets/icon/pencil.svg"
				alt="Svg of a pencil"
				class="edit-pen"
				(click)="onEdit()"
			/>
			<app-meta-data
				[metaData]="metaData"
				[url]="managedObjectAttributeValue.value"
			></app-meta-data>
		</section>
	}
</section>
