import { Component, OnInit } from '@angular/core';
import { SharingService } from '../../core/services/sharing.service';
import { ContextService } from '../../core/services/context.service';
import { ToastrService } from '../../core/services/toastr.service';
import { ParamMapString } from '../../core/types/param-map-string';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take } from 'rxjs';
import { ModalComponent } from '../../shared/modal/modal.component';

@Component({
	selector: 'app-share-drawing',
	standalone: true,
	imports: [ModalComponent],
	templateUrl: './share-drawing.component.html',
	styleUrl: './share-drawing.component.css',
})
export class ShareDrawingComponent implements OnInit {
	token: ParamMapString = '';
	canCreate: boolean = true;
	constructor(
		private contextService: ContextService,
		private toastrService: ToastrService,
		private sharingService: SharingService,
		private route: ActivatedRoute,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.contextService
			.getAll()
			.pipe(switchMap(() => this.contextService.canCreateContext$.pipe(take(1))))
			.subscribe({
				next: (canCreate) => {
					if (!canCreate) {
						this.canCreate = canCreate;
						setTimeout(() => {
							void this.router.navigate(['/']);
						}, 4000);
					} else {
						this.handelToken();
					}
				},
			});
	}

	private handelToken(): void {
		this.token = this.route.snapshot.queryParamMap.get('token');
		if (this.token) {
			this.sharingService.setContextAccess(this.token).subscribe({
				next: (contextId) => {
					this.contextService.contextsModified.next();
					void this.router.navigate(['/', contextId]);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
		}
	}
}
