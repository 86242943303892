import { NodeRepository } from "../infrastructure/node-repository.js";
import { ActionMenuSelection } from "../shared/constants/action-menu-selection.js";
import { focusboxId } from "../shared/constants/id.js";

export class FocusboxBehavior
{
    #rootContainer;
    #focusbox;

    constructor(rootContainer){
        this.#rootContainer = rootContainer;
    }

    handle(actionMenuSelection){
        if (actionMenuSelection === ActionMenuSelection.Focusbox && !this.#focusbox) {
            this.#createFocusbox(); 
        }
        else {
            this.#focusbox?.remove();
            this.#focusbox = null;
        } 
    }

    #createFocusbox() {
        this.#focusbox = document.createElement("div");
        this.#focusbox.setAttribute("id", focusboxId);
        this.#rootContainer.appendChild(this.#focusbox);

        const toolboxElement = document.getElementById("toolbox");        
        this.#focusbox.style.maxHeight = toolboxElement.offsetHeight + 'px';

        this.#focusbox.innerHtml = "";
        NodeRepository.getAll().forEach(node => {
            const focusboxContent = document.createElement("div");

			focusboxContent.setAttribute("class", "dd-focusboxItem");
			focusboxContent.innerHTML = node.name;
			focusboxContent.addEventListener("mouseover", () => {
				node.focus();
			});
			focusboxContent.addEventListener("mouseleave", () => {
				node.focusOut();
			});
			focusboxContent.addEventListener("click", () => {
				this.#focusbox.remove();
                this.#focusbox = null;
			});

			this.#focusbox.appendChild(focusboxContent);
        });
    }
}