import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../../core/services/user.service';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-drop-down',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './drop-down.component.html',
	styleUrl: './drop-down.component.css',
})
export class DropDownComponent implements OnInit {
	@Input() userNameInitial: string = '';
	userName: string = '';
	userEmail: string = '';

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.userName = this.userService.getFullName();
		this.userEmail = this.userService.getEmail();
	}

	logOut(): void {
		this.userService.logOut();
	}
}
