<section class="attribute-container padding-sides width-full gap-small border-radius">
	<button
		class="default-btn keep-scale gap-small width-full attribute-preview"
		[style]="managedObjectAttribute.isExpanded ? 'align-items: start' : 'align-items: center'"
		(click)="onToggleExpanded()"
	>
		@if (managedObjectAttribute.id === 0) {
			<app-input
				class="margin-left font-weight-medium flex-grow"
				labelText="Name:"
				[small]="true"
				placeholder="Item name"
				[(model)]="managedObjectAttribute.name"
				name="name"
			></app-input>
		} @else if (managedObjectAttribute.isExpanded && managedObjectAttribute.isEditing) {
			<section class="margin-left flex-col edit-mode-container">
				<app-input
					[(ngModel)]="name"
					[model]="managedObjectAttribute.name"
					(enterPressed)="onSaveRename()"
				></app-input>
				<section class="edit-btn-container">
					<app-confirm-cancel-button-group
						(confirm)="onSaveRename()"
						(cancel)="onCancelRename()"
					></app-confirm-cancel-button-group>
				</section>
			</section>
		} @else {
			<h5
				class="attribute-name flex-item ellipsis"
				[style]="managedObjectAttribute.isExpanded ? 'flex-grow: 10' : ''"
				[ngClass]="managedObjectAttribute.isExpanded ? 'edit-pen-container' : ''"
				(mouseenter)="handleMouseEnter()"
				(mouseleave)="handleMouseLeave()"
			>
				{{ managedObjectAttribute.name }} &nbsp;
				<img
					src="../../../../../assets/icon/pencil.svg"
					alt="Svg of a pencil"
					class="edit-pen"
					(click)="onRename()"
				/>
			</h5>
		}
		@if (showTooltip && !managedObjectAttribute.isExpanded && !managedObjectAttribute.isEditing) {
			<p class="tooltip">
				{{ managedObjectAttribute.name }}
			</p>
		}

		<section class="flex-row flex-item ellipsis">
			@for (
				value of managedObjectAttribute.managedObjectAttributeValues;
				track value;
				let i = $index
			) {
				@if (!managedObjectAttribute.isExpanded && value.attributeValue.preview) {
					@switch (value.attributeValue.type) {
						@case (AttributeType.Markdown) {
							<p
								class="ellipsis font-smaller ellipsis-long-word attribute-type-note border-radius padding-small-sides"
								[innerHTML]="
									value.value ? (value.value | safeHtml: managedObjectAttribute.isExpanded) : ''
								"
							></p>
						}
						@case (AttributeType.File) {
							<p
								class="ellipsis font-smaller ellipsis-long-word attribute-type-file border-radius padding-small-sides"
							>
								{{ value.value }}
							</p>
						}
						@case (AttributeType.URL) {
							<p
								class="ellipsis font-smaller ellipsis-long-word attribute-type-link border-radius padding-small-sides"
							>
								{{ value.value }}
							</p>
						}
					}
				}
			}
		</section>
		<p class="attribute-date flex-item font-smaller">
			{{ managedObjectAttribute.created | date: 'dd-MM-yyyy' }}
		</p>
	</button>
	@if (managedObjectAttribute.isExpanded) {
		<section class="attribute-container-open flex-col">
			<app-attribute-expanded
				[managedObjectAttribute]="managedObjectAttribute"
			></app-attribute-expanded>
			@if (managedObjectAttribute.id === 0) {
				<section class="edit-btn-container">
					@if (managedObjectAttribute.isLoading) {
						<app-loading-spinner></app-loading-spinner>
					} @else {
						<app-confirm-cancel-button-group
							[disabled]="
								!(managedObjectAttribute.hasAttributeValue && managedObjectAttribute.name)
							"
							(confirm)="saveChanges()"
							(cancel)="cancel()"
						></app-confirm-cancel-button-group>
					}
				</section>
			}
		</section>
	}
</section>
