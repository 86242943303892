import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button/button.component';
import { InputComponent } from '../input-field/input/input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { Contact } from '../../core/models/users/contact';
import { ModalService } from '../../core/services/modal.service';
import { EmailService } from '../../core/services/email.service';
import { ToastrService } from '../../core/services/toastr.service';
import { catchError, of, switchMap } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-contact-form',
	standalone: true,
	imports: [ButtonComponent, InputComponent, FormsModule, NgClass],
	templateUrl: './contact-form.component.html',
	styleUrl: './contact-form.component.css',
})
export class ContactFormComponent {
	inquirer: Contact = {
		firstName: '',
		lastName: '',
		email: '',
		country: undefined,
		body: '',
		phoneNumber: undefined,
		subject: '',
	};

	isPhoneNumberValid: boolean = true;
	invalidFormMessage = '';
	sendingForm: boolean = false;

	@Input() showPageWrapper?: boolean = true;

	@Output() cancel = new EventEmitter<void>();

	constructor(
		private modalService: ModalService,
		private emailService: EmailService,
		private toastrService: ToastrService,
	) {}

	sendContactForm(contactForm: NgForm): void {
		this.sendingForm = true;
		if (contactForm.valid) {
			this.emailService
				.sendContactForm(this.inquirer)
				.pipe(
					switchMap(() => {
						return this.modalService.open({
							title: 'The form has been sent',
							content: 'Thank you for your message. We will get back to you shortly.',
							buttons: [
								{
									label: 'Ok',
									action: (): void => {},
								},
							],
						});
					}),
					catchError(() => {
						this.toastrService.showError({});
						this.sendingForm = false;
						return of(undefined);
					}),
				)
				.subscribe({
					next: (_) => {
						this.sendingForm = false;
						this.onCancel();
					},
				});
		} else {
			this.invalidFormMessage = 'Please fill out the form correctly';
			this.sendingForm = false;
		}
	}

	onCancel(): void {
		this.cancel.emit();
	}
}
