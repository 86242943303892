import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../../core/services/user.service';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from '../../../../core/services/toastr.service';
import { ModalService } from '../../../../core/services/modal.service';
import { catchError, of, switchMap, tap } from 'rxjs';

@Component({
	selector: 'app-drop-down',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './drop-down.component.html',
	styleUrl: './drop-down.component.css',
})
export class DropDownComponent implements OnInit {
	@Input() userNameInitial: string = '';
	userName: string = '';
	userEmail: string = '';
	isGuestUser: boolean = false;

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
		private modalService: ModalService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.isGuestUser = this.userService.getGuestUser() ?? false;
		if (this.isGuestUser) {
			this.userName = 'GUEST';
			this.userEmail = '';
		} else {
			this.userName = this.userService.getFullName();
			this.userEmail = this.userService.getEmail();
		}
	}

	logOut(): void {
		this.userService.logOut();
		void this.router.navigate(['/login']);
	}

	openRemoveAccountModal(): void {
		this.modalService
			.open({
				title: 'Delete Data',
				content: 'Your data will be permanently deleted, are you sure you want to delete?',
				buttons: [
					{
						label: 'Delete',
						action: (): void => {
							this.removeGuestAccount();
						},
					},
					{
						label: 'Cancel',
						action: (): void => {},
					},
				],
			})
			.subscribe();
	}

	removeGuestAccount(): void {
		this.userService
			.removeGuestAccount()
			.pipe(
				switchMap(() =>
					this.modalService.open({
						title: 'Data Deleted',
						content: 'Your data has been successfully deleted.',
						buttons: [
							{
								label: 'Ok',
								action: (): void => {
									this.logOut();
									void this.router.navigate(['/login']);
								},
							},
						],
					}),
				),
				tap(() => {
					localStorage.removeItem('guest-user');
				}),
				catchError(() => {
					this.toastrService.showError({});
					return of(undefined);
				}),
			)
			.subscribe();
	}
}
