import { Component } from '@angular/core';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { filter } from 'rxjs';
import { DeluxeDrawService } from '../../core/services/deluxe-draw.service';
import { NodeType } from '../../core/models/deluxedraw/node-type';
import { ContentViewManagedObjectComponent } from './content-view-managedobject/content-view-managedobject.component';
import { ContentViewContextComponent } from './content-view-context/content-view-context.component';
import { Node } from '../../core/models/deluxedraw/node';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-content-view',
	standalone: true,
	imports: [
		LoadingSpinnerComponent,
		ContentViewManagedObjectComponent,
		ContentViewContextComponent,
	],
	templateUrl: './content-view.component.html',
	styleUrl: './content-view.component.css',
})
export class ContentViewComponent {
	nodeType: NodeType | undefined;
	selectedId: number = 0;
	NodeType = NodeType;

	constructor(
		private deluxeDrawService: DeluxeDrawService,
		private toastrService: ToastrService,
	) {
		this.deluxeDrawService.nodeClicked$
			.pipe(
				filter((node): node is Node => node !== undefined),
				takeUntilDestroyed(),
			)
			.subscribe({
				next: (node) => {
					this.nodeType = node.type;
					this.selectedId = node.referenceId;
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}
}
