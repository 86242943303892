<app-modal
	[title]="'Cookie Settings'"
	[buttons]="[{ label: 'Accept All', action: acceptAll }]"
	[disableBackdrop]="true"
	[showCloseButton]="false"
>
	<p>
		At Boswell, we use cookies to enhance your experience on our website. By clicking "Accept All"
		you agree to the storage of cookies on your device to improve website functionality and analyze
		user data. You can adjust your settings at any time.
	</p>
	<br />
	<p class="bold-text">We use cookies for the following purposes:</p>
	<ul class="modal-list">
		<li>The drawings you select and interact with.</li>
		<li>
			Other essential information that helps us improve our services and provide a tailored user
			experience.
		</li>
	</ul>
	<br />
	<p>
		By creating an account or logging in to our website, you consent to the collection, storage, and
		processing of your personal data in accordance with our Privacy Policy.
	</p>
	<br />
	@if (isExpanded) {
		<div>
			<p class="bold-text">What are Cookies?</p>
			<p>
				Cookies are small text files stored on your device when you visit a website. They are used
				to collect and store information about your website usage, enabling us to enhance your
				experience by tailoring content and features to your preferences. Cookies also allow us to
				remember your settings, such as selected drawings, so you don’t need to make the same
				choices every visit.
			</p>
			<br />
			<p class="bold-text">How we use cookies:</p>
			<ul class="modal-list">
				<li>
					<p class="bold-text">To improve website functionality:</p>
					We use cookies to keep track of your choices and preferences so you don’t need to re-enter
					them on each visit.
				</li>
				<li>
					<p class="bold-text">To analyze user data:</p>
					By collecting anonymous information about how you use the website, we can improve and
					customize our services.
				</li>
				<li>
					<p class="bold-text">To optimize the user experience:</p>
					Cookies help us remember the drawings and options you’ve selected, allowing us to create a
					more personalized and efficient experience for you.
				</li>
			</ul>
			<br />
			<p class="bold-text">How can I manage cookies?</p>
			<p>
				You can change or remove cookies at any time by adjusting your browser settings. If you no
				longer want us to store cookies, you can also clear your LocalStorage. Note that this may
				affect your experience on our website.
			</p>
		</div>
	}
	<br />
	<p>
		<button class="cookie-button login-link" (click)="toggleCookieInfo()">
			{{ isExpanded ? 'Less text' : 'Read more about cookies' }}
		</button>
	</p>
</app-modal>
