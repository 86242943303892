import { pointWithin } from "../../deluxe-math.js";

export function getPoints(startAnchor, endAnchor, lineMargin){
	const start = startAnchor.getAbsolutePosition();
	const end = endAnchor.getAbsolutePosition();
	const pStart = {x: start.x, y: start.y};
	const pEnd = {x: end.x, y: end.y};
	const directionY = start.y < end.y ? 1 : -1;

	if (pointWithin(
		start.y + startAnchor.nodeSize.height / 2 + lineMargin,
		end.y,
		start.y - startAnchor.nodeSize.height / 2 - lineMargin,
		end.y)){
		if (start.x < end.x){
			const p1 = {
				x: start.x - lineMargin,
				y: start.y
			};
			const p2 = {
				x: p1.x,
				y: p1.y + (startAnchor.nodeSize.height / 2 + lineMargin) * directionY
			};
			const p3 = {
				x: start.x + startAnchor.nodeSize.width + (end.x - (start.x + startAnchor.nodeSize.width)) / 2,
				y: p2.y
			};
			const p4 = {
				x: p3.x,
				y: end.y
			};
			return [pStart, p1, p2, p3, p4, pEnd]
		}
		else{
			const p1 = {
				x: start.x - (start.x - (end.x + endAnchor.nodeSize.width)) / 2,
				y: start.y
			};
			const p2 = {
				x: p1.x,
				y: end.y - (endAnchor.nodeSize.height / 2 + lineMargin) * directionY
			};
			const p3 = {
				x: end.x - lineMargin,
				y: p2.y
			};
			const p4 = {
				x: p3.x,
				y: end.y
			};
			return [pStart, p1, p2, p3, p4, pEnd]
		}
	}
	if (start.x < end.x){
		const p1 = {
			x: start.x - lineMargin,
			y: start.y
		};
		const p2 = {
			x: start.x - lineMargin,
			y: end.y
		};    
		return [pStart, p1, p2, pEnd];
	}
	const p1 = {
		x: end.x - lineMargin,
		y: start.y
	};
	const p2 = {
		x: end.x - lineMargin,
		y: end.y
	};    
	return [pStart, p1, p2, pEnd];
}
