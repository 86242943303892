import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContactFormComponent } from '../../shared/contact-form/contact-form.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-contact-us',
	standalone: true,
	imports: [FormsModule, ContactFormComponent],
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.css', '../../../form-styles.css'],
})
export class ContactUsComponent {
	constructor(private router: Router) {}

	onCancelForm(): void {
		void this.router.navigate(['/']);
	}
}
