import { RelationState } from "../shared/constants/relation-state.js";

export class RelationRepository
{
    static #relations = [];

    static add(relation) {
        this.#relations.push(relation)
    }

    static getAnchored() {
        return this.#relations.find(relation => relation.relationState === RelationState.Anchored) || null;
    }

    static deleteManyByGuid(guids) {
        this.#relations = this.#relations.filter(relation => !guids.includes(relation.guid));
    }

    static getAll() {
        return this.#relations;
    }

    static deleteByGuid(guid) {
        this.#relations = this.#relations.filter(relation => relation.guid !== guid);
    }

    static clear() {
        this.#relations.forEach(relation => relation.clear());
        this.#relations = [];
    }

    static updateIdByGuid(relationId, relationGuid) {
        const relation = this.#relations.find(relation => relation.guid === relationGuid);
        relation.id = relationId;
    }
}