import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserService } from './core/services/user.service';
import { ToastrComponent } from './shared/toastr/toastr.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, ToastrComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.css',
})
export class AppComponent {
	constructor(private userService: UserService) {}

	@HostListener('window:focus', [])
	onWindowFocus(): void {
		if (this.userService.getCurrentUser()) this.userService.getValidToken().subscribe();
	}
}
