import { gridSize } from "../shared/constants/grid.js";
import { NodeRepository } from "../infrastructure/node-repository.js";
import { distance, getTransformedMousePoint } from "../deluxe-math.js";
import { ApplicationState } from "../shared/constants/application-state.js";

export class MouseMoveEventBehavior {
    #rootContainer
    #svgContainer
    #applicationState
    #activeNode

    constructor(rootContainer, svgContainer, applicationState) {
        this.#rootContainer = rootContainer;
        this.#svgContainer = svgContainer;
        this.#applicationState = applicationState;
        this.#registerListener()
    }

    #registerListener() {
        const nodeHandlerSetActiveListener = (e) => {
            this.#setClosestNodeActive(e);
        };
        this.#rootContainer.addEventListener("pointermove", nodeHandlerSetActiveListener);
    }

    #setClosestNodeActive(e) {
        if (this.#applicationState === ApplicationState.Navigate)
            return;

        const transformedMousePoint = getTransformedMousePoint(this.#svgContainer, e);
        const closestNodeWithDistance = this.#getClosestNode(transformedMousePoint);
        const maxDistance = gridSize * 8;
    
        if (closestNodeWithDistance && this.#activeNode !== closestNodeWithDistance.node && closestNodeWithDistance.distance < maxDistance){
            this.#activeNode?.setInactive();
            this.#activeNode = closestNodeWithDistance.node;
            this.#activeNode.setActive();
        }
    
        if (closestNodeWithDistance && closestNodeWithDistance.distance >= maxDistance && this.#activeNode){
            this.#activeNode?.setInactive();
            this.#activeNode = undefined;
        }
    }

    #getClosestNode(mousePosition) {
        const nodes = NodeRepository.getAll();
        if (!nodes.length)
            return;
    
        return nodes.map(node => {
            const pos = node.getNodeCenterPosition();
            return {
                distance: distance(pos.x, pos.y, mousePosition.x, mousePosition.y),
                node: node
            };
        }).reduce((n1, n2) => n1.distance < n2.distance ? n1 : n2);
    }

    handle(applicationState) {
        this.#applicationState = applicationState;
    }
}