import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button/button.component';
import { InputComponent } from '../input-field/input/input.component';
import { ProcessStatus, RegisterUser } from '../../core/models/users';
import { FormsModule, NgForm } from '@angular/forms';
import { EmailService } from '../../core/services/email.service';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-register-form',
	standalone: true,
	imports: [ButtonComponent, InputComponent, FormsModule],
	templateUrl: './register-form.component.html',
	styleUrls: ['./register-form.component.css', '../../../form-styles.css'],
})
export class RegisterFormComponent {
	@Input() onRegister!: (user: RegisterUser) => void;
	@Input() registering: boolean = false;
	@Input() registerResult!: ProcessStatus;
	@Input() registerResults!: typeof ProcessStatus;

	user: RegisterUser = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		acceptedTerms: false,
	};
	invalidFormMessage = '';
	hasResentEmail: boolean = false;
	isExpanded: boolean = false;

	constructor(
		private toastrService: ToastrService,
		private emailService: EmailService,
	) {}

	handleFormSubmit(registerForm: NgForm): void {
		if (registerForm.valid && this.user.password === this.user.confirmPassword)
			this.onRegister(this.user);
	}

	resendEmail(): void {
		this.hasResentEmail = false;
		this.emailService.resendVerificationEmail(this.user.email).subscribe({
			next: () => {
				this.hasResentEmail = true;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	backToRegister(): void {
		this.registerResult = this.registerResults.Default;
	}

	togglePrivacyNotice(): void {
		this.isExpanded = !this.isExpanded;
	}
}
