import { Component, OnInit } from '@angular/core';
import { TopBarComponent } from '../../shared/top-bar/top-bar.component';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { User } from '../../core/models/users';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-settings',
	standalone: true,
	imports: [TopBarComponent, FormsModule, RouterOutlet, RouterLinkActive, RouterLink],
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css', '../../../form-styles.css'],
})
export class SettingsComponent implements OnInit {
	user: User;
	nameInitials: string = '';
	userEmail: string = '';
	isGuest: boolean | undefined;

	constructor(private userService: UserService) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser === undefined) throw new Error(`Couldn't get current user`);
		this.user = currentUser.user;
		this.isGuest = this.userService.getGuestUser();
	}

	ngOnInit(): void {
		if (this.user) {
			this.nameInitials = this.getInitials(this.user.firstName, this.user.lastName);
			this.userEmail = this.user.email;
		}
	}

	getInitials(firstName: string, lastName: string): string {
		const firstNameInitial = firstName.charAt(0).toUpperCase();
		const lastNameInitial = lastName.charAt(0).toUpperCase();
		return firstNameInitial + lastNameInitial;
	}
}
