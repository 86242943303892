import { Component } from '@angular/core';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { NgIf } from '@angular/common';

@Component({
	selector: 'app-faq',
	standalone: true,
	imports: [ButtonComponent, NgIf],
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.css', '../settings-form-styles.component.css'],
})
export class FAQComponent {
	showAnswer: { [key: number]: boolean } = {};

	toggleAnswer(key: number): void {
		this.showAnswer[key] = !this.showAnswer[key];
	}
}
