<header class="flex-row header">
	<section class="flex-row header-logo-container" routerLink="/">
		<img class="header-logo" src="../../../assets/logo/boswell.png" alt="Boswell logo" />
		<h1>BOSWELL</h1>
	</section>
	<section class="beta">
		<button class="default-btn" (click)="openBetaDisclaimer()"><h2>BETA</h2></button>
	</section>
	<section>
		<app-user-profile></app-user-profile>
	</section>
</header>
