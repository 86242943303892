<section class="page-container flex-col">
	<section class="flex-col questions-container">
		<section class="question">
			<app-button
				extraClass="keep-scale toggle-btn"
				label="This is first question"
				(buttonClick)="toggleAnswer(1)"
			>
			</app-button>
			@if (showAnswer[1]) {
				<p>This is the answer to the first question</p>
			}
		</section>
		<section class="question">
			<app-button
				extraClass="keep-scale toggle-btn"
				label="This is second question"
				(buttonClick)="toggleAnswer(2)"
			></app-button>
			@if (showAnswer[2]) {
				<p>This is the answer to the second question</p>
			}
		</section>
	</section>
	<section>
		<p>
			Still have questions? Contact us <a class="link-to-form" href="/settings/faq/contact">here</a>
		</p>
	</section>
</section>
