import { Node } from "./node.js"
import { Anchor } from "../anchor.js";
import { namespace } from "../../../shared/constants/svg.js";
import { Location } from "../../../shared/constants/location.js";

export class DiamondNode extends Node{
	color = "rgb(255, 255, 255)";
	#border = "#888888";

	constructor(position, actionMenuSelection, shape, referenceId, id, type){
		super(actionMenuSelection, shape, position, referenceId, id, type);
		this.#createSvg(position);
		this.#createAnchors();
		this.registerListeners();
	}

	getNodeCenterPosition(){
		const pos = this.getSvgPosition();
		return {
			x: pos.x + this.width / 2, 
			y: pos.y + this.height / 2 
		};
	}

	#createSvg(position){
		const {x, y} = position;

        this.svgContainer = document.createElementNS(namespace, "svg");
		this.svgContainer.setAttribute("fill", "white");
		this.svgContainer.setAttribute("x", x);
		this.svgContainer.setAttribute("y", y);
		this.svgContainer.style.overflow = "visible";
        
		this.svg = document.createElementNS(namespace, "polygon");
		this.svg.setAttribute("points", this.#getDiamondNodePoints());
		this.svg.setAttribute("fill", this.color);
		this.svg.setAttribute("stroke", this.#border);
		this.svg.setAttribute("stroke-width", this.strokeWidth);
		
        this.svgContainer.appendChild(this.svg);
		this.container.appendChild(this.svgContainer);
		this.#createStatusIndicator();
		this.createTextElement();
	}

	#createStatusIndicator(){
		const maskSvg = document.createElementNS(namespace, "mask");
		maskSvg.setAttribute("id", "cut-off-diamond");
		maskSvg.style.pointerEvents = "none";

		const rectMask = document.createElementNS(namespace, "rect");
		rectMask.setAttribute("width", this.width);
		rectMask.setAttribute("height", this.height);
		rectMask.setAttribute("fill", "white");

		const polygonMask = document.createElementNS(namespace, "polygon")
		polygonMask.setAttribute("points", this.#getDiamondNodePointsWithLateralOffset());
		polygonMask.setAttribute("fill", "black");
		
		maskSvg.appendChild(rectMask);
		maskSvg.appendChild(polygonMask);

		const statusSvg = document.createElementNS(namespace, "polygon");
		statusSvg.setAttribute("points", this.#getDiamondNodePoints(this.strokeWidth / 2));
		statusSvg.setAttribute("mask", "url(#cut-off-diamond)");
		statusSvg.style.pointerEvents = "none";
		
		this.svgContainer.appendChild(maskSvg);
		this.svgContainer.appendChild(statusSvg);
	}

	#createAnchors(){
		const halfWidth = this.width * 0.5;
		const halfHeight = this.height * 0.5;
		const nodeSize = {
			"width": this.width, 
			"height": this.height
		}
		this.anchors = [
			new Anchor({ x: halfWidth, y: 0 }, Location.Top, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: this.width, y: halfHeight }, Location.Right, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: halfWidth, y: this.height }, Location.Bottom, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: 0, y: halfHeight }, Location.Left, this.svgContainer, nodeSize, this.applicationState, this.id),
		];
	}

	#getDiamondNodePoints(halfStrokeWidth=0){
		return `${0 + halfStrokeWidth} ${this.height/2},${this.width/2} ${this.height - halfStrokeWidth * 1.5},${this.width - halfStrokeWidth} ${this.height/2},${this.width/2} ${0 + halfStrokeWidth * 1.5}`
	}

	#getDiamondNodePointsWithLateralOffset(){
		return `${0 + this.statusIndicatorWidth} ${this.height/2},${this.width/2 + this.statusIndicatorWidth} ${this.height},${this.width + this.statusIndicatorWidth} ${this.height/2},${this.width/2 + this.statusIndicatorWidth} 0`
	}
}