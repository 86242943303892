import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, shareReplay, Subject, tap } from 'rxjs';
import { Attribute } from '../models/attribute';
import { ManagedObjectAttribute } from '../models/managed-object';
import { MetaDataResponse } from '../models/attribute/meta-data-response';

@Injectable({
	providedIn: 'root',
})
export class AttributeService {
	private baseUrl: string = `${environment.apiUrl}attribute/api`;
	private cache$: Observable<Attribute[]> | undefined;
	private lastFetchTime: number = 0;
	private readonly cacheDuration = 60000;

	private managedObjectAttributeChanged: Subject<ManagedObjectAttribute> = new Subject();
	private managedObjectAttributeInitalized: Subject<ManagedObjectAttribute> = new Subject();
	private managedObjectAttributeCreated: Subject<ManagedObjectAttribute> = new Subject();
	private managedObjectAttributeClosed: Subject<ManagedObjectAttribute> = new Subject();
	private managedObjectAttributeDeleted: Subject<ManagedObjectAttribute> = new Subject();

	constructor(private httpClient: HttpClient) {}

	getAll(): Observable<Attribute[]> {
		if (!this.cache$ || Date.now() - this.lastFetchTime > this.cacheDuration) {
			this.cache$ = this.httpClient.get<Attribute[]>(`${this.baseUrl}/attributes`).pipe(
				tap(() => {
					this.lastFetchTime = Date.now();
				}),
				shareReplay(1),
			);
		}
		return this.cache$;
	}

	updateManagedObjectAttribute(managedObjectAttribute: ManagedObjectAttribute): void {
		this.managedObjectAttributeChanged.next(managedObjectAttribute);
	}

	deleteManagedObjectAttribute(managedObjectAttribute: ManagedObjectAttribute): void {
		this.managedObjectAttributeDeleted.next(managedObjectAttribute);
	}

	managedObjectAttributeUpdated$(): Observable<ManagedObjectAttribute> {
		return this.managedObjectAttributeChanged.asObservable();
	}

	getMetaData(url: string): Observable<MetaDataResponse> {
		return this.httpClient.get<MetaDataResponse>(
			`${this.baseUrl}/managedobjectattribute/meta-data?url=${url}`,
		);
	}

	uploadFile(file: File, fileName: string, containerName: string): Observable<string> {
		return this.httpClient.post<string>(
			`${this.baseUrl}/filemanagement/containers/${containerName}/files/${fileName}`,
			file,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/octet-stream',
					'Content-Disposition': 'attachment',
				}),
				responseType: 'text' as 'json',
				reportProgress: true,
			},
		);
	}

	downloadFile(fileName: string, folderId: string): Observable<Blob> {
		const params = new HttpParams().set('containerName', folderId).set('fileName', fileName);
		return this.httpClient.get<File>(`${this.baseUrl}/filemanagement`, {
			params: params,
			responseType: 'blob' as 'json',
		});
	}

	initalizeManagedObjectAttribute(attribute: Attribute, folderId: number): void {
		return this.managedObjectAttributeInitalized.next({
			id: 0,
			name: '',
			description: '',
			attributeId: attribute.id,
			attribute: attribute,
			folderId: folderId,
			created: new Date(),
			changed: new Date(),
			managedObjectId: 0,
			isExpanded: true,
			managedObjectAttributeValues: attribute.attributeValues.map((av) => ({
				id: 0,
				value: '',
				managedObjectAttributeId: 0,
				attributeValue: av,
				attributeValueId: av.id,
				preview: false,
				isEditing: true,
			})),
		});
	}

	createManagedObjectAttribute(managedObjectAttribute: ManagedObjectAttribute): void {
		return this.managedObjectAttributeCreated.next({
			id: 0,
			name: managedObjectAttribute.name,
			description: managedObjectAttribute.description,
			attributeId: managedObjectAttribute.attribute.id,
			attribute: managedObjectAttribute.attribute,
			folderId: managedObjectAttribute.folderId,
			created: new Date(),
			changed: new Date(),
			managedObjectId: managedObjectAttribute.managedObjectId,
			isExpanded: true,
			managedObjectAttributeValues: managedObjectAttribute.managedObjectAttributeValues.map(
				(av) => ({
					id: 0,
					value: av.value,
					managedObjectAttributeId: managedObjectAttribute.id,
					attributeValue: av.attributeValue,
					attributeValueId: av.attributeValueId,
					preview: false,
				}),
			),
		});
	}

	closeManagedObjectAttribute(managedObjectAttribute: ManagedObjectAttribute): void {
		this.managedObjectAttributeClosed.next(managedObjectAttribute);
	}

	managedObjectAttributeClosed$(): Observable<ManagedObjectAttribute> {
		return this.managedObjectAttributeClosed.asObservable();
	}

	managedObjectAttributeInitalized$(): Observable<ManagedObjectAttribute> {
		return this.managedObjectAttributeInitalized.asObservable();
	}

	managedObjectAttributeCreated$(): Observable<ManagedObjectAttribute> {
		return this.managedObjectAttributeCreated.asObservable();
	}

	managedObjectAttributeDeleted$(): Observable<ManagedObjectAttribute> {
		return this.managedObjectAttributeDeleted.asObservable();
	}
}
