import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { ManagedObjectService } from '../../../core/services/managed-object.service';
import { NgFor, NgIf } from '@angular/common';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { ManagedObject, ManagedObjectAttribute } from '../../../core/models/managed-object';
import { AttributeService } from '../../../core/services/attribute.service';
import { Attribute } from '../../../core/models/attribute';
import { Folder } from '../../../core/models/folders';
import { ConfirmCancelButtonGroupComponent } from '../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { FolderComponent } from '../../folder/folder.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../../core/services/modal.service';
import { ModalMode } from '../../../shared/modal/modal.component';
import { NodeService } from '../../../core/services/node-service';
import { NodeType } from '../../../core/models/deluxedraw/node-type';
import { RenameNode } from '../../../core/models/context-node/rename-node';
import { ContentViewStateService } from '../../../core/services/content-view-state.service';
import { ToastrService } from '../../../core/services/toastr.service';

@Component({
	selector: 'app-content-view-managedobject',
	standalone: true,
	imports: [
		InputComponent,
		ButtonComponent,
		FolderComponent,
		FormsModule,
		LoadingSpinnerComponent,
		NgIf,
		NgFor,
		ConfirmCancelButtonGroupComponent,
	],
	templateUrl: './content-view-managedobject.component.html',
	styleUrl: '../content-view.component.css',
})
export class ContentViewManagedObjectComponent implements OnChanges, OnInit {
	@Input() managedObjectId!: number;
	loading: boolean = false;
	managedObject: ManagedObject | undefined;
	attributes: Attribute[] = [];
	newManagedObjectName: string = '';
	addFolderFormVisible: boolean = false;
	newFolderName: string = '';
	loadingNewFolder: boolean = false;

	constructor(
		private managedObjectService: ManagedObjectService,
		private attributeService: AttributeService,
		private modalService: ModalService,
		private nodeService: NodeService,
		private contentViewStateService: ContentViewStateService,
		private toastrService: ToastrService,
	) {
		this.attributeService
			.managedObjectAttributeUpdated$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: (managedObjectAttribute) => {
					managedObjectAttribute.isLoading = true;
					const folder = this.findAttributeFolder(managedObjectAttribute);
					const attributeIndex = this.findAttributeIndex(managedObjectAttribute);

					if (attributeIndex !== -1) {
						folder.managedObjectAttributes[attributeIndex] = {
							...managedObjectAttribute,
						};
					} else throw Error;

					this.managedObjectService.update(this.managedObject!, this.attributes).subscribe({
						next: (managedObject) => {
							this.applyExpandedStates(managedObject);
							this.managedObject = managedObject;
							managedObjectAttribute.isLoading = false;
						},
						error: () => {
							this.toastrService.showError({});
						},
					});
				},
				error: () => {
					this.toastrService.showError({});
				},
			});

		this.attributeService
			.managedObjectAttributeCreated$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: (managedObjectAttribute) => {
					managedObjectAttribute.isLoading = true;
					const folder = this.findAttributeFolder(managedObjectAttribute);
					const attributeIndex = this.findAttributeIndex(managedObjectAttribute);

					if (attributeIndex !== -1) {
						folder.managedObjectAttributes[attributeIndex] = {
							...managedObjectAttribute,
						};
					}

					this.managedObjectService.update(this.managedObject!, this.attributes).subscribe({
						next: (managedObject) => {
							this.applyExpandedStates(managedObject);
							this.managedObject = managedObject;
							managedObjectAttribute.isLoading = false;
						},
						error: () => {
							this.toastrService.showError({});
						},
					});
				},
				error: () => {
					this.toastrService.showError({});
				},
			});

		this.attributeService
			.managedObjectAttributeClosed$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: (managedObjectAttribute) => {
					const folder = this.findAttributeFolder(managedObjectAttribute);

					folder.managedObjectAttributes = folder.managedObjectAttributes.filter(
						(moa) => moa !== managedObjectAttribute,
					);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});

		this.attributeService
			.managedObjectAttributeInitalized$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: (managedObjectAttribute) => {
					const folder = this.findAttributeFolder(managedObjectAttribute);
					if (folder) {
						folder.managedObjectAttributes = [
							{
								...managedObjectAttribute,
								managedObjectId: this.managedObject!.id,
							},
							...folder.managedObjectAttributes,
						];
					}
				},
				error: () => {
					this.toastrService.showError({});
				},
			});

		this.attributeService
			.managedObjectAttributeDeleted$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: (managedObjectAttribute) => {
					const folder = this.findAttributeFolder(managedObjectAttribute);
					const attributeIndex = this.findAttributeIndex(managedObjectAttribute);

					if (attributeIndex !== -1) folder.managedObjectAttributes.splice(attributeIndex, 1);

					folder.managedObjectAttributes = folder.managedObjectAttributes.filter(
						(moa) => moa.id !== 0,
					);

					this.managedObjectService.update(this.managedObject!, this.attributes).subscribe({
						next: (managedObject) => {
							this.applyExpandedStates(managedObject);
							this.managedObject = managedObject;
						},
						error: () => {
							this.toastrService.showError({});
						},
					});
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	ngOnInit(): void {
		this.nodeService.updatedNodeName$().subscribe({
			next: (updatedManagedObject) => {
				if (this.managedObjectId === updatedManagedObject.id)
					this.managedObject!.name = updatedManagedObject.name;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['managedObjectId'] && this.managedObjectId !== undefined) {
			this.loadManagedObject().subscribe({
				next: (managedObject) => {
					this.managedObject = managedObject;
					this.loading = false;
					this.addFolderFormVisible = false;
					this.newFolderName = '';
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
		}
	}

	private applyExpandedStates(managedObject: ManagedObject): void {
		managedObject.folders.forEach((folder) => {
			folder.isExpanded = this.contentViewStateService.isFolderExpanded(folder.id);

			folder.managedObjectAttributes.forEach((attribute) => {
				attribute.isExpanded = this.contentViewStateService.isManagedObjectAttributeExpanded(
					attribute.id,
				);
			});
		});
	}

	private loadManagedObject(): Observable<ManagedObject> {
		this.loading = true;
		return this.attributeService.getAll().pipe(
			switchMap((attributes) => {
				this.attributes = attributes;
				return this.managedObjectService.get(this.managedObjectId, this.attributes);
			}),
			catchError((error) => {
				this.toastrService.showError({});

				return throwError(() => error);
			}),
		);
	}

	findAttributeFolder(managedObjectAttribute: ManagedObjectAttribute): Folder {
		this.validateManagedObjectOrThrow();

		const folder = this.managedObject!.folders.find(
			(folder) => folder.id === managedObjectAttribute.folderId,
		);
		if (!folder) throw new Error('folder missing');
		return folder;
	}

	findAttributeIndex(managedObjectAttribute: ManagedObjectAttribute): number {
		const folder = this.findAttributeFolder(managedObjectAttribute);
		const attributeIndex = folder.managedObjectAttributes.findIndex(
			(moa) => moa.id === managedObjectAttribute.id,
		);
		return attributeIndex;
	}

	showAddFolderForm(): void {
		this.addFolderFormVisible = true;
	}

	cancelNewFolder(): void {
		this.addFolderFormVisible = false;
	}

	createNewFolder(): void {
		if (this.managedObject) {
			if (this.newFolderName.length > 0) {
				const newFolder: Folder = {
					id: 0,
					name: this.newFolderName,
					managedObjectId: this.managedObject.id,
					managedObjectAttributes: [],
				};

				this.managedObject.folders.push(newFolder);
				this.managedObjectService.update(this.managedObject, this.attributes).subscribe({
					next: (managedObject) => {
						this.managedObject = managedObject;
						this.newFolderName = '';
					},
					error: () => {
						this.toastrService.showError({});
						this.loadingNewFolder = false;
					},
				});

				this.addFolderFormVisible = false;
			} else this.addFolderFormVisible = false;
		} else this.addFolderFormVisible = false;
	}

	onRenameFolder(): void {
		this.validateManagedObjectOrThrow();

		this.managedObjectService.update(this.managedObject!, this.attributes).subscribe({
			next: (managedObject) => {
				this.managedObject = managedObject;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	onDeleteFolder(folderId: number): void {
		this.validateManagedObjectOrThrow();

		const updateManagedObject: ManagedObject = {
			...this.managedObject!,
			folders: this.managedObject!.folders.filter((folder) => folder.id !== folderId),
		};
		this.managedObjectService.update(updateManagedObject, this.attributes).subscribe({
			next: (managedObject) => {
				this.managedObject = managedObject;
			},
			error: (httpErrorResponse: HttpErrorResponse) => {
				if (httpErrorResponse.status === 409) {
					this.modalService
						.open({
							title: 'Folder could not be deleted',
							content: httpErrorResponse.error.detail,
							mode: ModalMode.Info,
						})
						.subscribe();
				} else console.error(httpErrorResponse.error);
			},
		});
	}

	onShowEditForm(): void {
		this.validateManagedObjectOrThrow();
		this.managedObject!.isEditing = true;
	}

	onRenameManagedObject(managedObject: ManagedObject): void {
		managedObject.isLoading = true;
		managedObject.name = this.newManagedObjectName;
		this.managedObjectService
			.update(managedObject, this.attributes)
			.pipe(switchMap(() => this.managedObjectService.get(managedObject.id, this.attributes)))
			.subscribe({
				next: (mo) => {
					this.nodeService.renameNode({
						name: mo.name,
						id: mo.id,
						nodeType: NodeType.ManagedObject,
					} as RenameNode);
					this.managedObject = mo;
					managedObject.isLoading = false;
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	onCancelRenamingManagedObject(): void {
		this.validateManagedObjectOrThrow();
		this.managedObject!.isEditing = false;
		this.newManagedObjectName = '';
	}

	private validateManagedObjectOrThrow(): void {
		if (!this.managedObject) throw new Error('managedObject undefined');
	}
}
