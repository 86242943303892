import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MetaDataResponse } from '../../../../../core/models/attribute/meta-data-response';

@Component({
	selector: 'app-meta-data',
	standalone: true,
	imports: [NgIf],
	templateUrl: './meta-data.component.html',
	styleUrls: ['./meta-data.component.css'],
})
export class MetaDataComponent {
	@Input() metaData: MetaDataResponse | undefined;
	@Input() url: string = '';
}
