import { Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../core/services/context.service';
import { CommonModule, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { FormsModule } from '@angular/forms';
import { ContextView } from '../../core/models/contexts';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ContextItemComponent } from './context-item/context-item.component';
import { ContextItemService } from './context-item/context-item.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NodeType } from '../../core/models/deluxedraw/node-type';
import { ModalService } from '../../core/services/modal.service';
import { NodeService } from '../../core/services/node-service';
import { RenameNode } from '../../core/models/context-node/rename-node';
import { ToastrService } from '../../core/services/toastr.service';
import { catchError, of, tap } from 'rxjs';

@Component({
	selector: 'app-side-bar',
	standalone: true,
	templateUrl: './side-bar.component.html',
	styleUrl: './side-bar.component.css',
	imports: [
		CommonModule,
		ButtonComponent,
		NgIf,
		FormsModule,
		LoadingSpinnerComponent,
		ContextItemComponent,
		InputComponent,
	],
})
export class SideBarComponent implements OnInit {
	@Input() contexts: ContextView[] = [];
	@Input() currentContext!: ContextView;
	@Input() loadingContexts: boolean = false;
	addDrawingFormVisible: boolean = false;
	newDrawingName: string = '';
	creatingNewDrawing: boolean = false;
	canCreateContext: boolean = true;

	constructor(
		private contextService: ContextService,
		private contextItemService: ContextItemService,
		private modalService: ModalService,
		private nodeService: NodeService,
		private toastrService: ToastrService,
		private router: Router,
	) {
		this.contextItemService.toggleExpansion.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onToggleExpansion(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.contextItemService.deleteDrawing.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onDeleteDrawing(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.contextItemService.renameDrawing.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onRenameDrawing(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.contextService.canCreateContext$
			.pipe(takeUntilDestroyed())
			.subscribe((canCreateContext) => {
				this.canCreateContext = canCreateContext;
			});
	}

	ngOnInit(): void {
		this.nodeService.updatedNodeName$().subscribe({
			next: (updatedContext) => {
				const matchingChild = this.findContextById(this.contexts, updatedContext.id);
				if (matchingChild) matchingChild.name = updatedContext.name;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	showAddDrawingForm(): void {
		if (this.canCreateContext) this.addDrawingFormVisible = true;
		else {
			this.modalService
				.open({
					title: 'No more avaliable drawings',
					content:
						'Upgrade to create an unlimited number of drawings, or press "Cancel" to continue using the free version.',
					buttons: [
						{
							label: 'Become Pro',
							action: (): void => {
								void this.router.navigate(['/settings']);
							},
						},
						{
							label: 'Cancel',
							action: (): void => {},
						},
					],
				})
				.subscribe();
		}
	}

	cancelNewDrawing(): void {
		this.addDrawingFormVisible = false;
	}

	addNewDrawing(): void {
		if (!this.canCreateContext) this.showAddDrawingForm();
		else {
			if (this.newDrawingName.length > 0) {
				this.creatingNewDrawing = true;
				this.contextService.createContext(this.newDrawingName, '').subscribe({
					next: (context) => {
						this.contexts.push({ ...context });
						void this.router.navigate(['/', context.id]);
						this.creatingNewDrawing = false;
						this.newDrawingName = '';
					},
					error: () => {
						this.creatingNewDrawing = false;
						this.toastrService.showError({});
					},
				});
				this.addDrawingFormVisible = false;
			} else {
				this.addDrawingFormVisible = false;
			}
		}
	}

	onRenameDrawing(context: ContextView): void {
		context.isLoading = true;
		context.isEditing = false;
		this.contextService.update(context.id, context.name, context.description).subscribe({
			next: (updatedContext) => {
				const matchingChild = this.findContextById(this.contexts, updatedContext.id);
				if (matchingChild) {
					this.nodeService.renameNode({
						name: updatedContext.name,
						id: updatedContext.id,
						nodeType: NodeType.Context,
					} as RenameNode);
				}
				context.isLoading = false;
			},
			error: () => {
				context.isLoading = false;
				this.toastrService.showError({});
			},
		});
	}

	onDeleteDrawing(context: ContextView): void {
		context.isLoading = true;
		this.contextService
			.deleteContext(context.id)
			.pipe(
				tap(() => {
					this.contexts = this.contexts.filter((ctx) => ctx.id != context.id);

					if (this.currentContext.children.some((t) => t.id === context.id))
						this.nodeService.deleteContextNode(context.id, NodeType.Context);

					if (context.id === this.currentContext.id)
						void this.router.navigate([this.contexts[0]?.id]);
				}),
				catchError((httpErrorResponse: HttpErrorResponse) => {
					if (httpErrorResponse.status === 409) {
						return this.modalService.open({
							title: 'Drawing could not be deleted',
							content: httpErrorResponse.error.detail,
							buttons: [
								{
									label: 'Ok',
									action: (): void => {},
								},
							],
						});
					}
					return of(undefined);
				}),
			)
			.subscribe({
				next: (_) => {
					context.isLoading = false;
				},
			});
	}

	onToggleExpansion(context: ContextView): void {
		context.expanded = !context.expanded;
	}

	findContextById(contexts: ContextView[], id: number): ContextView | undefined {
		for (const context of contexts) {
			if (context.id === id) return context;
			if (context.children) {
				const found = this.findContextById(context.children, id);
				if (found) return found;
			}
		}
		return undefined;
	}
}
