import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Context, Node } from '../models/deluxedraw';
import { NodeType } from '../models/deluxedraw/node-type';
import { ManagedObjectService } from './managed-object.service';
import { ContextService } from './context.service';
import { CreateNode } from '../models/deluxedraw/create-node';
import { ManagedObjectNode } from '../models/managed-object-node/managed-object-node';
import { map } from 'rxjs';
import { ContextNode } from '../models/context-node/context-node';

@Injectable({
	providedIn: 'root',
})
export class DeluxeDrawService {
	private clickedManagedObjectId = new BehaviorSubject<number | undefined>(undefined);
	private clickedNode = new BehaviorSubject<Node | null>(null);

	private openContext$ = new Subject<Context>();
	private currentlySelectedNode: number | undefined;

	constructor(
		private managedObjectService: ManagedObjectService,
		private contextService: ContextService,
	) {}

	openContext(context: Context): void {
		this.openContext$.next(context);
	}

	get context$(): Observable<Context> {
		return this.openContext$.asObservable();
	}

	nodeClicked(node: Node): void {
		this.currentlySelectedNode = node.referenceId;
		this.clickedNode.next(node);
	}

	get nodeClicked$(): Observable<Node | null> {
		return this.clickedNode.asObservable();
	}

	removeNode(node: Node): Observable<void> {
		const isCurrentlySelectedNode = this.currentlySelectedNode === node.referenceId;
		switch (node.type) {
			case NodeType[NodeType.ManagedObject]: {
				if (isCurrentlySelectedNode) {
					this.currentlySelectedNode = undefined;
					this.clickedManagedObjectId.next(undefined);
				}
				return this.deleteManagedObject(node);
			}
			case NodeType[NodeType.Context]: {
				return this.deleteContext(node);
			}
			default: {
				throw new Error('Unsupported node type');
			}
		}
	}

	createNode(node: CreateNode): Observable<{ nodeId: number; referenceId: number }> {
		switch (node.nodeType) {
			case NodeType[NodeType.ManagedObject]: {
				return this.createManagedObject(node).pipe(
					map((response: ManagedObjectNode) => {
						return {
							nodeId: response.nodeId,
							referenceId: response.managedObjectId,
						};
					}),
				);
			}
			case NodeType[NodeType.Context]: {
				return this.createContext(node).pipe(
					map((response: ContextNode) => {
						return {
							nodeId: response.nodeId,
							referenceId: response.contextId,
						};
					}),
				);
			}
			default: {
				throw new Error('Unsupported node type');
			}
		}
	}

	private deleteManagedObject(node: Node): Observable<void> {
		return this.managedObjectService.delete(node.id, node.referenceId);
	}

	deleteContext(node: Node): Observable<void> {
		return this.contextService.deleteFromNode(node.id, node.referenceId);
	}

	private createManagedObject(createNode: CreateNode): Observable<ManagedObjectNode> {
		return this.managedObjectService.create(createNode);
	}

	private createContext(createNode: CreateNode): Observable<ContextNode> {
		return this.contextService.createFromNode(createNode);
	}
}
