export class NodeRepository
{
    static #nodes = [];

    static add(node) {
        this.#nodes.push(node)
    }

    static clear() {
        this.#nodes.forEach(node => node.removeFromSvg())
        this.#nodes = [];
    }

    static getAll() {
        return this.#nodes;
    }

    static deleteByGuid(guid) {
        this.#nodes = this.#nodes.filter(node => node.guid != guid);
    }
    
    static updateNodeByGuid(referenceId, nodeId, nodeGuid) {
        const node = this.#nodes.find(node => node.guid === nodeGuid);
        node.referenceId = referenceId;
        node.id = nodeId;
        node.anchors.forEach(anchor => {
            anchor.nodeId = nodeId;
        });
    }

    static updateNodeName(referenceId, newName, nodeType) {
        const node = this.#nodes.find(node => node.referenceId === referenceId && node.type === nodeType);
        node.setName(newName);
    }

    static getAnchorByLocationAndNodeId(anchorLocation, nodeId) {
        return this.#nodes.find(node => node.id === nodeId).anchors.find(anchor => anchor.location === anchorLocation);
    }
}