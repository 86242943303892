import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
	selector: 'app-confirm-cancel-button-group',
	standalone: true,
	imports: [LoadingSpinnerComponent],
	templateUrl: './confirm-cancel-button-group.component.html',
	styleUrl: './confirm-cancel-button-group.component.css',
})
export class ConfirmCancelButtonGroupComponent {
	@Input() showLoadingSpinner: boolean | undefined = false;
	@Input() disabled: boolean = false;

	@Output() confirm = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	onConfirm(event: MouseEvent): void {
		if (!this.disabled) {
			this.confirm.emit();
		}
		event.stopPropagation();
	}

	onCancel(event: MouseEvent): void {
		this.cancel.emit();
		event.stopPropagation();
	}
}
