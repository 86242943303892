import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Node } from '../models/deluxedraw/node';
import { Observable, Subject } from 'rxjs';
import { UpdateNodeProperties } from '../models/managed-object-node/update-properties';
import { RenameNode } from '../models/context-node/rename-node';

@Injectable({
	providedIn: 'root',
})
export class NodeService {
	private baseUrl: string = `${environment.apiUrl}context/api`;
	private nodeName: Subject<RenameNode> = new Subject();

	constructor(private httpClient: HttpClient) {}

	update(properties: UpdateNodeProperties): Observable<Node> {
		return this.httpClient.put<Node>(`${this.baseUrl}/nodes`, properties);
	}

	renameNode(renameNode: RenameNode): void {
		this.nodeName.next({ name: renameNode.name, id: renameNode.id, nodeType: renameNode.nodeType });
	}

	updatedNodeName$(): Observable<RenameNode> {
		return this.nodeName.asObservable();
	}
}
