import { Component } from '@angular/core';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { EmailService } from '../../core/services/email.service';
import { RouterModule } from '@angular/router';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-forgot-password',
	standalone: true,
	imports: [
		InputComponent,
		ButtonComponent,
		FormsModule,
		NgIf,
		LoadingSpinnerComponent,
		RouterModule,
	],
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css', '../../../form-styles.css'],
})
export class ForgotPasswordComponent {
	userEmail: string = '';
	forgotPassword: boolean = false;
	emailSent: string = '';

	constructor(
		private emailService: EmailService,
		private toastrService: ToastrService,
	) {}

	onForgotPassword(forgotPasswordForm: NgForm): void {
		if (forgotPasswordForm.valid) {
			this.forgotPassword = true;
			this.emailSent = '';
			this.emailService.forgotPasswordEmail(this.userEmail).subscribe({
				next: () => {
					this.forgotPassword = false;
					this.emailSent = `An email has been sent to ${this.userEmail}`;
				},
				error: () => {
					this.forgotPassword = false;
					this.emailSent = '';
					this.toastrService.showError({});
				},
			});
		}
	}
}
