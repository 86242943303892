import { Component } from '@angular/core';
import { User } from '../../../core/models/users';
import { UserService } from '../../../core/services/user.service';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-payment',
	standalone: true,
	imports: [ButtonComponent, RouterLink],
	templateUrl: './payment.component.html',
	styleUrls: [
		'./payment.component.css',
		'../../../../form-styles.css',
		'../settings-form-styles.component.css',
	],
})
export class PaymentComponent {
	user: User;

	constructor(private userService: UserService) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser === undefined) throw new Error(`Couldn't get current user`);
		this.user = currentUser.user;
	}
}
