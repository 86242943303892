import { Location } from "../../shared/constants/location.js";
import { parentNodesHorizontalOverlap } from "../../deluxe-math.js";

export function getPoints(startAnchor, endAnchor, lineMargin){
	const start = startAnchor.getAbsolutePosition();
	const end = endAnchor.getAbsolutePosition();
	const pStart = {
		x: start.x,
		y: start.y
	};
	const pEnd = {
		x: end.x,
		y: end.y
	};
	if ((startAnchor.location === Location.Bottom && end.y < start.y) || (startAnchor.location === Location.Top && start.y < end.y)){
			const directionY = startAnchor.location === Location.Bottom ? 1 : -1;
			const directionX = start.x < end.x ? 1 : -1;
		if(parentNodesHorizontalOverlap({"x": start.x, "parentWidth": startAnchor.nodeSize.width}, {"x": end.x, "parentWidth": endAnchor.nodeSize.width})){
			const p1 = {
				x: start.x,
				y: start.y + lineMargin * directionY
			};
			const p2 = {
				x: start.x - (Math.max(endAnchor.nodeSize.width, startAnchor.nodeSize.width) / 2 + lineMargin) * directionX,
				y: p1.y
			};
			const p3 = {
				x: p2.x,
				y: end.y - lineMargin * directionY
			};
			const p4 = {
				x: end.x,
				y: p3.y
			};
			return [pStart, p1, p2, p3, p4, pEnd];
		}
		else{
			const p1 = {
				x: start.x,
				y: start.y + lineMargin * directionY
			};
			const p2 = {
				x: start.x + ((end.x - endAnchor.nodeSize.width * directionX / 2) - start.x + startAnchor.nodeSize.width * directionX / 2) / 2,
				y: start.y + lineMargin * directionY
			};
			const p3 = {
				x: p2.x,
				y: end.y - lineMargin * directionY
			};
			const p4 = {
				x: end.x,
				y: end.y - lineMargin * directionY
			};
			return [pStart, p1, p2, p3, p4, pEnd];
		}
	}

	const p1 = {
		x: start.x,
		y: start.y + (end.y - start.y) / 2
	};
	const p2 = {
		x: end.x,
		y: end.y - (end.y - start.y) / 2
	};
	return [pStart, p1, p2, pEnd];
}