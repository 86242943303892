import { Component, Input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@Component({
	selector: 'app-loading-spinner',
	standalone: true,
	imports: [NgIf, AsyncPipe, NgClass],
	templateUrl: './loading-spinner.component.html',
	styleUrl: './loading-spinner.component.css',
})
export class LoadingSpinnerComponent {
	@Input() isLarge?: boolean = false;
}
