<main class="form-main-wrapper">
	<div class="form-container flex-col">
		<section class="form-heading-container flex-col">
			<img class="form-logo" src="../../../assets/logo/boswell.png" alt="Boswells logo" />
			<div class="login-form-container flex-col">
				@switch (emailVerificationResult) {
					@case (emailVerificationResults.Processing) {
						<h1 class="login-headings">Verifying your email...</h1>
					}
					@case (emailVerificationResults.Success) {
						<h1 class="form-headings margin-bottom">Success!</h1>
						<a class="margin-top" routerLink="/login">
							<app-button class="margin-top shadow" label="Go to login" type="submit"></app-button>
						</a>
					}
					@case (emailVerificationResults.Failure) {
						<h1 class="form-headings">Could not verify your email</h1>
						<p>Please try and resend the email.</p>
						<app-button
							(buttonClick)="onResend()"
							label="Resend email"
							class="shadow"
							[showLoadingSpinner]="resendingEmail"
						></app-button>
						@if (hasTriedResending && resendSuccess) {
							<p>Successfully sent a new verification email.</p>
						}
						@if (hasTriedResending && !resendSuccess && !resendingEmail) {
							<p class="error-msg">Could not resend the email.</p>
						}
					}
				}
			</div>
		</section>
	</div>
</main>
