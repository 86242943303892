export const CustomEventName = {
	ActionMenuSelection: "actionMenuSelection",
	CreateNode: "createNode",
	SetRelations: "setRelations",
	AnchorClicked: "anchorClicked",
	DeleteNode: "deleteNode",
	DeleteRelation: "deleteRelation",
	RemoveAnchoredRelation: "removeAnchoredRelation",
	ResetZoom: "resetZoom",
	NodeClicked: "nodeClicked",
	TryDeleteNode: "tryDeleteNode"
};