import { Component } from '@angular/core';
import { ToastrMessage, ToastrService } from '../../core/services/toastr.service';
import { interval, Subject, takeUntil, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-toastr',
	standalone: true,
	imports: [],
	templateUrl: './toastr.component.html',
	styleUrl: './toastr.component.css',
})
export class ToastrComponent {
	private readonly defaultMessageDurationMs = 10000;
	private readonly progressUpdateIntervalMs = 100;
	private readonly maxProgressPercentage = 100;
	private readonly destroy$ = new Subject<void>();

	public toastrMessages: ToastrMessage[] = [];

	constructor(private toastrService: ToastrService) {
		this.toastrService.toastrState$
			.pipe(takeUntilDestroyed())
			.subscribe((message: ToastrMessage) => {
				this.addToastr(message);
			});
	}

	private addToastr(message: ToastrMessage): void {
		message.progress = 100;
		this.toastrMessages.unshift(message);

		const duration = message.duration || this.defaultMessageDurationMs;
		const timeInterval = this.progressUpdateIntervalMs;
		const totalIntervals = duration / timeInterval;
		let currentInterval = 0;

		interval(timeInterval)
			.pipe(takeUntil(timer(duration)), takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					currentInterval++;
					message.progress =
						this.maxProgressPercentage -
						(currentInterval / totalIntervals) * this.maxProgressPercentage;
				},
				complete: () => {
					this.removeToastr(message);
				},
			});
	}

	private removeToastr(message: ToastrMessage): void {
		this.toastrMessages = this.toastrMessages.filter((msg) => msg !== message);
	}

	public closeToastr(message: ToastrMessage): void {
		this.removeToastr(message);
	}
}
