<button
	[ngClass]="[
		'default-btn',
		'width-full',
		'border-radius',
		'capitalize',
		extraClass,
		smallButton ? 'small-btn' : 'btn',
		disabled ? 'default-btn' : ''
	]"
	[disabled]="disabled"
	(click)="onClick()"
>
	@if (showLoadingSpinner) {
		<app-loading-spinner></app-loading-spinner>
	}
	{{ label }}
</button>
