import { Component, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { DotMenuComponent } from '../../../shared/dot-menu/dot-menu.component';
import { DotMenuOptionComponent } from '../../../shared/dot-menu/dot-menu-option/dot-menu-option.component';
import { ContextView } from '../../../core/models/contexts';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ConfirmCancelButtonGroupComponent } from '../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { ContextItemService } from './context-item.service';

@Component({
	selector: 'app-context-item',
	standalone: true,
	imports: [
		FormsModule,
		CommonModule,
		LoadingSpinnerComponent,
		InputComponent,
		DotMenuComponent,
		DotMenuOptionComponent,
		RouterLink,
		ConfirmCancelButtonGroupComponent,
	],
	templateUrl: './context-item.component.html',
	styleUrl: './context-item.component.css',
})
export class ContextItemComponent {
	@Input() context!: ContextView;
	@Input() currentContext!: ContextView;
	showDotMenu: boolean = false;
	name: string = '';

	constructor(private contextItemService: ContextItemService) {}

	showRenameDrawingForm(context: ContextView): void {
		context.isEditing = true;
		context.isLoading = false;
	}

	get isActive(): boolean {
		return this.currentContext.id === this.context.id;
	}

	toggleExpansion(): void {
		this.contextItemService.onToggleExpansion(this.context);
	}

	onDeleteDrawing(): void {
		this.contextItemService.onDeleteDrawing(this.context);
	}

	onRenameDrawing(): void {
		this.context.name = this.name;
		this.contextItemService.onRenameDrawing(this.context);
	}

	onCancelRenaming(context: ContextView): void {
		context.isEditing = false;
		this.name = '';
	}
}
