import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/users';
import { SharedContext } from '../models/sharing/SharedContext';

@Injectable({
	providedIn: 'root',
})
export class SharingService {
	private baseUrl: string = `${environment.apiUrl}sharing/api/sharing`;

	constructor(private httpClient: HttpClient) {}

	createSharedContext(contextId: number): Observable<SharedContext> {
		return this.httpClient.post<SharedContext>(`${this.baseUrl}/shared-context`, {
			contextId,
		});
	}

	getUsersWithAccessToContext(contextId: number): Observable<User[]> {
		return this.httpClient.get<User[]>(`${this.baseUrl}/contexts/${contextId}/users`);
	}
}
