import { EventEmitter, Injectable } from '@angular/core';
import { ContextView } from '../../../core/models/contexts';

@Injectable({
	providedIn: 'root',
})
export class ContextItemService {
	toggleExpansion = new EventEmitter<ContextView>();
	deleteDrawing = new EventEmitter<ContextView>();
	renameDrawing = new EventEmitter<ContextView>();
	createSharedDrawing = new EventEmitter<ContextView>();
	deleteReadOnlyDrawing = new EventEmitter<ContextView>();

	onToggleExpansion(context: ContextView): void {
		this.toggleExpansion.emit(context);
	}

	onDeleteDrawing(context: ContextView): void {
		this.deleteDrawing.emit(context);
	}

	onRenameDrawing(context: ContextView): void {
		this.renameDrawing.emit(context);
	}

	onCreateSharedDrawing(context: ContextView): void {
		this.createSharedDrawing.emit(context);
	}

	onDeleteReadOnlyDrawing(context: ContextView): void {
		this.deleteReadOnlyDrawing.emit(context);
	}
}
