<label
	class="font-smaller"
	[ngClass]="{ 'attribute-value-heading': labelText.trim().length > 0 }"
	[for]="name"
	>{{ labelText }}</label
>
<input
	class="input-field width-full border-radius"
	[class.no-border]="!border"
	[class]="class"
	[type]="type"
	[id]="id"
	[(ngModel)]="model"
	[placeholder]="placeholder"
	[name]="name"
	(input)="handleInput($event)"
	(keydown.enter)="onEnter()"
	(blur)="onBlur()"
	[attr.maxLength]="maxLength"
/>
<p *ngIf="isLengthValid" class="error-message">
	You've reached the maximum length of {{ maxLength }} characters.
</p>
