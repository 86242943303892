import { Component } from '@angular/core';
import { ProcessStatus, RegisterUser } from '../../core/models/users';
import { UserService } from '../../core/services/user.service';
import { ToastrService } from '../../core/services/toastr.service';
import { RegisterFormComponent } from '../../shared/register-form/register-form.component';

@Component({
	selector: 'app-register-guest',
	standalone: true,
	imports: [RegisterFormComponent],
	templateUrl: './register-guest.component.html',
})
export class RegisterGuestComponent {
	registerResult: ProcessStatus = ProcessStatus.Default;
	registerResults = ProcessStatus;

	user: RegisterUser = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		acceptedTerms: false,
	};

	registering: boolean = false;

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
	) {}

	onRegisterGuest = (user: RegisterUser): void => {
		this.registering = true;
		this.userService.convertGuestToUser(user).subscribe({
			next: () => {
				this.registerResult = this.registerResults.Success;
				this.registering = false;
				this.userService.logOut();
			},
			error: () => {
				this.registerResult = this.registerResults.Failure;
				this.registering = false;
				this.toastrService.showError({});
			},
		});
	};
}
