import { CommandName } from "../shared/constants/command-name";
import { containerId } from "../shared/constants/id";


export class NodeClickedCommand
{
    static commandName = CommandName.NodeClickedCommand;

    static send(detail) {
        document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.NodeClickedCommand, { detail: detail, bubbles: true }));
    }
}