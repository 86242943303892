import { Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../core/services/context.service';
import { CommonModule, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { FormsModule } from '@angular/forms';
import { ContextView } from '../../core/models/contexts';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { ContextItemComponent } from './context-item/context-item.component';
import { ContextItemService } from './context-item/context-item.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NodeType } from '../../core/models/deluxedraw/node-type';
import { ModalService } from '../../core/services/modal.service';
import { ModalMode } from '../../shared/modal/modal.component';
import { NodeService } from '../../core/services/node-service';
import { RenameNode } from '../../core/models/context-node/rename-node';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-side-bar',
	standalone: true,
	templateUrl: './side-bar.component.html',
	styleUrl: './side-bar.component.css',
	imports: [
		CommonModule,
		ButtonComponent,
		NgIf,
		FormsModule,
		LoadingSpinnerComponent,
		ContextItemComponent,
		InputComponent,
	],
})
export class SideBarComponent implements OnInit {
	@Input() contexts: ContextView[] = [];
	@Input() currentContext!: ContextView;
	addDrawingFormVisible: boolean = false;
	newDrawingName: string = '';
	creatingNewDrawing: boolean = false;
	@Input() loadingContexts: boolean = false;

	constructor(
		private contextService: ContextService,
		private contextItemService: ContextItemService,
		private modalService: ModalService,
		private nodeService: NodeService,
		private toastrService: ToastrService,
		private router: Router,
	) {
		this.contextItemService.toggleExpansion.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onToggleExpansion(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.contextItemService.deleteDrawing.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onDeleteDrawing(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.contextItemService.renameDrawing.pipe(takeUntilDestroyed()).subscribe({
			next: (context: ContextView) => {
				this.onRenameDrawing(context);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	ngOnInit(): void {
		this.nodeService.updatedNodeName$().subscribe({
			next: (updatedContext) => {
				const matchingChild = this.findContextById(this.contexts, updatedContext.id);
				if (matchingChild) {
					matchingChild.name = updatedContext.name;
				}
			},
			error: () => {
				this.toastrService.showError({});
			},
		});
	}

	showAddDrawingForm(): void {
		this.addDrawingFormVisible = true;
	}

	cancelNewDrawing(): void {
		this.addDrawingFormVisible = false;
	}

	addNewDrawing(): void {
		if (this.newDrawingName.length > 0) {
			this.creatingNewDrawing = true;
			this.contextService.create(this.newDrawingName, '').subscribe({
				next: (context) => {
					this.contexts.push({ ...context });
					void this.router.navigate(['/', context.id]);
					this.creatingNewDrawing = false;
					this.newDrawingName = '';
				},
				error: () => {
					this.creatingNewDrawing = false;
					this.toastrService.showError({});
				},
			});
			this.addDrawingFormVisible = false;
		} else {
			this.addDrawingFormVisible = false;
		}
	}

	onRenameDrawing(context: ContextView): void {
		context.isLoading = true;
		context.isEditing = false;
		this.contextService.update(context.id, context.name, context.description).subscribe({
			next: (updatedContext) => {
				const matchingChild = this.findContextById(this.contexts, updatedContext.id);
				if (matchingChild) {
					this.nodeService.renameNode({
						name: updatedContext.name,
						id: updatedContext.id,
						nodeType: NodeType.Context,
					} as RenameNode);
				}
				context.isLoading = false;
			},
			error: () => {
				context.isLoading = false;
				this.toastrService.showError({});
			},
		});
	}

	onDeleteDrawing(context: ContextView): void {
		context.isLoading = true;
		this.contextService.delete(context.id).subscribe({
			next: () => {
				this.contexts = this.contexts.filter((ctx) => ctx.id != context.id);

				if (+localStorage.getItem('contextId')! === context.id) {
					localStorage.removeItem('contextId');
					void this.router.navigate(['/']);
				}
			},
			error: (httpErrorResponse: HttpErrorResponse) => {
				if (httpErrorResponse.status === 409) {
					this.modalService
						.open({
							title: 'Drawing could not be deleted',
							content: httpErrorResponse.error.detail,
							mode: ModalMode.Info,
						})
						.subscribe({
							next: (_) => {
								context.isLoading = false;
							},
						});
				} else console.error(httpErrorResponse.error);
			},
		});
	}

	onToggleExpansion(context: ContextView): void {
		context.expanded = !context.expanded;
	}

	findContextById(contexts: ContextView[], id: number): ContextView | undefined {
		for (const context of contexts) {
			if (context.id === id) return context;
			if (context.children) {
				const found = this.findContextById(context.children, id);
				if (found) return found;
			}
		}
		return undefined;
	}
}
