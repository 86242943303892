import { NodeRepository } from "../infrastructure/node-repository.js";
import { RelationRepository } from "../infrastructure/relation-repository.js";
import { ApplicationState } from "../shared/constants/application-state.js";

export class UpdateActionMenuSelectionBehavior
{
	constructor() {
		this.handle(ApplicationState.Edit);
	}

	handle(applicationState) {
		const nodes = NodeRepository.getAll();
		const relations = RelationRepository.getAll();
		
		nodes.forEach(node => node.updateApplicationState(applicationState));
		relations.forEach(relation => relation.updateApplicationState(applicationState));
	}
}