<section class="flex-col page-container">
	<section>
		<h1 class="heading">Settings</h1>
	</section>
	<section class="width-full">
		@if (isEditing) {
			<form
				(ngSubmit)="onSave(settingsForm)"
				#settingsForm="ngForm"
				class="login-form-container flex-col"
			>
				<app-input
					labelText="Firstname"
					type="text"
					name="firstname"
					[(ngModel)]="user.firstName!"
					[model]="user.firstName"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<app-input
					labelText="Lastname"
					type="text"
					name="lastname"
					[(ngModel)]="user.lastName"
					[model]="user.lastName"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<app-input
					labelText="Street"
					type="text"
					name="street"
					[(ngModel)]="user.street!"
					[model]="user.street!"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<app-input
					labelText="Postal Code"
					type="number"
					name="postalCode"
					[(ngModel)]="user.postalCode!"
					[model]="user.postalCode!"
					(enterPressed)="onSave(settingsForm)"
					[maxLength]="15"
				></app-input>
				<app-input
					labelText="City"
					type="text"
					name="city"
					[(ngModel)]="user.city!"
					[model]="user.city!"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<app-input
					labelText="Country"
					type="text"
					name="contry"
					[(ngModel)]="user.country!"
					[model]="user.country!"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<app-input
					labelText="Phone Number"
					type="tel"
					name="phoneNumber"
					pattern="^(\+|-)?[0-9]+([ -]?[0-9]+)*$"
					[(ngModel)]="user.phoneNumber!"
					[model]="user.phoneNumber!"
					(enterPressed)="onSave(settingsForm)"
					[maxLength]="15"
				></app-input>
				<app-input
					labelText="Company"
					type="text"
					name="company"
					[(ngModel)]="user.company!"
					[model]="user.company!"
					(enterPressed)="onSave(settingsForm)"
				></app-input>
				<div *ngIf="invalidFormMessage" class="error-msg">
					{{ invalidFormMessage }}
				</div>
				<section class="flex-row gap-small">
					<app-button
						class="option-btn margin-top"
						type="submit"
						(buttonClick)="onSave(settingsForm)"
						label="Save"
					></app-button>
					<app-button
						class="option-btn margin-top"
						(buttonClick)="onCancelEdit(settingsForm)"
						label="Cancel"
						extraClass="cancel"
					></app-button>
				</section>
			</form>
		} @else {
			<article class="content-container flex-col">
				<section class="flex-row content-row">
					<p>First name:</p>
					<p class="content-name">{{ user.firstName }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Last name:</p>
					<p class="content-name">{{ user.lastName }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Street:</p>
					<p class="content-name">{{ user.street }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Postal Code:</p>
					<p class="content-name">{{ user.postalCode }}</p>
				</section>
				<section class="flex-row content-row">
					<p>City:</p>
					<p class="content-name">{{ user.city }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Country:</p>
					<p class="content-name">{{ user.country }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Phone number:</p>
					<p class="content-name">{{ user.phoneNumber }}</p>
				</section>
				<section class="flex-row content-row">
					<p>Company:</p>
					<p class="content-name">{{ user.company }}</p>
				</section>
				<app-button (buttonClick)="onEdit()" label="Edit user information"></app-button>
				@if(!showPasswordInput){
				<button class="link-button" (click)="onRemoveAccount()">Remove account</button>
				}@else{
					<div class="flex-row content-row">
						<app-input
						  labelText="Enter your password"
						  type="password"
						  [(ngModel)]="password"
						  name="password"
						  (enterPressed)="verifyPassword()"
						></app-input>
					</div>
					<div class="flex-row form-row">
						<app-button (buttonClick)="verifyPassword()" [disabled]="!password" class="font-smaller" label="Remove"></app-button>
						<app-button (buttonClick)="onRemoveAccount()" extraClass="cancel" class="font-smaller" label="Cancel"></app-button>
					</div>
				}
			</article>
		}
	</section>
</section>
