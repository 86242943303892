import { namespace } from "../../shared/constants/svg.js";
import { Location } from "../../shared/constants/location.js";
import { getPoints as opposingVerticalPath } from "../line-configuration/opposing-vertical.js";
import { getPoints as opposingHorizontalPath } from "../line-configuration/opposing-horizontal.js";
import { getPoints as verticalToHorizontalPath } from "../line-configuration/vertical-to-horizontal.js";
import { getPoints as topToTopPath } from "../line-configuration/top-to-top.js";
import { getPoints as bottomToBottomPath } from "../line-configuration/bottom-to-bottom.js";
import { getPoints as rightToRightPath } from "../line-configuration/right-to-right.js";
import { getPoints as leftToLeftPath } from "../line-configuration/left-to-left.js";
import { CustomEventName } from "../../shared/constants/custom-events.js";
import { Symbol } from "../../shared/static/symbols.js";
import { containerId, svgContainerId } from "../../shared/constants/id.js";
import { RelationState } from "../../shared/constants/relation-state.js";
import { ApplicationState } from "../../shared/constants/application-state.js";

export class Relation{
    applicationState;
	guid;
    startAnchor;
	endAnchor;
	id;
    symbol1;
    symbol2;
    relationState;
    node1Id;
    node2Id;
    #color = "#888888";
    #activeColor = "rgb(40, 80, 140)";
    #svg;
    #hitbox;
    #container;
    #lineMargin = 20;
    #hitboxSize = 20;
	#rootContainer;
    #hoveredClasses;

	constructor(id, startAnchor, endAnchor, symbol1, symbol2, relationState) {
		this.#container = document.getElementById(svgContainerId);
		this.relationState = relationState;
		this.id = id;
        this.startAnchor = startAnchor;
		this.endAnchor = endAnchor;
        this.symbol1 = symbol1;
        this.symbol2 = symbol2;
        this.node1Id = startAnchor.nodeId;
        this.#hoveredClasses = [];
		this.#rootContainer = document.getElementById(containerId);
		this.guid = crypto.randomUUID();
		this.#createSvg(this.startAnchor, this.endAnchor);
    }

    highlight() {
        this.#svg.setAttribute("stroke", this.#activeColor);
        this.#svg.setAttribute("filter", "drop-shadow(0 0 2px rgb(40, 80, 140))");
        this.#svg.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)}-active)`);
        this.#svg.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)}-active)`);
    }

    removeHighlight() {
        this.#svg.setAttribute("stroke", this.#color);
        this.#svg.setAttribute("filter", "none");
        this.#svg.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)})`);
        this.#svg.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)})`);
    }

    update(mousePosition){
        const points = this.#getLinePoints(this.startAnchor, this.endAnchor ?? mousePosition);
        const strPoints = this.#linePointsToString(points)
        this.#svg.setAttribute("points", strPoints);
        if (this.#hitbox)
            this.#hitbox.setAttribute("points", strPoints);
    }

    updateApplicationState(applicationState) {
        this.applicationState = applicationState;
    }

    addEndAnchor(endAnchor) {
        this.relationState = RelationState.Idle;
        this.node2Id = endAnchor.nodeId;
        this.endAnchor = endAnchor;
        this.#createHitbox();
    }

    clear() {
        this.startAnchor.removeConnectedRelation(this);
        this.endAnchor?.removeConnectedRelation(this);

        this.#container.removeChild(this.#svg);
        if (this.#hitbox)
            this.#container.removeChild(this.#hitbox);
	}

	setId(id) {
		this.id = id;
	}

    #getPolylineFromAnchors(startAnchor, endAnchor)
    {
        const points = this.#getLinePoints(startAnchor, endAnchor);
        return this.#linePointsToString(points);
    }

	#createSvg(startAnchor, endAnchor) {
        const strPoints = this.#getPolylineFromAnchors(startAnchor, endAnchor);

        if (endAnchor)
            this.#createHitbox();

        this.#svg = document.createElementNS(namespace, 'polyline');
        this.#svg.style.pointerEvents = "none";
        this.#svg.setAttribute("class", "svg_" + this.guid);
        this.#svg.setAttribute("points", strPoints);
        this.#svg.setAttribute("fill", "none");
        this.#container.appendChild(this.#svg, this.#container.firstChild);
        
        if (this.relationState !== "Idle"){
            this.#svg.setAttribute("stroke", this.#activeColor);
            this.#svg.setAttribute("filter", "drop-shadow(0 0 2px rgb(40, 80, 140))");
            this.#svg.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)}-active)`);
            this.#svg.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)}-active)`);
        } else {
            this.#svg.setAttribute("stroke", this.#color);
            this.#svg.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)})`);
            this.#svg.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)}`);
        }
    }
    
    #createHitbox(){
        const strPoints = this.#getPolylineFromAnchors(this.startAnchor, this.endAnchor);
        
        this.#hitbox = document.createElementNS(namespace, 'polyline');
        this.#hitbox.style.pointerEvents = "stroke";
        this.#hitbox.setAttribute("class", "hitbox_" + this.guid)
        this.#hitbox.setAttribute("points", strPoints);
        this.#hitbox.setAttribute("fill", "none");
        this.#hitbox.setAttribute("stroke", this.#color);
        this.#hitbox.setAttribute("stroke-width", this.#hitboxSize);
        this.#hitbox.setAttribute("opacity", "0");
        this.#container.insertBefore(this.#hitbox, this.#container.firstChild);

        this.#hitbox.addEventListener("mouseenter", (e) => {
            this.highlight();
            const elements = document.elementsFromPoint(e.clientX, e.clientY);
            const activeElements = elements.filter((element) => element.localName === "polyline");

            activeElements.forEach((hitbox) => {
                const hitboxClass = hitbox.className.baseVal;
                const svgClass = hitboxClass.replace("hitbox_", "svg_");
                this.#hoveredClasses.push(svgClass);
                const svgElements = document.getElementsByClassName(svgClass);
                Array.from(svgElements).forEach((element) => {
                    element.setAttribute("stroke", this.#activeColor);
                    element.setAttribute("filter", "drop-shadow(0 0 2px rgb(40, 80, 140))");
                    element.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)}-active)`);
                    element.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)}-active)`);
                })
            });
        });

        this.#hitbox.addEventListener("mouseleave", () => {
            this.removeHighlight();

            this.#hoveredClasses.forEach((className) => {
                const svgElements = document.getElementsByClassName(className);
                Array.from(svgElements).forEach((element) => {
                    element.setAttribute("stroke", this.#color);
                    element.setAttribute("filter", "none");
                    element.setAttribute("marker-start", `url(${Symbol.ToCssId(this.symbol1)})`);
                    element.setAttribute("marker-end", `url(${Symbol.ToCssId(this.symbol2)})`);
                })
            });
        });

        this.#hitbox.addEventListener("click", () => {
            if (this.applicationState !== ApplicationState.Delete) return;
            
            this.#rootContainer.dispatchEvent(new CustomEvent(CustomEventName.DeleteRelation, { detail: this }));
            this.clear();
        });
    }

	#getLinePoints(startAnchor, endAnchor) {
        const start = startAnchor.getAbsolutePosition();
        const end = endAnchor ? endAnchor.getAbsolutePosition() : {x: start.x, y: start.y};

        if (!endAnchor){
            return [{x: start.x, y: start.y}, {x: end.x, y: end.y}];
        }

        if (this.#opposingVertical(startAnchor, endAnchor)){
            return opposingVerticalPath(startAnchor, endAnchor, this.#lineMargin);
        }

        if (this.#opposingHorizontal(startAnchor, endAnchor)){
            return opposingHorizontalPath(startAnchor, endAnchor, this.#lineMargin);
        }

        if (this.#horizontalToVertical(startAnchor, endAnchor)){
            return verticalToHorizontalPath(endAnchor, startAnchor, this.#lineMargin, true);
        }

        if (this.#verticalToHorizontal(startAnchor, endAnchor)){
            return verticalToHorizontalPath(startAnchor, endAnchor, this.#lineMargin, false);
        }

        if (this.#topToTop(startAnchor, endAnchor)){
            return topToTopPath(startAnchor, endAnchor, this.#lineMargin);
        }

        if (this.#bottomToBottom(startAnchor, startAnchor)){
            return bottomToBottomPath(startAnchor, endAnchor, this.#lineMargin);
        }

        if (this.#rightToRight(startAnchor, endAnchor)){
            return rightToRightPath(startAnchor, endAnchor, this.#lineMargin);
        }

        if (this.#leftToLeft(startAnchor, endAnchor)){
            return leftToLeftPath(startAnchor, endAnchor, this.#lineMargin);
        }

        return [{x: start.x, y: start.y}, {x: end.x, y: end.y}];
    }

    #opposingVertical(startAnchor, endAnchor){
        return startAnchor.location === Location.Top && endAnchor.location === Location.Bottom
            || startAnchor.location === Location.Bottom && endAnchor.location === Location.Top;
    }

    #opposingHorizontal(startAnchor, endAnchor){
        return startAnchor.location === Location.Right && endAnchor.location === Location.Left
            || startAnchor.location === Location.Left  && endAnchor.location === Location.Right;
    }

    #horizontalToVertical(startAnchor, endAnchor){
        return (startAnchor.location === Location.Right || startAnchor.location === Location.Left)
            && (endAnchor.location === Location.Top || endAnchor.location === Location.Bottom);
    }

    #verticalToHorizontal(startAnchor, endAnchor){
        return (startAnchor.location === Location.Top  || startAnchor.location === Location.Bottom)
            && (endAnchor.location === Location.Left || endAnchor.location === Location.Right);
    }

    #topToTop(startAnchor, endAnchor){
        return startAnchor.location === Location.Top && endAnchor.location === Location.Top;
    }

    #bottomToBottom(startAnchor, endAnchor){
        return startAnchor.location === Location.Bottom && endAnchor.location === Location.Bottom;
    }

    #rightToRight(startAnchor, endAnchor){
        return startAnchor.location === Location.Right && endAnchor.location === Location.Right;
    }

    #leftToLeft(startAnchor, endAnchor){
        return startAnchor.location === Location.Left && endAnchor.location === Location.Left;
    }

    #linePointsToString(points){
        let strPoints = "";
        for (const point of points){
            strPoints += `${point.x},${point.y} `;
        }
        return strPoints.trim();
    }
}
