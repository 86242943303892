import { AfterViewInit, Component } from '@angular/core';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ModalService } from '../../core/services/modal.service';
import { ModalMode } from '../modal/modal.component';
import { RouterLink } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { User } from '../../core/models/users';

@Component({
	selector: 'app-top-bar',
	standalone: true,
	imports: [UserProfileComponent, RouterLink],
	templateUrl: './top-bar.component.html',
	styleUrl: './top-bar.component.css',
})
export class TopBarComponent implements AfterViewInit {
	isBetaDisclaimerOpen: boolean = false;
	user: User;

	constructor(
		private modalService: ModalService,
		private userService: UserService,
	) {
		this.user = this.userService.getCurrentUser()!.user;
	}

	ngAfterViewInit(): void {
		const userHasAgreedToBeta = localStorage.getItem('userHasAgreedToBeta');
		const modalOpen = localStorage.getItem('modalOpen');

		if (!modalOpen) {
			if (!userHasAgreedToBeta || userHasAgreedToBeta !== this.user.email) {
				localStorage.setItem('modalOpen', 'true');
				this.openBetaDisclaimer();
			}
		}
	}

	openBetaDisclaimer(): void {
		this.modalService
			.open({
				title: 'Disclaimer',
				content:
					'This application is a beta version and may contain bugs or other issues. We take no responsibility for any loss or damage related to saved data, usage, or functionality. We do our best to preserve data, but cannot guarantee its availability. Users are encouraged to back up important information before using the application.',
				mode: ModalMode.Info,
				disableBackdrop: true,
				buttonLabel: 'Agree',
			})
			.subscribe((userAgree) => {
				if (userAgree) {
					localStorage.setItem('userHasAgreedToBeta', this.user.email);
					localStorage.removeItem('modalOpen');
				}
			});
	}
}
