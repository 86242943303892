export enum ToolboxAction {
	Box = 'Box',

	Square = 'Square',
	Circle = 'Circle',
	Triangle = 'Triangle',
	Diamond = 'Diamond',
	Octagon = 'Octagon',

	Connect = 'Connect',
	ConnectBoth = 'ConnectBoth',

	Navigate = 'Navigate',
	Delete = 'Delete',
	Edit = 'Edit',
	MarkerArea = 'MarkerArea',
	ResetZoom = 'ResetZoom',
	Focusbox = 'Focusbox'
}
