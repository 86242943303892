import { namespace } from "../../shared/constants/svg.js";

export class RelationSymbol {
    static color = "#888888"
	static activeColor = "rgb(40, 80, 140)";

    static create(svgContainer) {
        this.renderSymbolMarkers(svgContainer);
    }    

    static renderSymbolMarkers(svgContainer){
        const defs = document.createElementNS(namespace, 'defs');
        const markers = [
            ...this.createArrowMarkers()
        ]
        markers.forEach(element => defs.appendChild(element));
        svgContainer.appendChild(defs)
    }

    static createArrowMarkers() {
        const marker = document.createElementNS(namespace, 'marker');
        marker.setAttribute("id", "dd-arrow");
        marker.setAttribute("markerWidth", "10");
        marker.setAttribute("markerHeight", "10");
        marker.setAttribute("viewBox", "0 0 10 10");
        marker.setAttribute("refX", "9");
        marker.setAttribute("refY", "5");
        marker.setAttribute("orient", "auto-start-reverse");

        const arrow = document.createElementNS(namespace, "path");
        arrow.setAttribute("d", "M 0 0 L 10 5 L 0 10 z");
        arrow.setAttribute("fill", this.color);

        marker.appendChild(arrow);

		const activeMarker = marker.cloneNode();
		activeMarker.setAttribute("id", "dd-arrow-active");

		const activeArrow = arrow.cloneNode();
		activeArrow.setAttribute("fill", this.activeColor);

		activeMarker.appendChild(activeArrow);

		return [marker, activeMarker];
    }
}