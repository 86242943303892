import { Component } from '@angular/core';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { FormsModule, NgForm } from '@angular/forms';
import { UserService } from '../../../core/services/user.service';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from '../../../core/services/toastr.service';
import { User } from '../../../core/models/users';

@Component({
	selector: 'app-password',
	standalone: true,
	imports: [InputComponent, ButtonComponent, FormsModule, RouterLink],
	templateUrl: './password.component.html',
	styleUrls: [
		'./password.component.css',
		'../settings-form-styles.component.css',
		'../../../../form-styles.css',
	],
})
export class PasswordComponent {
	user: User;
	savingNewPassword: boolean = false;
	invalidFormMessage: string = '';
	currentPassword: string = '';
	newPassword: string = '';
	confirmNewPassword: string = '';
	passwordsMatch: boolean = true;
	passwordChangedSuccessfully: string = '';
	passwordChangedFailed: string = '';
	private _debounceTimer: ReturnType<typeof setTimeout> | undefined = undefined;

	constructor(
		private userService: UserService,
		private toastrService: ToastrService,
		private router: Router,
	) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser === undefined) throw new Error(`Couldn't get current user`);
		this.user = currentUser.user;
	}

	onChangePassword(changePasswordForm: NgForm): void {
		if (changePasswordForm.valid && this.passwordsMatch) {
			this.passwordChangedSuccessfully = '';
			this.passwordChangedFailed = '';
			this.savingNewPassword = true;
			this.userService
				.updatePassword(this.currentPassword, this.newPassword, this.confirmNewPassword)
				.subscribe({
					next: () => {
						this.savingNewPassword = false;
						this.passwordChangedSuccessfully = "You've successfully changed your password";
					},
					error: () => {
						this.savingNewPassword = false;
						this.passwordChangedFailed = 'Could not update your password';
						this.toastrService.showError({});
					},
				});
		} else {
			this.invalidFormMessage = 'Please fill out the form correctly';
		}
	}

	onCancel(): void {
		void this.router.navigateByUrl('settings/overview');
	}

	onConfirmPasswordInput(): void {
		if (this._debounceTimer) clearTimeout(this._debounceTimer);

		this._debounceTimer = setTimeout(() => {
			this.passwordsMatch = this.newPassword === this.confirmNewPassword;
		}, 500);
	}
}
