import { NodeRepository } from "../infrastructure/node-repository.js";
import { getTransformedMousePoint } from "../deluxe-math.js";
import { namespace } from "../shared/constants/svg.js";
import { ActionMenuSelection } from "../shared/constants/action-menu-selection.js";

export class CreateMarkerAreaBehavior
{
    #svgContainer;
    #markerArea = null;
	#markedNodes = [];
	#startX;
	#startY;
	#endX;
	#endY;
	#markerStartX;
	#markerEndX;
	#markerStartY;
	#markerEndY;
    #createMarkerAreaMouseDown;
    #createMarkerAreaMouseMove;
    #createMarkerAreaMouseUp;

    constructor(svgContainer)
    {
        this.#svgContainer = svgContainer;
        this.#createMarkerAreaMouseDown = (e) => {
            this.#unmarkNodes();
            this.#removeActiveMarkerArea();
    
            this.#markedNodes = [];
            
            this.#startX = this.#markerEndX = this.#markerStartX = getTransformedMousePoint(this.#svgContainer, e).x;
            this.#startY = this.#markerEndY = this.#markerStartY = getTransformedMousePoint(this.#svgContainer, e).y;
    
            this.#svgContainer.addEventListener("mousemove", this.#createMarkerAreaMouseMove);
            this.#svgContainer.addEventListener("mouseup", this.#createMarkerAreaMouseUp);
        };

        this.#createMarkerAreaMouseMove = (e) => {
            if(this.#markerArea)
                this.#markerArea.remove();
            
            this.#endX = getTransformedMousePoint(this.#svgContainer, e).x;
            this.#endY = getTransformedMousePoint(this.#svgContainer, e).y;
            this.#createMarkerArea();
        };

        this.#createMarkerAreaMouseUp = () => {
            this.#svgContainer.removeEventListener("mousemove", this.#createMarkerAreaMouseMove);
    
            for (let node of NodeRepository.getAll()) {
                if (this.#isWithinMarkerArea(node)) {
                    this.#markedNodes.push(node);
                    this.#markedNodes.forEach(node => node.highlight());
                }
            }
            this.#registerListenersForMarkerArea();
        };
    }

    handle(actionMenuSelection) {
        if (actionMenuSelection !== ActionMenuSelection.MarkerArea) {
            this.#svgContainer.removeEventListener("mousedown", this.#createMarkerAreaMouseDown);
            this.#svgContainer.removeEventListener("mouseup", this.#createMarkerAreaMouseUp);
            this.#removeActiveMarkerArea();	
            this.#unmarkNodes();
            window.panZoom.resume();	
            return;
        }
        this.#selectElements();
    }

    #selectElements() {
        window.panZoom.pause();
        this.#svgContainer.addEventListener("mousedown", this.#createMarkerAreaMouseDown);
	}

    #isWithinMarkerArea(node) {
        const nodePosition = node.getNodeCenterPosition();
        return (nodePosition.x > this.#markerStartX && nodePosition.y > this.#markerStartY) && 
        (nodePosition.x < this.#markerStartX + this.#markerEndX && 
        nodePosition.y < this.#markerStartY + this.#markerEndY);
    }

    
	#registerListenersForMarkerArea() {
		let mousePosition;
		let offsetValueX;
		let offsetValueY;

		this.#markerArea.addEventListener("mousedown", (e) => {
			if (this.#markedNodes.length > 0) {
				e.stopPropagation();
				mousePosition = getTransformedMousePoint(this.#svgContainer, e);
				offsetValueX = mousePosition.x - parseInt(this.#markerArea.getAttribute("x"));
				offsetValueY = mousePosition.y - parseInt(this.#markerArea.getAttribute("y"));
	
				this.#markedNodes.forEach(node => node.setMouseStartPosition(e));
				this.#svgContainer.addEventListener("mousemove", moveMarkerArea);
			} 
            else
				this.#markerArea.remove();
		});

		const moveMarkerArea = (e) => {
			mousePosition = getTransformedMousePoint(this.#svgContainer, e);
			this.#markerArea.setAttribute("x", mousePosition.x - offsetValueX);
			this.#markerArea.setAttribute("y", mousePosition.y - offsetValueY);
			this.#markedNodes.forEach(node => node.drag(e));
			this.#markerArea.addEventListener("mouseup", dropMarkerArea);
		};
		
		const dropMarkerArea = (e) => {
            e.stopPropagation();
            this.#svgContainer.removeEventListener("mousemove", moveMarkerArea);
		}
	}

    #createMarkerArea() {
		this.#setXMarker();
		this.#setYMarker();

		this.#markerArea = document.createElementNS(namespace, "rect");
		this.#markerArea.setAttribute("id", "selection");
		this.#markerArea.setAttribute("x", this.#markerStartX);
		this.#markerArea.setAttribute("y", this.#markerStartY);
		this.#markerArea.setAttribute("width", this.#markerEndX);
		this.#markerArea.setAttribute("height", this.#markerEndY);
		this.#markerArea.setAttribute("class", "dd-marker-area");
		this.#svgContainer.appendChild(this.#markerArea);
	}

    #setXMarker(){
        this.#markerStartX = Math.min(this.#startX, this.#endX);
        this.#markerEndX = Math.abs(this.#endX - this.#startX);
    }

    #setYMarker(){
            this.#markerStartY = Math.min(this.#startY, this.#endY);
            this.#markerEndY = Math.abs(this.#endY - this.#startY);
    }

    #removeActiveMarkerArea() {
		if (this.#markerArea){
			this.#markerArea.remove();
		} 
	}

	#unmarkNodes() {
		for(let node of this.#markedNodes){
			node.removeHighlight();
		}
	}
}