<section class="page-wrapper">
	<section class="width-full header-wrapper">
		<app-top-bar></app-top-bar>
	</section>
	<section class="flex-row page-container">
		<aside class="flex-col aside-container width-full">
			<section class="flex-row user-name-container width-full gap-small">
				<div class="container">
					<div class="profile-container">
						<p>
							{{ nameInitials ? nameInitials : 'Guest' }}
						</p>
					</div>
				</div>
				<section class="width-full">
					<p class="font-bigger">{{ user.firstName + ' ' + user.lastName }}</p>
					<p>{{ userEmail }}</p>
				</section>
			</section>
			<ul class="btn-list flex-col">
				@if (!isGuest) {
					<li>
						<button
							class="default-btn"
							routerLinkActive="active-btn"
							[routerLink]="'/settings/overview'"
						>
							<img src="../../../assets/icon/eye.svg" /> Overview
						</button>
					</li>
					<li>
						<button
							class="default-btn"
							routerLinkActive="active-btn"
							[routerLink]="'/settings/user'"
						>
							<img src="../../../assets/icon/person-gear.svg" /> User settings
						</button>
					</li>
					<li>
						<button
							class="default-btn"
							routerLinkActive="active-btn"
							[routerLink]="'/settings/password'"
						>
							<img src="../../../assets/icon/key.svg" /> Password
						</button>
					</li>
					<li>
						<button
							class="default-btn"
							routerLinkActive="active-btn"
							[routerLink]="'/settings/payment'"
						>
							<img src="../../../assets/icon/credit-card.svg" /> Payment
						</button>
					</li>
				}
				<li>
					<button class="default-btn" routerLinkActive="active-btn" [routerLink]="'/settings/faq'">
						<img src="../../../assets/icon/question-circle.svg" /> FAQ
					</button>
				</li>
			</ul>
		</aside>

		<main class="main">
			<router-outlet></router-outlet>
		</main>
	</section>
</section>
