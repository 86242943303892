import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';
import { Observable, of } from 'rxjs';

export const authGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
	const userService = inject(UserService);
	const router = inject(Router);

	const currentUser = userService.getCurrentUser();
	const guestUser = userService.getGuestUser();

	if (currentUser || guestUser === true) return of(true);

	return of(router.parseUrl('/login'));
};
