<section
	class="attribute-value-section flex-col attribute-type-note"
	[ngClass]="isEditing ? 'edit-container' : ''"
>
	<h6 class="attribute-value-heading flex-item">
		{{ managedObjectAttributeValue.attributeValue.name }}:
	</h6>
	<section class="attribute-value-text-container flex-col flex-item">
		@if (isEditing) {
			<app-markdown-editor
				[editorContent]="managedObjectAttributeValue.value"
				(contentEdited)="onMarkdownContentChange($event)"
			></app-markdown-editor>
			<section class="edit-btn-container flex-row">
				<app-confirm-cancel-button-group
					(cancel)="onCancelEdit()"
					(confirm)="onSaveChanges()"
				></app-confirm-cancel-button-group>
			</section>
		} @else if (managedObjectAttributeValue.id === 0) {
			<app-markdown-editor
				[editorContent]="managedObjectAttributeValue.value"
				(contentEdited)="onCreateNewNotes($event)"
			>
			</app-markdown-editor>
		} @else {
			<article class="ql-snow edit-pen-container">
				<div
					class="markdown-content-container font-smaller attribute-value-section ql-editor"
					[innerHTML]="
						managedObjectAttributeValue.value
							? (managedObjectAttributeValue.value | safeHtml: isExpanded)
							: ''
					"
					></div>
					<img
					src="../../../../../assets/icon/pencil.svg"
					alt="Svg of a pencil"
					class="edit-pen"
					(click)="onEdit()"
					/>
				</article>
		}
	</section>
</section>
