<div
	class="modal-backdrop"
	(click)="mode === ModalMode.Info && !disableBackdrop ? close() : null"
></div>
<div class="modal {{ size }} flex-col" (click)="onModalContentClick($event)">
	<div class="modal-header">
		<h4>{{ title }}</h4>
	</div>
	<div class="modal-content flex-col">
		<p>{{ content }}</p>
	</div>
	<div class="modal-footer flex-row">
		@switch (mode) {
			@case (ModalMode.Edit) {
				<app-button label="Save" extraClass="save-btn" (click)="saveChanges()"></app-button>
				<app-button label="Discard" (click)="discardChanges()"></app-button>
				<app-button label="Cancel" (click)="close()"></app-button>
			}
			@case (ModalMode.Info) {
				@if (autoClose) {
					<div>
						<p>Closing in {{ autoCloseTime - remainingTime }} seconds</p>
						<progress [value]="autoCloseTime - remainingTime" [max]="autoCloseTime"></progress>
					</div>
				}
				<app-button
					class="confirm-btn"
					[label]="buttonLabel ? buttonLabel : 'OK'"
					(click)="close(ModalResult.Ok)"
				></app-button>
			}
			@case (ModalMode.Delete) {
				<app-confirm-cancel-button-group
							(confirm)="saveChanges()"
							(cancel)="close()"
				></app-confirm-cancel-button-group>
			}
		}
	</div>
</div>
