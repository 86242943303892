import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ContextView } from '../models/contexts/context-view';
import { Context } from '../models/deluxedraw';
import { environment } from '../../../environments/environment';
import { ContextNode } from '../models/context-node/context-node';
import { CreateNode } from '../models/deluxedraw/create-node';

@Injectable({
	providedIn: 'root',
})
export class ContextService {
	public readonly maxContextCountAllowed: number = 5;
	private baseUrl: string = `${environment.apiUrl}context/api`;
	private canCreateContext = new BehaviorSubject<boolean>(true);
	public contextsModified = new Subject<void>();

	constructor(private httpClient: HttpClient) {}

	get canCreateContext$(): Observable<boolean> {
		return this.canCreateContext.asObservable();
	}

	get contextsModified$(): Observable<void> {
		return this.contextsModified.asObservable();
	}

	deleteContext(id: number): Observable<void> {
		return this.#delete(id).pipe(
			tap(() => {
				this.contextsModified.next();
			}),
		);
	}

	createContext(name: string, description: string): Observable<ContextView> {
		return this.#create(name, description).pipe(
			tap(() => {
				this.contextsModified.next();
			}),
		);
	}

	deleteFromNode(nodeId: number, contextId: number): Observable<void> {
		return this.#deleteAsNode(nodeId, contextId).pipe(
			tap(() => {
				this.contextsModified.next();
			}),
		);
	}

	createFromNode(createNode: CreateNode): Observable<ContextNode> {
		return this.#createAsNode(createNode).pipe(
			tap(() => {
				this.contextsModified.next();
			}),
		);
	}

	#calculateTotalContextCount(contexts: ContextView[]): number {
		let total = 0;
		for (const context of contexts) {
			total += 1;
			if (context.children) total += this.#calculateTotalContextCount(context.children);
		}
		return total;
	}

	getAll(): Observable<ContextView[]> {
		return this.httpClient.get<ContextView[]>(`${this.baseUrl}/contexts`).pipe(
			tap((contexts) => {
				const totalCount = this.#calculateTotalContextCount(contexts);
				const canAdd = totalCount < this.maxContextCountAllowed;
				this.canCreateContext.next(canAdd);
			}),
		);
	}

	get(id: number): Observable<Context> {
		return this.httpClient.get<Context>(`${this.baseUrl}/contexts/${id}`);
	}

	#create(name: string, description: string): Observable<ContextView> {
		return this.httpClient.post<ContextView>(`${this.baseUrl}/contexts`, { name, description });
	}

	#delete(id: number): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/contexts/${id}`);
	}

	update(id: number, name: string, description: string): Observable<ContextView> {
		return this.httpClient.put<ContextView>(`${this.baseUrl}/contexts`, { id, name, description });
	}

	#createAsNode(createNode: CreateNode): Observable<ContextNode> {
		return this.httpClient.post<ContextNode>(`${this.baseUrl}/contexts/node`, createNode);
	}

	#deleteAsNode(nodeId: number, contextId: number): Observable<void> {
		return this.httpClient.delete<void>(`${this.baseUrl}/contexts/${contextId}/nodes/${nodeId}`);
	}

	setContextId(contextId: number): void {
		localStorage.setItem('contextId', contextId.toString());
	}
}
