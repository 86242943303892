import { CommandName } from "../shared/constants/command-name.js";
import { Relation } from "../core/models/relation.js";
import { RelationState } from "../shared/constants/relation-state.js";
import { containerId } from "../shared/constants/id.js";
import { RelationRepository } from "../infrastructure/relation-repository.js";
import { NodeRepository } from "../infrastructure/node-repository.js";
import { gridSize } from "../shared/constants/grid.js";
import { distance, getTransformedMousePoint } from "../deluxe-math.js";
import { BoswellEventName } from "../shared/constants/boswell-events.js";

export class CreateRelationCommand {
	static commandName = CommandName.CreateRelationCommand;

	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.CreateRelationCommand, { detail: detail }))
	}
}

export class CreateRelationHandler {
	#rootContainer;
	#svgContainer;
    #mouseMoveListener;
    #deleteAnchoredRelationOnClickListener

    constructor(rootContainer, svgContainer)
    {
		this.#rootContainer = rootContainer;
        this.#svgContainer = svgContainer;
        this.#rootContainer.addEventListener(CreateRelationCommand.commandName, (e) => {
            this.#createRelation(e.detail.anchor, e.detail.relationSymbols);
        });
        this.#mouseMoveListener = (e) => {
            this.#updateAnchoredRelationPosition(e);
        }
        this.#deleteAnchoredRelationOnClickListener = () => {
            this.#deleteAnchoredRelation();
        }
    }

    #createRelation(clickedAnchor, relationSymbols) {
        const anchoredRelation = RelationRepository.getAnchored();

        if (anchoredRelation !== null) {
            this.#rootContainer.removeEventListener("mousemove", this.#mouseMoveListener);
            anchoredRelation.addEndAnchor(clickedAnchor);
            anchoredRelation.startAnchor.connectedRelations.push(anchoredRelation);
            anchoredRelation.endAnchor.connectedRelations.push(anchoredRelation);
            this.#svgContainer.removeEventListener("click", this.#deleteAnchoredRelationOnClickListener);

            const boswellRelation = {
                guid: anchoredRelation.guid,
                symbol1: anchoredRelation.symbol1,
                symbol2: anchoredRelation.symbol2,
                anchorLocation1: anchoredRelation.startAnchor.location,
                anchorLocation2: anchoredRelation.endAnchor.location,
                node1Id: anchoredRelation.node1Id,
                node2Id: anchoredRelation.node2Id,
            };
            this.#rootContainer.dispatchEvent(new CustomEvent(BoswellEventName.CreateRelation, {
                detail: boswellRelation,
                bubbles: true
            }));
        }
        else {
            const relation = new Relation(null, clickedAnchor, null, relationSymbols.symbol1, relationSymbols.symbol2, RelationState.Anchored);
            RelationRepository.add(relation);
            this.#svgContainer.addEventListener("click", this.#deleteAnchoredRelationOnClickListener);
            this.#rootContainer.addEventListener("mousemove", this.#mouseMoveListener);
        }
    }

    #updateAnchoredRelationPosition(e) {
        const relation = RelationRepository.getAnchored();
        const transformedMousePoint = getTransformedMousePoint(this.#svgContainer, e);
        const closestAnchor = this.#getClosestAnchor(transformedMousePoint);
        const closestAnchorPosition = closestAnchor.getAbsolutePosition(); 

        if (closestAnchor !== relation.startAnchor && distance(closestAnchorPosition.x, closestAnchorPosition.y, transformedMousePoint.x, transformedMousePoint.y) < gridSize * 1.5)
            relation.update(closestAnchor);
        else {
            const endAnchor = this.#createEndAnchor(closestAnchor.nodeSize, closestAnchor.location, transformedMousePoint)
            relation.update(endAnchor);
        }
    }

    #createEndAnchor(nodeSize, location, transformedMousePoint) {
		return {
			nodeSize: nodeSize,
			location: location,
			getAbsolutePosition: () => {
				return {
					x: transformedMousePoint.x,
					y: transformedMousePoint.y
				}
			}
		};
	}

    #getClosestAnchor(mousePosition) {
        const nodes = NodeRepository.getAll();
        
        if (!nodes.length)
            return;
        
        const closestNode = nodes.map(node => {
            const pos = node.getNodeCenterPosition();
            return {
                distance: distance(pos.x, pos.y, mousePosition.x, mousePosition.y),
                node: node
            };
        }).reduce((n1, n2) => n1.distance < n2.distance ? n1 : n2).node;
        
        return closestNode.anchors.map(anchor => {
			const pos = anchor.getAbsolutePosition();
			return {
				distance: distance(pos.x, pos.y, mousePosition.x, mousePosition.y),
				anchor: anchor
			}
		}).reduce((a1, a2) => a1.distance < a2.distance ? a1 : a2).anchor;
	}

    #deleteAnchoredRelation() {
        this.#rootContainer.removeEventListener("mousemove", this.#mouseMoveListener);
        this.#svgContainer.removeEventListener("click", this.#deleteAnchoredRelationOnClickListener);
        const relation = RelationRepository.getAnchored();
        RelationRepository.deleteByGuid(relation.guid);
        relation.clear();
    }
}