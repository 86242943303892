<form
	class="flex-col gap-small form-wrapper"
	[ngClass]="showPageWrapper ? 'form-wrapper' : 'component-form-wrapper'"
	#contactForm="ngForm"
	(ngSubmit)="sendContactForm(contactForm)"
>
	<div class="form-row flex-row">
		<section class="input-section">
			<app-input
				labelText="First name*"
				name="firstName"
				type="text"
				placeholder="First Name"
				[(ngModel)]="inquirer.firstName"
				required
			></app-input>
			@if (contactForm.submitted && contactForm.controls['firstName'].invalid) {
				<div class="error-msg font-smaller">Please enter your first name</div>
			}
		</section>
		<section class="input-section">
			<app-input
				labelText="Last name*"
				name="lastName"
				type="text"
				placeholder="Last Name"
				[(ngModel)]="inquirer.lastName"
				required
			></app-input>
			@if (contactForm.submitted && contactForm.controls['lastName'].invalid) {
				<div class="error-msg font-smaller">Please enter your last name</div>
			}
		</section>
	</div>

	<div class="form-row flex-row">
		<section class="input-section">
			<app-input
				labelText="Email adress*"
				name="email"
				type="email"
				placeholder="name@example.com"
				[(ngModel)]="inquirer.email"
				email
				pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
				required
			></app-input>
			@if (contactForm.submitted && contactForm.controls['email'].invalid) {
				<div class="error-msg font-smaller">Please enter a valid email address</div>
			}
		</section>
		<section class="input-section">
			<app-input
				labelText="Your country"
				name="country"
				type="country"
				placeholder="Your country"
				[(ngModel)]="inquirer.country"
			></app-input>
		</section>
	</div>
	<div class="form-row flex-row">
		<section class="input-section">
			<app-input
				labelText="Phone number"
				name="phoneNumber"
				type="tel"
				pattern="^(\+|-)?[0-9]+([ -]?[0-9]+)*$"
				placeholder="Your phone number"
				[(ngModel)]="inquirer.phoneNumber"
				#phoneNumber="ngModel"
			></app-input>
			@if (phoneNumber.invalid && phoneNumber.touched) {
				<div class="error-msg font-smaller">invalid phone number</div>
			}
		</section>
		<section class="input-section">
			<app-input
				labelText="Subject*"
				name="subject"
				type="subject"
				placeholder="Subject"
				[(ngModel)]="inquirer.subject"
				required
			></app-input>
			@if (contactForm.submitted && contactForm.controls['subject'].invalid) {
				<div class="error-msg font-smaller">Please enter your subject</div>
			}
		</section>
	</div>
	<label class="font-smaller font-weight-medium">Message*</label>
	<textarea
		name="body"
		placeholder="Message"
		[(ngModel)]="inquirer.body"
		class="message-textarea border-radius"
		required
	></textarea>
	@if (contactForm.submitted && contactForm.controls['body'].invalid) {
		<div class="error-msg font-smaller">Please enter your message</div>
	}
	<div class="flex-row" [ngClass]="showPageWrapper ? 'form-row form-row-btn' : 'button-container'">
		<app-button
			class="margin-top"
			label="Send"
			type="submit"
			[showLoadingSpinner]="sendingForm"
		></app-button>
		<app-button
			class="margin-top"
			extraClass="cancel"
			label="Back"
			type="submit"
			(buttonClick)="onCancel()"
		></app-button>
	</div>
</form>
