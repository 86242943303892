import { Location } from "../../shared/constants/location.js";
import { sameHorizontalAndVertical, sameSideHorizontalOnly, sameSideVerticalOnly, oppositeHorizontalAndVertical } from "../../deluxe-math.js";

export function getPoints(startAnchor, endAnchor, lineMargin, mirrored){
    const start = startAnchor.getAbsolutePosition();
    const end = endAnchor.getAbsolutePosition();
    const pStart = {x: start.x, y: start.y};
    const pEnd = {x: end.x, y: end.y};

    const directionX = endAnchor.location === Location.Left ? 1 : -1 
    const directionY = startAnchor.location === Location.Bottom ? 1 : -1
    
    const pointMatrix = {
        "start": start,
        "end": end,
        "startAnchor": startAnchor,
        "endAnchor": endAnchor,
        "lineMargin": lineMargin,
    }
    if (sameHorizontalAndVertical(pointMatrix)){
        const p1 = {
            x: start.x,
            y: end.y
        }
        return mirrored ? [pEnd, p1, pStart] : [pStart, p1, pEnd];
    }
    else if (sameSideHorizontalOnly(pointMatrix)){
        const p1 = {
            x: start.x,
            y: start.y + lineMargin * directionY
        }
        const p2 = {
            x: start.x + startAnchor.nodeSize.width * directionX / 2 + ((end.x - (start.x + startAnchor.nodeSize.width * directionX / 2)) / 2),
            y: p1.y
        }
        const p3 = {
            x: p2.x,
            y: end.y
        }
        return mirrored ? [pEnd, p3, p2, p1, pStart] : [pStart, p1, p2, p3, pEnd];
    }
    else if (sameSideVerticalOnly(pointMatrix)){
        const p1 = {
            x: start.x,
            y: start.y + ((end.y - endAnchor.nodeSize.height * directionY / 2) - start.y) / 2
        }
        const p2 = {
            x: end.x - lineMargin * directionX,
            y: p1.y
        }
        const p3 = {
            x: p2.x,
            y: end.y
        }
        return mirrored ? [pEnd, p3, p2, p1, pStart] : [pStart, p1, p2, p3, pEnd];
    }
    else if (oppositeHorizontalAndVertical(pointMatrix)){
        if ((directionX === 1 && pointMatrix.start.x - pointMatrix.startAnchor.nodeSize.width / 2 <= pointMatrix.end.x)
        ||(directionX === -1 && pointMatrix.start.x + pointMatrix.startAnchor.nodeSize.width / 2 >= pointMatrix.end.x)){
            const p1 = {
                x: start.x,
                y: start.y + lineMargin * directionY
            }
            const p2 = {
                x: start.x - (startAnchor.nodeSize.width / 2 + lineMargin) * directionX,
                y: p1.y
            }
            const p3 = {
                x: p2.x,
                y: end.y
            }
            return mirrored ? [pEnd, p3, p2, p1, pStart] : [pStart, p1, p2, p3, pEnd];
        }
        else{
            if ((directionY === 1 && start.y > end.y + endAnchor.nodeSize.height / 2) 
            || (directionY === -1 && start.y < end.y - endAnchor.nodeSize.height / 2)){
                const p1 = {
                    x: start.x,
                    y: start.y + lineMargin * directionY
                }
                const p2 = {
                    x: end.x - lineMargin * directionX,
                    y: p1.y
                }
                const p3 = {
                    x: p2.x,
                    y: end.y
                }
                return mirrored ? [pEnd, p3, p2, p1, pStart] : [pStart, p1, p2, p3, pEnd];
            }
            else{
                const p1 = {
                    x: start.x,
                    y: end.y + (endAnchor.nodeSize.height / 2 + lineMargin) * directionY
                }
                const p2 = {
                    x: end.x - lineMargin * directionX,
                    y: p1.y
                }
                const p3 = {
                    x: p2.x,
                    y: end.y
                }
                return mirrored ? [pEnd, p3, p2, p1, pStart] : [pStart, p1, p2, p3, pEnd]
            }
        }
    }
}
