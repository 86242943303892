import { Node } from "./node.js"
import { Anchor } from "../anchor.js";
import { namespace } from "../../../shared/constants/svg.js";
import { Location } from "../../../shared/constants/location.js";

export class CircleNode extends Node{
	color = "rgb(255, 255, 255)";
	radius = this.height / 2;
	#border = "#888888";

	constructor(position, actionMenuSelection, shape, referenceId, id, type){
		super(actionMenuSelection, shape, position, referenceId, id, type);
		this.#createSvg(position);
		this.#createAnchors();
		this.registerListeners();
	}
	
	getNodeCenterPosition(){
		const pos = this.getSvgPosition();
		return {
			x: pos.x + this.radius, 
			y: pos.y + this.radius 
		};
	}

	#createSvg(position){
		const {x, y} = position;
		
		this.svgContainer = document.createElementNS(namespace, "svg");
		this.svgContainer.setAttribute("fill", "white");
		this.svgContainer.setAttribute("x", x);
		this.svgContainer.setAttribute("y", y);
		this.svgContainer.style.overflow = "visible";

		this.svg = document.createElementNS(namespace, "circle");
		this.svg.setAttribute("r", this.radius);
		this.svg.setAttribute("cx", this.radius);
		this.svg.setAttribute("cy", this.radius);
		this.svg.setAttribute("fill", this.color);
		this.svg.setAttribute("stroke", this.#border);
		this.svg.setAttribute("stroke-width", this.strokeWidth);

		this.svgContainer.appendChild(this.svg);
		this.container.appendChild(this.svgContainer);
		this.#createStatusIndicator();
		this.createTextElement();
	}

	#createStatusIndicator(){
		const maskSvg = document.createElementNS(namespace, "mask");
		maskSvg.setAttribute("id", "cut-off-circle");
		maskSvg.style.pointerEvents = "none";

		const rectMask = document.createElementNS(namespace, "rect");
		rectMask.setAttribute("width", this.width);
		rectMask.setAttribute("height", this.height);
		rectMask.setAttribute("fill", "white");

		const circleMask = document.createElementNS(namespace, "circle");
		circleMask.setAttribute("r", this.radius);
		circleMask.setAttribute("cx", this.radius + this.statusIndicatorWidth);
		circleMask.setAttribute("cy", this.radius);
		circleMask.setAttribute("fill", "black");
		
		maskSvg.appendChild(rectMask);
		maskSvg.appendChild(circleMask);

		const statusSvg = document.createElementNS(namespace, "circle");
		statusSvg.setAttribute("r", this.radius - this.strokeWidth / 2);
		statusSvg.setAttribute("cx", this.radius);
		statusSvg.setAttribute("cy", this.radius);
		statusSvg.setAttribute("mask", "url(#cut-off-circle)");
		statusSvg.style.pointerEvents = "none";
		
		this.svgContainer.appendChild(maskSvg);
		this.svgContainer.appendChild(statusSvg);
	}

	#createAnchors(){
		const halfWidth = this.radius;
		const halfHeight = this.radius;
		const nodeSize = {
			"width": halfWidth * 2, 
			"height": halfHeight * 2
		}
		this.anchors = [
			new Anchor({ x: halfWidth, y: 0 }, Location.Top, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: halfWidth * 2, y: halfHeight }, Location.Right, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: halfWidth, y: halfHeight * 2 }, Location.Bottom, this.svgContainer, nodeSize, this.applicationState, this.id),
			new Anchor({ x: 0, y: halfHeight }, Location.Left, this.svgContainer, nodeSize, this.applicationState, this.id),
		];
	}
}