import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ManagedObject, ManagedObjectAttribute } from '../models/managed-object';
import { ManagedObjectNode } from '../models/managed-object-node/managed-object-node';
import { CreateNode } from '../models/deluxedraw/create-node';
import { Attribute } from '../models/attribute';

@Injectable({
	providedIn: 'root',
})
export class ManagedObjectService {
	private baseUrl: string = `${environment.apiUrl}managedobject/api`;
	constructor(private httpClient: HttpClient) {}

	get(manageObjectId: number, attributes: Attribute[]): Observable<ManagedObject> {
		return this.httpClient
			.get<ManagedObject>(`${this.baseUrl}/managedobjects/${manageObjectId}`)
			.pipe(map((managedObject) => this.mapManagedObject(managedObject, attributes)));
	}

	create(createNode: CreateNode): Observable<ManagedObjectNode> {
		return this.httpClient.post<ManagedObjectNode>(`${this.baseUrl}/managedobjects`, createNode);
	}

	delete(nodeId: number, managedObjectId: number): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.baseUrl}/managedobjects/${managedObjectId}/nodes/${nodeId}`,
		);
	}

	update(managedObjectToUpdate: ManagedObject, attributes: Attribute[]): Observable<ManagedObject> {
		return this.httpClient
			.put<ManagedObject>(`${this.baseUrl}/managedobjects`, managedObjectToUpdate)
			.pipe(
				map((managedObject) => {
					this.mapManagedObject(managedObject, attributes);
					return managedObject;
				}),
			);
	}

	private mapManagedObject(managedObject: ManagedObject, attributes: Attribute[]): ManagedObject {
		const managedObjectResult = { ...managedObject };
		managedObjectResult.folders.forEach((folder) => {
			folder.managedObjectAttributes.sort((a, b) => b.id - a.id);
			folder.managedObjectAttributes.forEach((managedObjectAttribute) => {
				this.mapManagedObjectAttribute(managedObjectAttribute, attributes);
				this.mapManagedObjectAttributeValues(managedObjectAttribute);
			});
		});
		return managedObjectResult;
	}

	private mapManagedObjectAttribute(
		managedObjectAttribute: ManagedObjectAttribute,
		attributes: Attribute[],
	): void {
		managedObjectAttribute.attribute = attributes.filter(
			(a) => a.id === managedObjectAttribute.attributeId,
		)[0];
	}

	private mapManagedObjectAttributeValues(managedObjectAttribute: ManagedObjectAttribute): void {
		managedObjectAttribute.managedObjectAttributeValues.forEach((managedObjectAttributeValue) => {
			managedObjectAttributeValue.attributeValue =
				managedObjectAttribute.attribute.attributeValues.filter(
					(attributeValue) => attributeValue.id === managedObjectAttributeValue.attributeValueId,
				)[0];
		});
	}
}
