import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ContactFormComponent } from '../../../../shared/contact-form/contact-form.component';

@Component({
	selector: 'app-contact',
	standalone: true,
	imports: [ContactFormComponent],
	templateUrl: './contact.component.html',
	styleUrl: './contact.component.css',
})
export class ContactComponent {
	constructor(private router: Router) {}

	onCancelForm(): void {
		void this.router.navigate(['/settings/faq']);
	}
}
