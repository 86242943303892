@if (!showLoadingSpinner) {
	<section class="flex-row gap-small">
		<button
			(click)="onConfirm($event)"
			[disabled]="disabled || showLoadingSpinner"
			class="default-btn confirm-btn"
			[class.enabled]="!disabled && !showLoadingSpinner"
			[class.disabled]="disabled || showLoadingSpinner"
		>
			<i class="bi bi-check-circle-fill"></i>
		</button>
		<button (click)="onCancel($event)" class="default-btn">
			<i class="bi bi-x-circle-fill cancel-btn"></i>
		</button>
	</section>
} @else {
	<app-loading-spinner ></app-loading-spinner>
}
