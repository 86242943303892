<section class="flex-col width-full content-view-container">
	<section class="touch-device-container flex-col">
		<app-button
			extraClass="create-btn"
			[smallButton]="true"
			label="Close"
			(buttonClick)="closeContentView()"
		></app-button>
	</section>
	@if (currentContext) {
		<section class="flex-row width-full gap-small">
			@if (!currentContext.isEditing) {
				<button
					class="default-btn keep-scale content-name border-radius"
					(click)="onShowEditForm()"
				>
					<p>{{ currentContext.name }}</p>
					<img class="pencil-icon" src="assets/icon/pencil.svg" alt="Pencil icon" />
				</button>
			} @else {
				<app-input
					[(ngModel)]="newContextName"
					[model]="currentContext.name"
					(enterPressed)="onRenameContext()"
				>
				</app-input>
				<app-confirm-cancel-button-group
					(confirm)="onRenameContext()"
					(cancel)="onCancelRenamingContext()"
					[disabled]="!newContextName || !currentContext.isEditing"
					[showLoadingSpinner]="currentContext.isLoading"
				>
				</app-confirm-cancel-button-group>
			}
		</section>
		<section class="context-section">
			<app-button
				extraClass="create-btn"
				label="Navigate to drawing"
				(buttonClick)="navigateToContext()"
			>
			</app-button>
		</section>
	} @else {
		<app-loading-spinner></app-loading-spinner>
	}
</section>
