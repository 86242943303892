import { Component, Input, OnInit } from '@angular/core';
import { ManagedObjectAttribute } from '../../../core/models/managed-object';
import { NgStyle } from '@angular/common';
import { FileComponent } from './file/file.component';
import { LinkComponent } from './link/link.component';
import { NoteComponent } from './note/note.component';
import { AttributeType } from '../../../core/models/attribute/attribute-type';
import { AttributeService } from '../../../core/services/attribute.service';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { FormsModule } from '@angular/forms';
import { ConfirmCancelButtonGroupComponent } from '../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';

@Component({
	selector: 'app-attribute-expanded',
	standalone: true,
	imports: [
		FileComponent,
		LinkComponent,
		NoteComponent,
		NgStyle,
		FormsModule,
		InputComponent,
		ConfirmCancelButtonGroupComponent,
		ButtonComponent,
		LoadingSpinnerComponent,
	],
	templateUrl: './attribute-expanded.component.html',
	styleUrls: ['./attribute-expanded.component.css', '../../../../attribute-value-styles.css'],
})
export class AttributeExpandedComponent implements OnInit {
	@Input() managedObjectAttribute!: ManagedObjectAttribute;

	AttributeType = AttributeType;
	CreatedByUser!: string;
	isEditingDescription: boolean = false;

	constructor(private attributeService: AttributeService) {}

	ngOnInit(): void {
		this.CreatedByUser = `${this.managedObjectAttribute.createdByUser?.firstName} ${this.managedObjectAttribute.createdByUser?.lastName}`;
	}

	updateManagedObjectAttribute(): void {
		this.attributeService.updateManagedObjectAttribute(this.managedObjectAttribute);
	}

	onEditDescription(): void {
		this.isEditingDescription = true;
	}

	onSaveEditDescription(): void {
		this.updateManagedObjectAttribute();
		this.isEditingDescription = false;
	}

	onCancelEditDescription(): void {
		this.isEditingDescription = false;
	}

	onDeleteAttribute(): void {
		this.attributeService.deleteManagedObjectAttribute(this.managedObjectAttribute);
	}
}
