<section class="flex-col page-container">
	<section>
		<h1 class="heading">Settings</h1>
	</section>
	@if (user.guest) {
		<section>
			<p>Create an account to access this view</p>
		</section>
		<app-button label="Create account" routerLink="/register-guest"></app-button>
	} @else {
		<section class="width-full">
			@if (isEditing) {
				<form
					(ngSubmit)="onSave(settingsForm)"
					#settingsForm="ngForm"
					class="login-form-container flex-col"
				>
					<app-input
						labelText="First name*"
						type="text"
						name="firstName"
						[(ngModel)]="user.firstName"
						[model]="user.firstName"
						required
					></app-input>
					@if (settingsForm.submitted && settingsForm.controls['firstName'].invalid) {
						<div class="error-msg">Please enter your first name</div>
					}
					<app-input
						labelText="Last name*"
						type="text"
						name="lastName"
						[(ngModel)]="user.lastName"
						[model]="user.lastName"
						required
					></app-input>
					@if (settingsForm.submitted && settingsForm.controls['lastName'].invalid) {
						<div class="error-msg">Please enter your last name</div>
					}
					<app-input
						labelText="Street"
						type="text"
						name="street"
						[(ngModel)]="user.addresses[0].street"
						[model]="user.addresses[0].street ?? ''"
					></app-input>
					<app-input
						labelText="Postal Code"
						type="number"
						name="postalCode"
						[(ngModel)]="user.addresses[0].postalCode"
						[model]="user.addresses[0].postalCode ?? ''"
						[maxLength]="15"
					></app-input>
					<app-input
						labelText="City"
						type="text"
						name="city"
						[(ngModel)]="user.addresses[0].city"
						[model]="user.addresses[0].city ?? ''"
					></app-input>
					<app-input
						labelText="Country"
						type="text"
						name="contry"
						[(ngModel)]="user.addresses[0].country"
						[model]="user.addresses[0].country ?? ''"
					></app-input>
					<app-input
						labelText="Phone Number"
						type="tel"
						name="phoneNumber"
						pattern="^(\+|-)?[0-9]+([ -]?[0-9]+)*$"
						[(ngModel)]="user.addresses[0].phoneNumber"
						[model]="user.addresses[0].phoneNumber ?? ''"
						[maxLength]="15"
					></app-input>
					<app-input
						labelText="Company"
						type="text"
						name="company"
						[(ngModel)]="user.addresses[0].company"
						[model]="user.addresses[0].company ?? ''"
					></app-input>
					@if (invalidFormMessage) {
						<div class="error-msg">
							{{ invalidFormMessage }}
						</div>
					}
					<section class="flex-row gap-small">
						<app-button class="option-btn margin-top" type="submit" label="Save"></app-button>
						<app-button
							class="option-btn margin-top"
							(buttonClick)="onCancelEdit(settingsForm)"
							label="Cancel"
							extraClass="cancel"
						></app-button>
					</section>
				</form>
			} @else {
				<article class="content-container flex-col">
					<section class="flex-row content-row">
						<p>First name:</p>
						<p class="content-name">{{ user.firstName }}</p>
					</section>
					<section class="flex-row content-row">
						<p>Last name:</p>
						<p class="content-name">{{ user.lastName }}</p>
					</section>
					<section class="flex-row content-row">
						<p>Street:</p>
						<p class="content-name">
							{{ user.addresses[0].street ?? '' }}
						</p>
					</section>
					<section class="flex-row content-row">
						<p>Postal Code:</p>
						<p class="content-name">
							{{ user.addresses[0].postalCode ?? '' }}
						</p>
					</section>
					<section class="flex-row content-row">
						<p>City:</p>
						<p class="content-name">
							{{ user.addresses[0].city ?? '' }}
						</p>
					</section>
					<section class="flex-row content-row">
						<p>Country:</p>
						<p class="content-name">
							{{ user.addresses[0].country ?? '' }}
						</p>
					</section>
					<section class="flex-row content-row">
						<p>Phone number:</p>
						<p class="content-name">
							{{ user.addresses[0].phoneNumber ?? '' }}
						</p>
					</section>
					<section class="flex-row content-row">
						<p>Company:</p>
						<p class="content-name">
							{{ user.addresses[0].company ?? '' }}
						</p>
					</section>
					<app-button (buttonClick)="onEdit()" label="Edit user information"></app-button>
					@if (!showPasswordInput) {
						<button class="link-button" (click)="onRemoveAccount()">Remove account</button>
					} @else {
						<div class="flex-row content-row">
							<app-input
								labelText="Enter your password"
								type="password"
								[(ngModel)]="password"
								name="password"
								(enterPressed)="verifyPassword()"
							></app-input>
						</div>
						<div class="flex-row form-row">
							<app-button
								(buttonClick)="verifyPassword()"
								[disabled]="!password"
								class="font-smaller"
								label="Remove"
							></app-button>
							<app-button
								(buttonClick)="onRemoveAccount()"
								extraClass="cancel"
								class="font-smaller"
								label="Cancel"
							></app-button>
						</div>
					}
				</article>
			}
		</section>
	}
</section>
