<aside class="toolbox-container flex-col" id="toolbox">
	<section class="button-container flex-col">
		<p>DRAWING</p>
		<app-toolbox-button
			iconClass="bi bi-box"
			tooltip="box"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Box"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<p>SHAPES</p>
		<app-toolbox-button
			iconClass="bi bi-square"
			tooltip="square"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Square"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-circle"
			tooltip="circle"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Circle"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-triangle"
			tooltip="triangle"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Triangle"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-octagon"
			tooltip="octagon"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Octagon"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-diamond"
			tooltip="diamond"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Diamond"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
	</section>
	<section class="button-container flex-col">
		<p>RELATIONS</p>
		<app-toolbox-button
			iconClass="bi bi-arrow-right"
			tooltip="relation"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Connect"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="
				selectedRelation === ToolboxAction.Connect && toolboxState === ToolboxState.Edit
			"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-arrows"
			tooltip="relation"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.ConnectBoth"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="
				selectedRelation === ToolboxAction.ConnectBoth && toolboxState === ToolboxState.Edit
			"
		></app-toolbox-button>
	</section>
	<section class="button-container flex-col">
		<p>ACTIONS</p>
		<app-toolbox-button
			iconClass="bi bi-hand-index"
			tooltip="view"
			[ToolbarState]="ToolboxState.Navigate"
			[Action]="ToolboxAction.Navigate"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="toolboxState === ToolboxState.Navigate"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-pencil"
			tooltip="edit"
			[ToolbarState]="ToolboxState.Edit"
			[Action]="ToolboxAction.Edit"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="toolboxState === ToolboxState.Edit"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-eraser"
			tooltip="delete"
			[ToolbarState]="ToolboxState.Delete"
			[Action]="ToolboxAction.Delete"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="toolboxState === ToolboxState.Delete"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-arrow-down-right-square"
			tooltip="mark & move"
			[ToolbarState]="ToolboxState.MarkerArea"
			[Action]="ToolboxAction.MarkerArea"
			(clickEvent)="onToolboxButtonClick($event)"
			[class.selected]="toolboxState === ToolboxState.MarkerArea"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-geo-alt"
			tooltip="reset zoom"
			[ToolbarState]="toolboxState"
			[Action]="ToolboxAction.ResetZoom"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
		<app-toolbox-button
			iconClass="bi bi-eye"
			tooltip="find node"
			[ToolbarState]="toolboxState"
			[Action]="ToolboxAction.Focusbox"
			(clickEvent)="onToolboxButtonClick($event)"
		></app-toolbox-button>
	</section>
</aside>
