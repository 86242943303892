export const CommandName = {
	CreateNodeCommand: "createNodeCommand",
	CreateFromNodesCommand: "createFromNodesCommand",
	UpdateActionMenuSelectionCommand: "updateActionMenuSelectionCommand",
	DeleteNodeCommand: "RemoveNodeCommand",
	CreateRelationCommand: "createRelationCommand",
	DeleteRelationCommand: "deleteRelationCommand",
	ResetZoomCommand: "resetZoomCommand",
	NodeClickedCommand: "nodeClickedCommand",
	TryDeleteNodeCommand: "tryRemoveNodeCommand"
}