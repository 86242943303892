import { NodeRepository } from "../infrastructure/node-repository.js";
import { CommandName } from "../shared/constants/command-name.js";
import { containerId } from "../shared/constants/id.js";
import { canvasSize, centerPositionPadding, maxZoom, minZoom } from "../shared/constants/svg.js";

export class ResetZoomCommand
{
    static commandName = CommandName.ResetZoomCommand;

    static send(detail) {
        document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.ResetZoomCommand, { detail: detail }))
    }
}

export class ResetZoomHandler
{
    #rootContainer;

    constructor(rootContainer){

        this.#rootContainer = rootContainer;
        this.#rootContainer.addEventListener(ResetZoomCommand.commandName, (e) => {
            this.#setDefaultZoom(e.detail.contextId);
        })
    }

    #setDefaultZoom(contextId) {
		const { x, y, zoom } = this.#nodesCenterPosition();
		const xTransform = x * -1 + this.#rootContainer.offsetWidth / 2;
		const yTransform = y * -1 + this.#rootContainer.offsetHeight / 2;
		window.panZoom.zoomAbs(0, 0, 1);
		window.panZoom.zoomTo(0, 0, zoom);
		window.panZoom.moveTo(xTransform, yTransform);
		sessionStorage.setItem(`panZoomValue-${contextId}`, JSON.stringify({ x: xTransform, y: yTransform, zoom: zoom }))
	}

    #nodesCenterPosition() {
        const nodes = NodeRepository.getAll();

		if (!nodes.length)
			return { x: canvasSize * 0.5, y: canvasSize * 0.5 , zoom: 1};

		const xValues = nodes.map(node => node.getNodeCenterPosition().x);
		const yValues = nodes.map(node => node.getNodeCenterPosition().y);
		const centerX = (Math.max(...xValues) + Math.min(...xValues)) / 2;
		const centerY = (Math.max(...yValues) + Math.min(...yValues)) / 2; 

		const scale = this.#getScale(xValues, yValues);

		return { x: centerX * scale, y: centerY * scale, zoom: scale};
	}

	#getScale(xValues, yValues) {
		const containerHeight = this.#rootContainer.offsetHeight - centerPositionPadding;
		const containerWidth = this.#rootContainer.offsetWidth - centerPositionPadding;

		const distanceX = Math.max(...xValues) - Math.min(...xValues);
		const distanceY = Math.max(...yValues) - Math.min(...yValues);

		let scaleX = containerWidth / distanceX;
		let scaleY = containerHeight / distanceY;

		scaleX = scaleX < minZoom ? minZoom : scaleX > maxZoom ? maxZoom : scaleX
		scaleY = scaleY < minZoom ? minZoom : scaleY > maxZoom ? maxZoom : scaleY

		return Math.min(scaleX, scaleY)
	}
}