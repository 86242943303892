<section class="page-container flex-col">
	<section class="heading-section">
		<h1 class="form-headings">Payment</h1>
	</section>
	@if (user && user.guest) {
		<section>
			<p>Create an account to access this view</p>
		</section>
		<app-button label="Create account" routerLink="/register-guest"></app-button>
	} @else {
		<p>payment works!</p>
	}
</section>
