import { Component } from '@angular/core';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { NgIf } from '@angular/common';
import { Contact } from '../../core/models/users/contact';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { Router } from '@angular/router';
import { ModalMode } from '../../shared/modal/modal.component';
import { ModalService } from '../../core/services/modal.service';
import { EmailService } from '../../core/services/email.service';
import { ToastrService } from '../../core/services/toastr.service';

@Component({
	selector: 'app-contact-form',
	standalone: true,
	imports: [FormsModule, NgIf, ButtonComponent, InputComponent],
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.css', '../../../form-styles.css'],
})
export class ContactFormComponent {
	inquirer: Contact = {
		firstName: '',
		lastName: '',
		email: '',
		country: undefined,
		body: '',
		phoneNumber: undefined,
		subject: '',
	};

	isPhoneNumberValid: boolean = true;
	invalidFormMessage = '';

	constructor(
		private modalService: ModalService,
		private emailService: EmailService,
		private toastrService: ToastrService,
		private router: Router,
	) {}

	sendContactForm(contactForm: NgForm): void {
		if (contactForm.valid) {
			this.emailService.sendContactForm(this.inquirer).subscribe({
				next: () => {
					this.modalService
						.open({
							title: 'The form has been sent',
							content: 'Thank you for your message. We will get back to you shortly.',
							mode: ModalMode.Info,
						})
						.subscribe();
					this.cancel();
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
		} else {
			this.invalidFormMessage = 'Please fill out the form correctly';
		}
	}

	cancel(): void {
		void this.router.navigate(['/']);
	}
}
