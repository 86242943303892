<section class="flex-col page-wrapper">
	<section class="width-full header-wrapper">
		<app-top-bar></app-top-bar>
	</section>
	<section class="content-wrapper">
		@if (showSideBar$ | async) {
			<app-side-bar
				[contexts]="contexts"
				[currentContext]="currentContext"
				[loadingContexts]="isLoadingContext"
				[style.width.px]="sidebarWidth ? sidebarWidth : ''"
			></app-side-bar>
		}
		<app-deluxe-draw
			[contextId]="contextId"
			(contextSelectedChange)="onContextSelectedChange($event)"
			(reSize)="onResize($event)"
		></app-deluxe-draw>
		@if ((showContentView$ | async) && isContextSelected) {
			<app-content-view
				[style.width.px]="contentViewWidth ? contentViewWidth : ''"
			></app-content-view>
		}
	</section>
</section>
