import { CommandName } from "../shared/constants/command-name";
import { containerId } from "../shared/constants/id";

export class TryDeleteNodeCommand
{
    static commandName = CommandName.TryDeleteNodeCommand;
    
	static send(detail) {
		document.getElementById(containerId).dispatchEvent(new CustomEvent(CommandName.TryDeleteNodeCommand, { detail: detail, bubbles: true }))
	}
}