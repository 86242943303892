import { Component, OnInit } from '@angular/core';
import { TopBarComponent } from '../../shared/top-bar/top-bar.component';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ButtonComponent } from '../../shared/button/button/button.component';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { User } from '../../core/models/users';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-settings',
	standalone: true,
	imports: [
		TopBarComponent,
		InputComponent,
		ButtonComponent,
		NgIf,
		FormsModule,
		RouterOutlet,
		NgClass,
		AsyncPipe,
		RouterLinkActive,
		RouterLink,
	],
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css', '../../../form-styles.css'],
})
export class SettingsComponent implements OnInit {
	user: User;
	nameInitials: string = '';
	userEmail: string = '';

	constructor(private userService: UserService) {
		this.user = this.userService.getCurrentUser()!.user;
	}

	ngOnInit(): void {
		if (this.user) {
			this.nameInitials = this.getInitials(this.user.firstName, this.user.lastName);
			this.userEmail = this.user.email;
		}
	}

	getInitials(firstName: string, lastName: string): string {
		const firstNameInitial = firstName.charAt(0).toUpperCase();
		const lastNameInitial = lastName.charAt(0).toUpperCase();
		return firstNameInitial + lastNameInitial;
	}
}
